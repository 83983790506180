<template>
<div class="account_op">
  <div class="manage_tip">
    <p class="title">{{ dialog.title }}</p>
	<el-button  icon="el-icon-close" @click="dialog.show = false" class="close"></el-button>
  </div>
  <el-main class='main'>
	  <el-form
	    ref="form"
	    :model="form"
	    label-width="60px"
	    style="width:100%"
	  >
	    <el-form-item prop="nickName" label="姓名">
	      <el-input type="text" v-model="form.customName" placeholder="请输入"></el-input>
	    </el-form-item>
	  	<el-form-item prop="nickName" label="电话">
	  	  <el-input type="text" v-model="form.organizers" placeholder="请输入"></el-input>
	  	</el-form-item>
		<el-form-item prop="nickName" label="所属区域">
		  <el-input type="text" v-model="form.organizers" placeholder="请输入"></el-input>
		</el-form-item>
	  	<div class="text_right" >
	      <el-button type="primary" @click="onSubmit('form')" class="primary">
			  <span>确 定</span>
		  </el-button>
	    </div>
	  </el-form>
  </el-main>
  
</div>
</template>

<script>
import {doInterface } from '@/api/index'
export default {
  name: 'AccountOperate',
  props: {
    dialog: Object,
    form: Object
  },
  data () {
    return {
    }
  },
  methods: {
    // 提交按钮
    onSubmit (form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          // 表单数据验证完成之后，提交数据;
          const data = {
           act:this.dialog.option === 'add' ? 'add' : 'edit',
           id: this.form.id,//	图片id
           img_url: this.form.img_url,//图片地址
           title:this.form.title,//	标题
           web_url:this.form.web_url,//网页地址
           img_type: this.form.img_type,//类型
           img_sort:this.form.img_sort,//排序
          }
          console.log(data)
          doInterface(data).then((res) => {
            console.log(res)
            // 操作成功
            if (res.msg === '成功') {
				if(this.dialog.option === 'add'){
					this.$message({
					  message: '新增成功！',
					  type: 'success'
					})
				}else{
					this.$message({
					  message: '编辑成功！',
					  type: 'success'
					})
				}
              
              this.dialog.show = false
              this.$emit('update')
            }
          })
            .catch((err) => {
              console.log(err)
            })
        }
      })
    },
	// 给请求回来的图片地址加上后台服务器地址，可以正常显示
	SetImg(value){
		// var str =getApp().globalData.https + value
		var str ="https://poetry.dysense.cn" + value
		if(value != ""){
			return str
		}
	},
   
  }
}
</script>

<style scoped>
.account_op{
  width: 418px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background-color: #FFFFFF;
  opacity: 1;
  /*height: 100%;*/
}
/* .manage_tip{
	position: relative;
} */
.manage_tip .title {
  /*height: 20px;*/
  font-family: "Microsoft YaHei";
  margin: 25px auto;
  width: 100px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #303133;
}
.close{
	position: absolute;
	right: 0;
	top: 0;
	border: none;
}
.pic{
  display: flex;
}
.main{
	/* height: 500px; */
	padding: 0 20px;
}
.img{
	width: 280px;
	height: 194px;
  margin-left: 5%;
  position: relative;
}
.img img{
  width: 280px;
  height: 194px;
}
.img i{
  width: 17px;
  height: 17px;
  display: inline-block;
  background: url("../assets/del.png") center no-repeat;
  background-size: 100%;
  position: absolute;
  right: -10px;
  top: -5px;
}
/deep/ .el-input__inner{
  height:40px;
  width: 90%;
  margin-bottom: 10px;
}
/deep/ .el-form{
  margin: 0;
}
/deep/ .el-form-item{
  width: 100%;
  /* display: flex !important; */
  /* justify-content: start; */
  margin-bottom: 2%;
}
/deep/ .el-form-item__content{
 /*  width: 85%;
  display: flex;
  position: relative;
  margin-left: 0!important; */
}
/deep/ .el-form-item__label{
  text-align: right;
  /* margin-left: 25px !important; */
  padding: 0;
  /*margin-right: 5%;*/
}
/deep/ .el-select{
	/* margin-left: 3%; */
	width: 100%;
	text-align: center;
	position: relative;
}
/deep/ .el-select{
	width:100%;
	margin: 0 auto;
	left: 0;
	text-align: center;
}
/deep/ .el-tag.el-tag--info{
	margin-left: 35px;
	margin-bottom: 10px;
}
/deep/ .el-input__suffix{
	margin-right: 30px;
	height: 40px;
}
/deep/ .el-upload--text{
	color: #C4C6CF !important;
}
/deep/.el-select .el-input__inner{
	width: 285px!important;
	height: 40px !important;
	border: 1px solid #DCDFE6 !important;
} 
.text_right{
	/* background-color: #0486FE; */
	width:200px;
	/* text-align: center; */
	display: flex;
	justify-content: center;
	margin: 0 auto;
	margin-bottom: 20px;
	margin-top: 10px;
	
}
.text_right1{
	/* background-color: #0486FE; */
	width:200px;
	text-align: center;
	margin: 0 auto;
	margin-bottom: 20px;
	margin-top: 10px;
	
}
.primary{
	background-color: #E91A1B !important;
	border: none;
	color: #FFFFFF;
}
.no{
	display: inline-block;
	background-color: none !important;
	color: #333333 !important;
	width: 66px;
	height: 40px;
	background: #FFFFFF;
	border-radius: 3px;
	border: 1px solid #C4C6CF;
	margin-left: 30px;
}
.Clipped{
  margin-left: 5px;
  margin-right: 5px;
}
</style>

