<template>
<div class="server_home">
  <div class="top">
	  <span>商品管理</span>
  </div>
  <div class="tabber_box">
  	  <div class="search_item">
  		<div>商品名称</div>
  		<input type="text" placeholder="请输入" v-model="goods_name"/>
  	  </div>
  	  <!-- <div class="search_item">
  	  	<div>手机号码</div>
  	  	<input type="text" placeholder="请输入" v-model="head_name"/>
  	  </div> -->
  	 <!-- <div class="search_item">
  	  	<div style="margin-right: 13px">会员标签</div>
  	  	<el-select v-model="level_value" placeholder="请选择排序方式">
  	  	    <el-option
  	  	      v-for="item in options"
  	  	      :key="item.value"
  	  	      :label="item.label"
  	  	      :value="item.value">
  	  	    </el-option>
  	  	  </el-select>
  	  </div> -->
  	  <div class="search" @click="goSearch">搜索</div>
  </div>
  <div class="table_container">
     <div class="table_top">
		 <div class="t_l">
			 <div class="release" @click="release">发布商品</div>
			 <div class='import'>导入</div>
		 </div>
		 <div class='t_r'>导出查询内容</div>
	 </div>
     <el-table
       :data="tableData"
       size="mini"
       fit
	   border
	   stripe
       :default-sort="{prop:'date', order: 'descending'}"
       :row-style="{height: '45px'}"
	   :header-cell-style="{'text-align':'left',padding: '0'}"
       :cell-style="{padding: '0','text-align':'left'}"
       style="width: 100%"
	   @selection-change="handleSelectionChange"
    >
	 <el-table-column
	      type="selection"
	      width="55">
	 </el-table-column>
	 <el-table-column type="index" label="序号" align="center" sortable></el-table-column>
     <el-table-column prop="product_name" label="商品" align="center" >
		 <template slot-scope="scope">
			<!-- <img src="../assets/voice.png" /> -->
			<img :src="SetImg(scope.row.product_img)" />
			<div class="s_r">
				<div class="name">{{scope.row.product_name}}</div>
				<div class="price">{{scope.row.display_price}}</div>
			</div>
		 </template>
	 </el-table-column>
     <el-table-column prop="code" label="编码" align="center"></el-table-column>
	 <el-table-column prop="class_name" label="分类" align="center"></el-table-column>
	 <el-table-column prop="desc" label="商品描述" align="center" width="400"></el-table-column>
	<!-- <el-table-column prop="organizers" label="实际库存" align="center"></el-table-column>
	 <el-table-column prop="organizers" label="预售库存" align="center"></el-table-column> -->
	 <el-table-column prop="actual_sales_num" label="实际销量" align="center" sortable></el-table-column>
	 <el-table-column prop="virtual_sales_num" label="虚拟销量" align="center" sortable></el-table-column>
     <el-table-column prop="create_time" label="发布时间" align="center" style="height: 45px" sortable>
		<template slot-scope="scope">
			<div>{{timeFormate(parseInt(scope.row.create_time))}}</div>
		</template>
	 </el-table-column>
     <el-table-column prop="operation" align="center" label="操作" >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
			style="color:#5584FF;"
            @click="edit(scope.row)"
          >编辑</el-button>
		  <el-button
		    type="text"
		    size="small"
			style="color: #5584FF;"
		    @click="isShelf(scope.row,scope.$index)"
			v-if="scope.row.is_shelf==1"
		  >下架</el-button>
		  <el-button
		    type="text"
		    size="small"
		  	style="color: #5584FF;"
		    @click="isShelf(scope.row,scope.$index)"
			v-if="scope.row.is_shelf==0"
		  >上架</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-row class="page">
        <el-col>
          <div class="pagination">
            <el-pagination
              v-if="paginations.total > 0"
              @current-change="handleCurrentChange"
              :current-page.sync="paginations.page_index"
              :page-size="paginations.page_size"
              layout="prev, pager, next, jumper"
              :total="paginations.total">
            </el-pagination>
          </div>
        </el-col>
      </el-row>
  </div>
  <!-- 蒙版对话框-->
  <div class="marks" v-if="dialog.show">
	   <!-- <CompetitionOperate :dialog="dialog" :form="form" @update="getProfile"></CompetitionOperate> -->
	   <GoodsOperate :dialog="dialog" :form="form" :classOptions="classOptions" @update="getProfile"></GoodsOperate>
  </div>

</div>
</template>

<script>
import { getGoodsList,getClassList,GoodsIsShelf,getProClassList } from '@/api/index'
import GoodsOperate from '@/components/GoodsOperate.vue'
export default {
  name: 'ServerHome',
  data () {
    return {
      fullHeight: '', // fullHeight: document.documentElement.clientHeight  屏幕高度 默认值
      tableData: [],
      // 需要给分页组件传的信息
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10 // 1页显示多少条
      },
	  dialog: {
	    show: false,
	    title: '',
	    option: 'edit'
	  },
	  form: {},
	  goods_name:'',//搜索框内容
	  options: [{
	            value: 'level_1',
	            label: '一级会员'
	          }, {
	            value: 'level_2',
	            label: '二级会员'
	          }, {
	            value: 'level_3',
	            label: '三级会员'
	          }],
	   level_value:'',//输入的等级值
	   multipleSelection: [],//表格选中对象集
	   classOptions:[],//分类下拉框选项内容
    }
  },
  components: {
	GoodsOperate
  },
  created () {
	//获取分类下拉框选项内容
	this.getClass()
    // 加载数据表格
    this.getProfile(1)
    // 获取屏幕高度
    this.fullHeight = `${document.documentElement.clientHeight}`// 默认值
    const that = this
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        that.fullHeight = window.fullHeight
      })()
    }
  },
  methods: {
	// 一级分类数据
	getClass() {
	  // 获取数据
	  const data = {}
	  // 请求数据
	  getProClassList(data)
	    .then((res) => {
			console.log("获取分类数据")
	      console.log(res)
		    console.log(res.data)
			for(let i=0;i<res.data.length;i++){
					// let obj={}
					// console.log(res.data[i])
				 //    obj.label=res.data[i].class_name
					//  obj.value=res.data[i].id
					//  this.classOptions.push(obj)
				for(let j=0;j<res.data[i].son_class_list.length;j++){
					 let obj={}
					 console.log(res.data[i].son_class_list[j])
					 obj.label=res.data[i].son_class_list[j].class_name
					 obj.value=res.data[i].son_class_list[j].id
					 this.classOptions.push(obj)
				}
			}  
			   console.log("分类选项内容")
			   console.log(this.classOptions)
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	getProfile (pages) {
		console.log("切换至")
		console.log(pages,this.pagesNumber)
	  // 获取表格数据
	  const data = {
	    type:1,
		search:this.goods_name,
		class_id:'',
		page: pages? pages:this.pagesNumber,
	    limit: '10',
	  }
	  // 请求数据
	  getGoodsList(data)
	    .then((res) => {
	      console.log(res)
		  console.log(res.data)
		  this.paginations.total=res.count_page
		  this.tableData=res.data
		  console.log("总共有"+ this.paginations.total+"条数据")
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
    // 根据屏幕的高度实时动态修改分页条数
    changePageSize (fullHeight) {
      if (fullHeight > 801) {
        this.paginations.page_size = 15
      } else {
        this.paginations.page_size = 10
      }
    },
	// 表格数据选中方法
	handleSelectionChange(val) {
		console.log(val)
	   this.multipleSelection = val;
	   console.log( this.multipleSelection)
	},
	// 搜索
	goSearch(){
		this.getProfile()
	},
	// 发布商品
	release(){
		console.log('点击发布')
		this.dialog = {
		  show: true,
		  title: '发布商品',
		  option: 'add'
		  // option: 'goods_add'
		}
		this.form = {}
	},
	// 通过操作
    edit (row) {
	  console.log('点击编辑')
      console.log(row)
	  // console.log(row.t_ids)
      this.dialog = {
        show: true,
        title: '商品修改',
        option: 'edit'
      }
      this.form = row
	  // console.log(this.form)
	  // console.log(this.form.t_ids)
    },
	//上/下架操作
	isShelf (row, index) {
	  // 上/下架提示
	  console.log(row, index)
	  this.$confirm('确认要进行上/下架操作吗？', {
	    confirmButtonText: '确定',
	    cancelButtonText: '取消',
	    confirmButtonClass: 'delete',
	  }).then(() => {
	    // 下架请求
	    const data = {
	      p_id: row.id,
	      is_shelf: row.is_shelf==0?1:0,
	    }
	    console.log(data)
	    GoodsIsShelf(data).then((res) => {
	      console.log(res)
	      if (res.msg === '成功') {
	        // 操作成功
	        this.$message({
	          message: '操作成功！',
	          type: 'success'
	        })
	        this.getProfile(this.pagesNumber)
	      }
	    })
	      .catch((err) => {
	        console.log(err)
	      })
	  }).catch(() => {
	    this.$message({
	      type: 'info',
	      message: '已取消删除'
	    })
	  })
	},
    handleCurrentChange (page) {
      const number = String(page)
	  this.pagesNumber=number
	  console.log("切换至"+this.pagesNumber+"页")
      // 当点击切换分页数据时，发送请求给服务器，让其把相应的页码数据内容发送回来
	  const data = {
	   type:'',
	   search:this.goods_name,
	   class_id:'',
	   page: number,
	   limit: '10',
	  }
      console.log(data)
      // 发送请求
      // 请求数据
      getGoodsList(data)
        .then((res) => {
          console.log(res)
      	  console.log(res.data)
		  this.tableData=res.data
        })
        .catch((err) => {
          console.log(err)
        })

    },
	// 给请求回来的图片地址加上后台服务器地址，可以正常显示
	SetImg(value){
		// var str =getApp().globalData.https + value
		// var str ="https://lovehome.dysense.cn" + value
		var str ="https://testlove.dysense.cn" + value
		if(value != ""){
			return str
		}
	},
	// 格式化后台传来的时间戳（年月日时分秒）
	timeFormate (timeStamp) {
	      var date = new Date(timeStamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
	      const Y = date.getFullYear() + '-'
	      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
	      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	      // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
		  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) 
	      const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	      return Y + M + D 
	},

  },
}
</script>
<style scoped>
  .server_home{
	width: 100%;
	height: 100%;
    color: #606266;
	/* position: relative; */
	top: 0;
	overflow: auto;
	background-color: #F7F8FA;
  }
  .top{
	  width: 100px;
	  height: 28px;
	  border-left: 4px solid #FB5C55;
	  font-size: 20px;
	  color: #333333;
	  font-weight: bold;
	  margin: 24px 0 10px 20px;
  }
  .tabber_box {
  	width: calc(100% - 40px);
  	height: 40px;
  	font-size: 14px;
  	color:#333333 ;
  	line-height: 40px;
  	margin-top: 23px;
  	margin-bottom: 24px;
  	display: flex;
  	align-items: center;
  	justify-content: start;
  	margin-left: 20px;
	padding-bottom: 25px;
	border-bottom: 1px solid #C4C6CF;
  }
  .search_item{
  	display: flex;
  	align-items: center;
  	justify-content: start;  
  	margin-right: 37px;
  }
  input{
  	  width: 250px;
  	  height: 32px;
  	  margin-left: 13px;
  	  border: 1px solid #C4C6CF;
  	  padding-left: 12px;
  	  border-radius: 3px;
  }
  /deep/.el-select .el-input__inner{
  	width: 250px;
  	height: 34px;
  	/* margin-left: 13px; */
  	 border: 1px solid #C4C6CF;
  	 padding-left: 12px;
  	 border-radius: 3px;
  }
  .tabber_box .search{
  	  width: 66px;
  	  height: 36px;
  	  border-radius: 3px;
  	  background-color: #E91A1B;
  	  color: #FFFFFF;
  	  line-height: 36px;
  	  text-align: center;
  	  margin-left: 37px;
  	  
  }
  /* .tabber_box span{
	  display: inline-block;
	  text-align: center;
	  line-height: 40px;
	  width: 80px;
	  height: 40px;
	  border-radius: 3px 0 0 3px;
  } */
  /* .yes{
	  background-color: #FB5C55;
	   color: #FFFFFF;
	   border: 1px solid #FB5C55;
  }
  .no{
	  background-color: #F2F3F7;
	  border: 1px solid #C4C6CF;
	  font-size: 12px;
	  color:#333333 ;
  } */
  
  .server_home .table_container{
    /* padding-left:10px; */
	padding: 0 10px;
  }
  .table_top{
	  width: 100%;
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	  font-size: 14px;
	  margin-bottom: 25px;
  }
  .t_l{
	display: flex;
	align-items: center;
	justify-content: start;  
  }
  .release{
	  width: 98px;
	  height: 38px;
	  background-color: #E91A1B;
	  color: #FFFFFF;
	  margin-right: 36px;
	  line-height: 36px;
	  text-align: center;
	  border-radius: 3px;
  }
  .import{
	  width: 66px;
	  height: 36px;
	  border: 1px solid #E91A1B;
	  color: #E91A1B;
	  line-height: 36px;
	  text-align: center;
	  border-radius: 3px;
  }
  .t_r{
	  width: 130px;
	  height: 36px;
	  border: 1px solid #C4C6CF;
	  color: #333333;
	  line-height: 36px;
	  text-align: center;
	  border-radius: 3px;
  }
  /deep/.cell{
	display: flex;
	align-items: center;
	justify-content: start;  
	text-overflow: ellipsis;
	white-space:nowrap;
  }
  /deep/.cell img{
	  width: 34px;
	  height: 34px;
	  margin-right: 10px;
  }
  .s_r{
	  /* height: 40px; */
	  display: flex;
	  flex-direction: column;
	  align-items: left;
	  line-height: 40px;
  }
  .name{
	  height: 20px;
	  line-height: 20px;
  }
  .price{
	  height: 20px;
	  line-height: 20px;
	  color: #E91A1B;
  }
  .server_home .page{
    width: 50%;
    margin: 20px auto;
    text-align: center;
  }
  /*为了设置每行的高度不会随着内容的增多而变大设置的样式，需要结合表格行内样式的row-style、cell-style一起来使用*/
  /deep/ .el-table .cell{
    height: 45px;
    line-height: 45px;
    overflow: hidden;
  }
  .marks{
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background: rgba(0,0,0,0.6);
	  z-index: 999;
  }
  /deep/.el-table--mini td, .el-table--mini th{
	  padding: 0;
  }
  /deep/.el-table td, .el-table th{
	  padding: 0;
  }
  /deep/ .el-table::before{
	  background-color: rgba(255,255,255,0);
  }
  /deep/.el-table .descending .sort-caret.descending{
	 border-top-color: #E91A1B !important; 
  }
  /deep/.el-table .ascending .sort-caret.ascending{
  	 border-bottom-color: #E91A1B !important; 
  }
  
</style>
<style>
	.delete{
		  background-color: #E91A1B!important;
		  color: #FFFFFF;
		  border: 1px solid #E91A1B !important;
	}
</style>


