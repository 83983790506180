<template>
	<div class="ti_xian">
	  <div class="top">
		  <span>账号提现</span>
	  </div>
	  <div class='ti_box'>
		<div class="item">
			<div class="txt_1">可用金额(元)</div>
			<div class="txt_2">0.00</div>
		</div> 
		<div class="item">
			<div class="txt_3">可提现金额(元)</div>
			<div class="txt_4">0.00</div>
		</div> 
	  </div>
	  <div class="op">
		  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
		    <el-form-item label="提现金额" prop="tixian_money">
		      <el-input v-model="ruleForm.tixian_money" type="number" onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode) ) )"></el-input>
		    </el-form-item>
			<el-form-item label="到账金额" prop="arrival_money">
			  <div class="arrival">0.00</div>
			  <!-- <el-input v-model="ruleForm.arrival_money" type="number" onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode) ) )" class="arrival"></el-input> -->
			</el-form-item>
		    <el-form-item>
		      <el-button type="primary" @click="submitForm('ruleForm')">提现</el-button>
		      <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
		    </el-form-item>
		  </el-form>
	  </div>
	</div>
</template>

<script>
	export default {
	  name: 'TiXian',
	  data () {
	    return {
			ruleForm: {
			          tixian_money: '',
			          arrival_money: '',
			        },
			        rules: {
			          tixian_money: [
			            { required: true, message: '请输入数值型提现金额', trigger: 'blur' },
			          ],
			          arrival_money: [
			            { required: true, message: '请输入数值型提现金额', trigger: 'blur' },
			          ],
			        }
		}
	  },
	  methods:{
		 submitForm(formName) {
		        this.$refs[formName].validate((valid) => {
		          if (valid) {
		            alert('submit!');
		          } else {
		            console.log('error submit!!');
		            return false;
		          }
		        });
		      },
		      resetForm(formName) {
		        this.$refs[formName].resetFields();
		      }  
	  }
	}
</script>

<style scoped="scoped">
.ti_xian{
	width: 100%;
	height: 100%;
	color: #606266;
	/* position: relative; */
	top: 0;
	padding: 30px;
	box-sizing: border-box;
	overflow: hidden;
	background-color: #F7F8FA;
}
.top{
	  width: 130px;
	  height: 28px;
	  border-left: 4px solid #FB5C55;
	  font-size: 20px;
	  color: #333333;
	  font-weight: bold;
	  margin: 0px 0 22px 0px;
  }
.ti_box{
	width: 100%;
	height: 180px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #FFFFFF;
	box-shadow: 0px 3px 5px 4px rgba(153, 153, 153, 0.12);
	border-radius: 6px;
}
.item{
	width: 50%;
	height: 100%;
	padding: 45px 80px;
	box-sizing: border-box;
}
.item:first-child{
	border-left: 10px solid #E72B00;
}
.item:last-child{
	border-left: 10px solid #EB7E10;
}
.txt_1{
	/* width: 80px; */
	height: 20px;
	font-size: 14px;
	font-family: PingFang-SC-Heavy, PingFang-SC;
	font-weight: 800;
	color: #666666;
	line-height: 20px;
}
.txt_2{
	/* width: 100px; */
	height: 67px;
	font-size: 48px;
	font-family: PingFang-SC-Heavy, PingFang-SC;
	font-weight: 800;
	color: #E72B00;
	line-height: 67px;
	margin-top: 10px;
}
.txt_3{
	/* width: 94px; */
	height: 20px;
	font-size: 14px;
	font-family: PingFang-SC-Heavy, PingFang-SC;
	font-weight: 800;
	color: #EB7E10;
	line-height: 20px;
}
.txt_4{
	/* width: 100px; */
	height: 67px;
	font-size: 48px;
	font-family: PingFang-SC-Heavy, PingFang-SC;
	font-weight: 800;
	color: #EB7E10;
	line-height: 67px;
	margin-top: 10px;
}
.op{
	width: 500px;
	margin: 31px auto;
}
.arrival{
	width: 100px;
	height: 40px;
	font-size: 48px;
	font-family: PingFang-SC-Heavy, PingFang-SC;
	font-weight: 800;
	color: #E72B00;
	line-height: 40px;
}
/*------------------------修改type=number的样式------------------------------------------------*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"]{
    -moz-appearance: textfield;
}
/deep/.el-button--primary{
	width: 66px;
	height: 40px;
	background: #E91A1B;
	border-radius: 3px;
	position: absolute;
	left: 0;
	top: 0;
	border: 1px solid rgba(0, 0, 0, 0);
}
/deep/.el-form-item{
	margin-bottom: 45px;
}
/* /deep/.el-form-item__label{
	padding: 0 25px 0 0;
} */
/deep/.el-form-item__content{
	margin-left: 120px!important;
	position: relative;
}
</style>
