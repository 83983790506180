<template>
    <el-row class="menu_page">
         <el-col>
             <el-menu
                mode="vertical"
                text-color="#333333"
				unique-opened='true'
                active-text-color="#FB5C55"
                class="el-menu-vertical-demo">
				<!-- <router-link to="/home">
				    <el-menu-item index="0">
				        <i class="fa fa-margin el-icon-mobile-phone"></i>
				        <span slot="title">首页</span>
				    </el-menu-item>
				</router-link> -->
				<template  v-for="item in items" >
                <!-- <template  v-for="item in item_user" > -->
                    <el-submenu v-if="item.children" :index="item.path" :key="item.path">
                        <template slot="title">
                            <i :class="'fa fa-margin '+item.icon"></i>
                            <span slot="title">{{item.name}}</span>
                        </template>
                        <router-link v-for="(citem,cindex) in item.children"
                            :to="citem.path" :key="cindex">
                            <el-menu-item
                                :index='citem.path'>
                                <span slot="title">{{citem.name}}</span>
                            </el-menu-item>
                        </router-link>
                    </el-submenu>

                </template>
             </el-menu>
         </el-col>
    </el-row>
</template>
<script>
export default {
  name: 'leftmenu',
  data () {
    return {
      items: [ //超级管理员权限菜单
		  {
		    icon: 'el-icon-user',
		    name: '权限管理',
		    path: '',
		    children: [
		  	  { path: 'mainAccountDistribution', name: '主账户分配' },
		  	  { path: 'headAccountDistribution', name: '团长账号分配' },
			  { path: 'headReview', name: '团长审核' }
		  	  ]
		  },
		  {
		    icon: 'el-icon-edit-outline',
		    name: '服务管理',
		    path: 'userManagement',
		    children: [
		  	  { path: 'service', name: '服务管理' },
			  { path: 'shopMall', name: '商城管理' },
		  	  { path: 'menuManagement', name: '菜单管理' },
		  	 ]
		  },
		  {
		    icon: 'el-icon-shopping-bag-1',
		    name: '产品发布',
		    path: 'info2',
		    children: [
		  	  { path: 'goodsManagement', name: '商品管理' },
			  { path: 'headGoodsReview', name: '团长产品审查' },
		  	 ]
		  },
		  {
		    icon: 'el-icon-document',
		    name: '客户资料',
		    path: 'info3',
		    children: [
		  	  { path: 'customerInformation', name: '客户服务查询' },
			  { path: 'potentialCustomers', name: '意向客户查询' },
		  	 ]
		  },
		  {
		    icon: 'el-icon-message',
		    name: '客户评价',
		    path: 'info4',
		    children: [
		  	  { path: 'badQuery', name: '差评查询' },
			  { path: 'evaluationFeedback', name: '评价反馈' },
			  { path: 'goodsOrderStatistics', name: '商品订单' },
		  	 ]
		  },
		  {
		    icon: 'el-icon-pie-chart',
		    name: '财务系统',
		    path: 'info5',
		    children: [
		  	  { path: 'headShareMoney', name: '团长分红' },
		  	  { path: '', name: '工人工费' },
			  { path: 'goodsFinance', name: '商品财政' },
		  	 ]
		  },
  //         {
  //           icon: 'el-icon-coordinate',
  //           name: '工人',
  //           path: 'fund2',
  //           children: [
		// 	  { path: 'userData', name: '工人审批' },
		// 	  { path: 'headManagement', name: '团长管理' },
		// 	  { path: 'headAccountDistribution', name: '用户管理' },
		// 	  ]
  //       },
		// {
		//   icon: 'el-icon-document',
		//   name: '订单',
		//   path: 'fund3',
		//   children: [
		// 	  { path: 'goodsOrder', name: '商品订单' },
		// 	  { path: 'serviceOrder', name: '服务订单' },
		// 	  ]
		// },
		// {
		//   icon: 'el-icon-user',
		//   name: '用户',
		//   path: 'info1',
		//   children: [
		// 	  { path: 'competitionApplication', name: '办赛申请' },
		// 	  { path: 'feedbackInformation', name: '反馈信息' },
		// 	  ]
		// },
		
      ],
	  item_user:[ //团长权限菜单
		 {
		   icon: 'el-icon-pie-chart',
		   name: '财务系统',
		   path: 'info1',
		   children: [
		 	  { path: 'tiXian', name: '账户提现' },
		 	  { path: 'monthlyFlowingWater', name: '月流水' },
		 	 ]
		 },
		 {
		   icon: 'el-icon-pie-chart',
		   name: '订单管理',
		   path: 'info22',
		   children: [
		 	  { path: 'serviceOrderManagement', name: '服务订单管理' },
		 	  { path: '', name: '商品预定' },
			  { path: '', name: '发布需求' },
		 	 ]
		 },
	  ]
    }
  }
}
</script>
<style scoped>
.menu_page {
  position: fixed;
  top: 71px;
  left: 0;
  min-height: 100%;
 /* background-color: #324057; */
 box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
  z-index: 99;
}
.el-menu {
  border: none;
}
.fa-margin {
  margin-right: 5px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 140px;
  min-height: 400px;
  text-align: left;
}
.el-menu-vertical-demo {
  width: 35px;
}
.el-submenu .el-menu-item {
  min-width: 140px;
}
.el-submenu.is-active .el-submenu__title{
	color: #E91A1B !important;
}
.el-menu-item.is-active {
      background-color: #FCE2E2 !important;
      color: #E91A1B;
	  border-right: 4rpx solid #E91A1B !important;
      /* span {
        color: #E91A1B !important;
      } */
    }
	
.hiddenDropdown,
.hiddenDropname {
  display: none;
}
a {
  text-decoration: none;
}
</style>
