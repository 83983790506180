<template>
  <div id="app">
   <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
  </div>
</template>

<script>
	export default {
		globalData:{
			select_address_id:'',//存储用户在地址界面选中的地址id，以便共享到其他界面展示该地址信息
		},
	}
</script>

<style>
	解决

<style lang="scss">
	*{
	  margin: 0;
	  padding: 0;
	}
	html,body,#app {
	  width: 100%;
	  height: 100%;
	  color: #606266;
	}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
*{
	  margin: 0;
	  padding: 0;
	}
/**修改全局的滚动条
/**滚动条的宽度*/
::-webkit-scrollbar {
  width: 8px; 
}
//滚动条的滑块
::-webkit-scrollbar-thumb {
  background-color: #E91A1B;
  border-radius: 3px;
}


</style>
