<template>
	<div class="statistics">
		<div class="detail">
			<div class="top">
				  <span>首页</span>
			</div>
			<div class="contant">
				<div class="data_show">
					<img class="pic" src="../assets/home_1.png"></img>
					<div class="title">今日订单数</div>
					<div class="data">99986</div>
				</div>
				<div class="data_show">
					<img class="pic" src="../assets/home_2.png"></img>
					<div class="title">今日新增会员数</div>
					<div class="data">10000</div>
				</div>
				<div class="data_show">
					<img class="pic" src="../assets/home_3.png"></img>
					<div class="title">今日收入</div>
					<div class="data">1000000</div>
				</div>
			</div>
		</div>
		<div class="middle">
			<!-- 代办事项-->
			<div class="event">
				<div class="top">
					  <span>待办事项</span>
				</div>
				<div class="box">
					<div v-for="(it,idx) in eventList" :key="idx" class="box_it">
						<div class="quan" :class="[it.status==1?'yellow':it.status==2?'gray':'']"></div>
						<div>{{it.txt}}</div>
					</div>
					<!-- 分页 -->
					<el-row class="page">
					    <el-col>
					      <div class="pagination">
					        <el-pagination
					          v-if="paginations.total > 0"
					          @current-change="handleCurrentChange"
					          :current-page.sync="paginations.page_index"
					          :page-size="paginations.page_size"
					          layout="prev, pager, next, jumper"
					          :total="paginations.total">
					        </el-pagination>
					      </div>
					    </el-col>
					</el-row>
				</div>
			</div>
			<!-- 快捷功能-->
			<div class="fast">
				<div class="top">
					  <span>快捷功能</span>
				</div>
				<div class="box">
					<div v-for="(item,index) in fastList" :key="index" class="box_item" @click="go(item)">
						<div>{{item.txt}}</div>
						<div class="el-icon-arrow-right"></div>
					</div>
				</div>
			</div>
		</div>
		<!-- 底部 -->
		<div class="footer">
			<div class="item">
				<img class="pic" src="../assets/home_4.png"></img>
				<div class="title">商品数量</div>
				<div class="data">99986</div>
			</div>
			<div class="item">
				<img class="pic" src="../assets/home_5.png"></img>
				<div class="title">团长数量</div>
				<div class="data">99986</div>
			</div>
			<div class="item">
				<img class="pic" src="../assets/home_6.png"></img>
				<div class="title">近7天销售额</div>
				<div class="data">99986</div>
			</div>
			<div class="item">
				<img class="pic" src="../assets/home_7.png"></img>
				<div class="title">近7天退款</div>
				<div class="data">99986</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getMatchList} from '@/api/index'
export default {
	  name: 'FinancialStatistics',
	  data () {
	    return {
			fullWidth: '',
			// 需要给分页组件传的信息
			paginations: {
			  page_index: 1, // 当前位于哪页
			  total:8, // 总数
			  page_size: 8 // 1页显示多少条
			},
			fastList:[ //快速服务
				{txt:'发布商品',path:'/goodsManagement'},
				{txt:'订单列表',path:'/goodsOrder'},
				{txt:'会员管理',path:'/userManagement'},
				{txt:'团长管理',path:'/headManagement'},
				{txt:'配送单管理',path:''},
				{txt:'财务管理',path:''},
			],
			eventList:[ //待办事件
				{txt:'商品库存/付款信息/发货订单',status:0},
				{txt:'审核会员',status:0},
				{txt:'审核团长',status:1},
				{txt:'服务订单改价申请',status:1},
				{txt:'会员身份证审核',status:1},
				{txt:'客户投诉',status:2},
				{txt:'商品退款审核',status:2},
				{txt:'已收货待确认申请',status:2},
			]
		}
	},
	  mounted () {
	    // 加载数据表格
	    // this.getProfile(1,0)
		
	  },
	  methods: {
		// 加载统计明细数据表格
		getProfile (pages,type) {
			console.log("切换至")
			console.log(pages,this.pagesNumber)
		  // 获取表格数据
		  const data = {
		    // page: '1',
			page: pages? pages:this.pagesNumber,
		    limit: '8',
			type:this.is_handle?1:0//0 未审核 1已审核
		  }
		  // 请求数据
		  getMatchList(data)
		    .then((res) => {
		      console.log(res)
			  console.log(res.data)
			  this.paginations.total=res.count_page
			  this.tableData=res.data
			  console.log("总共有"+ this.paginations.total+"条数据")
		    })
		    .catch((err) => {
		      console.log(err)
		    })
		},
		// 快捷服务跳转
		go(data){
			console.log(data)
			this.$router.push(data.path)
		},
		// 换页
		handleCurrentChange (page) {
		  const number = String(page)
		  this.pagesNumber=number
		  console.log("切换至"+this.pagesNumber+"页")
		  // 当点击切换分页数据时，发送请求给服务器，让其把相应的页码数据内容发送回来
		  const data = {
		    page: number,
		    limit: '8',
			type:this.is_handle?1:0
		  }
		  console.log(data)
		  // 发送请求
		  // 请求数据
		  getMatchList(data)
		    .then((res) => {
		      console.log(res)
		  	  console.log(res.data)
			  this.tableData=res.data
		    })
		    .catch((err) => {
		      console.log(err)
		    })
		},
		
	  },
	 
	}	
</script>

<style lang="scss">
	.statistics{
		width: 100%;
		// height: 100%;
		color: #606266;
		position: relative;
		padding: 0 24px 24px 24px;
		box-sizing: border-box;
		background-color: #F3F3F3;
	}
	.detail{
		margin-bottom: 36px;
		overflow: auto;
	}
	.top{
		  width: 200px;
		  height: 28px;
		  border-left: 4px solid #FB5C55;
		  font-size: 20px;
		  color: #333333;
		  font-weight: bold;
		  margin: 24px 0 10px 0px;
		  text-align: left;
		  padding-left: 12px;
	}
	.contant{
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
	.data_show{
		width: 32%;
		height: 180px;
		background: #FFFFFF;
		box-shadow: 0px 3px 5px 4px rgba(153, 153, 153, 0.12);
		border-radius: 20px;
		text-align: center;
		margin-top: 24px;
		padding: 30px;
		box-sizing: border-box;
		display: flex;
		justify-content:start ;
		align-items: center;
	}
	.pic{
		width: 86px;
		height: 86px;
		margin-right: 20px;
	}
	.data{
		// height: 63px;
		font-size: 40px;
		font-family: Roboto-Bold, Roboto;
		font-weight: bold;
		color: #333333;
		// line-height: 63px;
		// margin-top: 24px;
	}
	.title{
		/* width: 84px; */
		// height: 17px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		// line-height: 17px;
		// margin-top: 12px;
		margin-right: 20px;
	}
	.middle{
		width: 100%;
		display: flex;
		justify-content:space-between ;
		align-items: center;
	}
	.event{
		width: 60%;
	}
	.fast{
		width: 38%;
	}
	.box{
		width: 100%;
		height: 454px;
		margin-top: 22px;
		box-shadow: 0 1px 5px 2px #999999;
		border-radius: 6px;
		background-color: #FFFFFF;
		padding:0 24px;
		box-sizing: border-box;
		
	}
	.box_it{
		height: 47px;
		display: flex;
		justify-content:start ;
		align-items: center;
		font-size: 14px;
		color: #333333;
		border-bottom: 1px solid #DCDEE3;
	}
	.quan{
		width: 9px;
		height: 9px;
		border-radius: 50%;
		background: rgba(233, 26, 27, 0.4);
		border: 1px solid #E91A1B;
		margin-right: 20px;
	}
	.yellow{
		background: rgba(255, 147, 0, 0.4);
		border: 1px solid #FF9300;
	}
	.gray{
		background: rgba(153, 153, 153, 0.4);
		border: 1px solid #999999;
	}
	.page{
    width: 50%;
    margin: 20px auto;
    text-align: center;
  }
	.box_item{
		height: 75px;
		display: flex;
		justify-content:space-between ;
		align-items: center;
		font-size: 14px;
		color: #333333;
		border-bottom: 1px solid #DCDEE3;
	}
	.box_item:last-child{
		border-bottom: none;
	}
	.footer{
		width: 100%;
		height: 192px;
		box-shadow: 0 1px 5px 2px #999999;
		background-color: #FFFFFF;
		margin-top: 24px;
		border-radius: 6px;
		display: flex;
		justify-content:space-around ;
		align-items: center;

	}
	.footer .pic{
		width: 28px;
		height: 28px;
		margin-bottom: 10px;
	}
	.footer .data{
		margin-top: 18px;
	}
	.footer .title{
		font-size: 14px;
	}

</style>
