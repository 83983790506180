<template>
<div class="server_home">
  <div class="top">
	  <span>服务订单</span>
  </div>
  <div class="tabber_box">
  	  <div class="search_item">
  		<div>订单号</div>
  		<input type="text" placeholder="请输入" v-model="order_num"/>
  	  </div>
  	  <div class="search">搜索</div>
  </div>
  <div class="table_container">
     <div class="table_top">
		 <div class='t_r'>导出查询内容</div>
		 <div class='t_r'>导出近7天待发货商品</div>
		 <div class='t_r'>导出商品配货表</div>
		 <div class='t_r'>电子面单</div>
	 </div>
     <el-table
       :data="tableData"
       size="mini"
       fit
	   border
	   stripe
       :default-sort="{prop:'date', order: 'descending'}"
       :row-style="{height: '45px'}"
	   :header-cell-style="{'text-align':'left',padding: '0'}"
       :cell-style="{padding: '0','text-align':'left'}"
       style="width: 100%"
	   @selection-change="handleSelectionChange"
    >
	 <el-table-column
	      type="selection"
	      width="55">
	 </el-table-column>
	 <el-table-column prop="organizers" label="订单号" align="center" ></el-table-column>
     <el-table-column prop="create_time" label="下单时间" align="center" style="height: 45px" >
     		<template slot-scope="scope">
     			<div>{{timeFormate(parseInt(scope.row.create_time))}}</div>
     		</template>
     </el-table-column>
     <el-table-column prop="organizers" label="服务" align="center"></el-table-column>
	 <el-table-column prop="organizers" label="订单总额" align="center"></el-table-column>
	 <el-table-column prop="organizers" label="下单人" align="center"></el-table-column>
	 <el-table-column prop="organizers" label="负责工人" align="center"></el-table-column>
	 <el-table-column prop="organizers" label="订单状态" align="center" ></el-table-column>
	 <el-table-column prop="organizers" label="订单评价" align="center" ></el-table-column>
     <!-- <el-table-column prop="operation" align="center" label="操作"  v-if="!is_handle">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
			style="color:#5584FF;"
            @click="edit(scope.row)"
          >编辑</el-button>
		  <el-button
		    type="text"
		    size="small"
			style="color: #5584FF;"
		    @click="onDelete(scope.row,scope.$index)"
		  >下架</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <!-- 分页 -->
    <el-row class="page">
        <el-col>
          <div class="pagination">
            <el-pagination
              v-if="paginations.total > 0"
              @current-change="handleCurrentChange"
              :current-page.sync="paginations.page_index"
              :page-size="paginations.page_size"
              layout="prev, pager, next, jumper"
              :total="paginations.total">
            </el-pagination>
          </div>
        </el-col>
      </el-row>
  </div>
  <!-- 审核通过-->
  <div class="marks" v-if="dialog.show">
	   <!-- <CompetitionOperate :dialog="dialog" :form="form" @update="getProfile"></CompetitionOperate> -->
	   <GoodsOperate :dialog="dialog" :form="form" @update="getProfile"></GoodsOperate>
  </div>

</div>
</template>

<script>
import { getMatchList,doWord } from '@/api/index'
import GoodsOperate from '@/components/GoodsOperate.vue'
export default {
  name: 'ServerHome',
  data () {
    return {
      fullHeight: '', // fullHeight: document.documentElement.clientHeight  屏幕高度 默认值
      tableData: [],
      // 需要给分页组件传的信息
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10 // 1页显示多少条
      },
	  dialog: {
	    show: false,
	    title: '',
	    option: 'edit'
	  },
	  form: {
	    id: '',
	    organizers: '',
	    theme:'',
	    desc:'',
	    cut_off_time:'',
	    bonus_amount:'',
	    contact:'',
	    u_id:'',
		is_del:'',
	    status:'',
		remark:'',
		create_time:'',
		update_time:'',
		nickName:'',
		customName:'',
		join_type:'',
		is_proposition:'',
	  },
	  is_handle:false,//是否选中已处理
	  order_num:'',//搜索框内容
	   multipleSelection: [],//表格选中对象集
    }
  },
  components: {
	GoodsOperate
  },
  created () {
    // 加载数据表格
    this.getProfile(1,0)
    // 获取屏幕高度
    this.fullHeight = `${document.documentElement.clientHeight}`// 默认值
    const that = this
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        that.fullHeight = window.fullHeight
      })()
    }
  },
  methods: {
	getProfile (pages,type) {
		console.log("切换至")
		console.log(pages,this.pagesNumber)
	  // 获取表格数据
	  const data = {
	    // page: '1',
		page: pages? pages:this.pagesNumber,
	    limit: '10',
		type:this.is_handle?1:0//0 未审核 1已审核
	  }
	  // 请求数据
	  getMatchList(data)
	    .then((res) => {
	      console.log(res)
		  console.log(res.data)
		  this.paginations.total=res.count_page
		  this.tableData=res.data
		  console.log("总共有"+ this.paginations.total+"条数据")
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
    // 根据屏幕的高度实时动态修改分页条数
    changePageSize (fullHeight) {
      if (fullHeight > 801) {
        this.paginations.page_size = 15
      } else {
        this.paginations.page_size = 10
      }
    },
	// 表格数据选中方法
	handleSelectionChange(val) {
		console.log(val)
	   this.multipleSelection = val;
	   console.log( this.multipleSelection)
	},
	// 发布商品
	release(){
		console.log('点击发布')
		this.dialog = {
		  show: true,
		  title: '发布商品',
		  option: 'add'
		}
		this.form = {}
	},
	// 通过操作
    edit (row) {
	  console.log('点击编辑')
      console.log(row)
	  // console.log(row.t_ids)
      this.dialog = {
        show: true,
        title: '商品修改',
        option: 'edit'
      }
      this.form = row
	  // console.log(this.form)
	  // console.log(this.form.t_ids)
    },
	// 拒绝操作
	refuse (row, index) {
	  // 添加数据
	  this.dialog = {
	    show: true,
	    title: '拒绝',
	    option: 'refuse'
	  }
	  this.form = {
	    id: row.id,
	    organizers:row.organizers,
	    theme:row.theme,
	    desc:row.desc,
	    cut_off_time:row.cut_off_time,
	    bonus_amount:row.bonus_amount,
	    contact:row.contact,
	    u_id:row.u_id,
	    is_del:row.is_del,
	    status:row.status,
	    remark:row.remark,
	    create_time:row.create_time,
	    update_time:row.update_time,
	    nickName:row.nickName,
	    customName:row.customName,
	    join_type:row.join_type,
	    is_proposition:row.is_proposition,
	  }
	},
	//删除操作
	onDelete (row, index) {
	  // 删除提示
	  console.log(row, index)
	  this.$confirm('确认要删除吗，删除后不可恢复', {
	    confirmButtonText: '删除',
	    cancelButtonText: '取消',
	    confirmButtonClass: 'delete',
	  }).then(() => {
	    // 删除请求
	    const data = {
	      act: 'del',
	      id: row.id,
	      word: row.word,
	      phonetic_symbols: row.phonetic_symbols,
		  pronunciation_file:row.pronunciation_file,
		  meaning:row.meaning,
		  part_speech:row.part_speech,
		  example_sentence:row.example_sentence,
		  test_questions:row.test_questions,
		  t_ids:row.t_ids
	    }
	    console.log(data)
	    doWord(data).then((res) => {
	      console.log(res)
	      if (res.msg === '成功') {
	        // 操作成功
	        this.$message({
	          message: '删除成功！',
	          type: 'success'
	        })
	        this.getProfile(this.pagesNumber)
	      }
	    })
	      .catch((err) => {
	        console.log(err)
	      })
	  }).catch(() => {
	    this.$message({
	      type: 'info',
	      message: '已取消删除'
	    })
	  })
	},
    handleCurrentChange (page) {
      const number = String(page)
	  this.pagesNumber=number
	  console.log("切换至"+this.pagesNumber+"页")
      // 当点击切换分页数据时，发送请求给服务器，让其把相应的页码数据内容发送回来
	  const data = {
	    page: number,
	    limit: '10',
		type:this.is_handle?1:0
	  }
      console.log(data)
      // 发送请求
      // 请求数据
      getMatchList(data)
        .then((res) => {
          console.log(res)
      	  console.log(res.data)
		  this.tableData=res.data
        })
        .catch((err) => {
          console.log(err)
        })

      // // 当前页
      // const sortnum = this.paginations.page_size * (page - 1)
      // const table = this.allTableData.filter((item, index) => {
      //   return index >= sortnum
      // })
      // // 设置默认分页数据
      // this.tableData = table.filter((item, index) => {
      //   return index < this.paginations.page_size
      // })
    },
	// 格式化后台传来的时间戳（年月日时分秒）
	timeFormate (timeStamp) {
	      var date = new Date(timeStamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
	      const Y = date.getFullYear() + '-'
	      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
	      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	      // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
		  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) 
	      const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	      return Y + M + D 
	},

  },
  // 监听屏幕变化
  // watch: {
  //   // 如果 `fullHeight ` 发生改变，这个函数就会运行
  //   fullHeight: function (val) {
  //     if (!this.timer) {
  //       this.fullHeight = val
  //       this.changePageSize(this.fullHeight)
  //       this.getProfile()
  //       console.log(val)
  //       console.log(this.paginations.page_size)
  //       this.timer = true
  //       const that = this
  //       setTimeout(function () { // 频繁触发 resize 函数，会导致页面很卡
  //         that.timer = false
  //       }, 100)
  //     }
  //   }

  // }
}
</script>
<style scoped>
  .server_home{
	width: 100%;
	height: 100%;
    color: #606266;
	/* position: relative; */
	top: 0;
	overflow: auto;
	background-color: #F7F8FA;
  }
  .top{
	  width: 100px;
	  height: 28px;
	  border-left: 4px solid #FB5C55;
	  font-size: 20px;
	  color: #333333;
	  font-weight: bold;
	  margin: 24px 0 10px 20px;
  }
  .tabber_box {
  	width: calc(100% - 40px);
  	height: 40px;
  	font-size: 14px;
  	color:#333333 ;
  	line-height: 40px;
  	margin-top: 23px;
  	margin-bottom: 24px;
  	display: flex;
  	align-items: center;
  	justify-content: start;
  	margin-left: 20px;
	padding-bottom: 25px;
	border-bottom: 1px solid #C4C6CF;
  }
  .search_item{
  	display: flex;
  	align-items: center;
  	justify-content: start;  
  	margin-right: 37px;
  }
  input{
  	  width: 250px;
  	  height: 32px;
  	  margin-left: 13px;
  	  border: 1px solid #C4C6CF;
  	  padding-left: 12px;
  	  border-radius: 3px;
  }
  /deep/.el-select .el-input__inner{
  	width: 250px;
  	height: 34px;
  	/* margin-left: 13px; */
  	 border: 1px solid #C4C6CF;
  	 padding-left: 12px;
  	 border-radius: 3px;
  }
  .tabber_box .search{
  	  width: 66px;
  	  height: 36px;
  	  border-radius: 3px;
  	  background-color: #E91A1B;
  	  color: #FFFFFF;
  	  line-height: 36px;
  	  text-align: center;
  	  margin-left: 37px;
  	  
  }
  /* .tabber_box span{
	  display: inline-block;
	  text-align: center;
	  line-height: 40px;
	  width: 80px;
	  height: 40px;
	  border-radius: 3px 0 0 3px;
  } */
  /* .yes{
	  background-color: #FB5C55;
	   color: #FFFFFF;
	   border: 1px solid #FB5C55;
  }
  .no{
	  background-color: #F2F3F7;
	  border: 1px solid #C4C6CF;
	  font-size: 12px;
	  color:#333333 ;
  } */
  
  .server_home .table_container{
    /* padding-left:10px; */
	padding: 0 10px;
  }
  .table_top{
	  width: 100%;
	  display: flex;
	  align-items: center;
	  justify-content: start;
	  flex-direction: row-reverse;
	  font-size: 14px;
	  margin-bottom: 25px;
  }
  .t_r{
	  /* width: 130px; */
	  height: 36px;
	  padding: 0 17px;
	  border: 1px solid #C4C6CF;
	  color: #333333;
	  line-height: 36px;
	  text-align: center;
	  border-radius: 3px;
	  margin-left: 36px;
  }
  /deep/.cell{
	display: flex;
	align-items: center;
	justify-content: start;  
  }
  /deep/.cell img{
	  width: 34px;
	  height: 34px;
	  margin-right: 10px;
  }
  .s_r{
	  height: 40px;
	  display: flex;
	  flex-direction: column;
	  line-height: 40px;
  }
  .name{
	  height: 20px;
	  line-height: 20px;
  }
  .price{
	  height: 20px;
	  line-height: 20px;
	  color: #E91A1B;
  }
  .server_home .page{
    width: 50%;
    margin: 20px auto;
    text-align: center;
  }
  /*为了设置每行的高度不会随着内容的增多而变大设置的样式，需要结合表格行内样式的row-style、cell-style一起来使用*/
  /deep/ .el-table .cell{
    height: 45px;
    line-height: 45px;
    overflow: hidden;
  }
  .marks{
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background: rgba(0,0,0,0.6);
	  z-index: 999;
  }
  /deep/.el-table--mini td, .el-table--mini th{
	  padding: 0;
  }
  /deep/.el-table td, .el-table th{
	  padding: 0;
  }
  /deep/ .el-table::before{
	  background-color: rgba(255,255,255,0);
  }
  /deep/.el-table .descending .sort-caret.descending{
	 border-top-color: #E91A1B !important; 
  }
  /deep/.el-table .ascending .sort-caret.ascending{
  	 border-bottom-color: #E91A1B !important; 
  }
  
</style>
<style>
	.delete{
		  background-color: #E91A1B!important;
		  color: #FFFFFF;
		  border: 1px solid #E91A1B !important;
	}
</style>