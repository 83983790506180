<template>
<div class="head_review">
  <div class="top">
	  <span>团长审核</span>
  </div>
  <div class="tabber_box">
	  <div class="search_item">
	  		<div>模糊搜索内容</div>
	  		<input style="width: 300px;" type="text" placeholder="请输入姓名或电话" v-model="search" />
	  </div>
	  <!-- <div class="search_item">
	  	<div>店铺名称</div>
	  	<input type="text" placeholder="请输入" v-model="store_name"/>
	  </div>
	  <div class="search_item">
		<div>姓名</div>
		<input type="text" placeholder="请输入" v-model="nick_name"/>
	  </div>
	  <div class="search_item">
	  	<div>电话</div>
	  	<input type="text" placeholder="请输入" v-model="phone"/>
	  </div> -->
	  <div class="search_item">
	  	<div>状态</div>
	  	<el-select v-model="status" placeholder="请选择">
	  	    <el-option
	  	      v-for="item in options"
	  	      :key="item.value"
	  	      :label="item.label"
	  	      :value="item.value">
	  	    </el-option>
	  	  </el-select>
	  </div>
	  <div class="search" @click="goSearch">搜索</div>
	  <div class="add" @click="add">新增</div>
  </div>
  <div class="table_container">
   <el-table
     :data="tableData"
      size="mini"
      fit
	  border
	  stripe
      :default-sort="{prop: 'date', order: 'descending'}"
     :row-style="{height: '45px'}"
	  :header-cell-style="{'text-align':'left',padding: '0'}"
     :cell-style="{padding: '0','text-align':'left'}"
     style="width: 100%"
    >
     <el-table-column prop="chief_name" label="姓名" align="center" ></el-table-column>
	 <el-table-column prop="chief_phone" label="手机号" align="center"></el-table-column>
	 <!-- <el-table-column prop="organizers" label="关联会员" align="center"></el-table-column> -->
	 <!-- <el-table-column prop="organizers" label="店铺名称" align="center"></el-table-column> -->
	 <!-- <el-table-column prop="organizers" label="推荐团长" align="center"></el-table-column> -->
	 <el-table-column prop="chief_region" label="小区地址" align="center"></el-table-column>
     <el-table-column prop="create_time" label="申请时间" align="center" style="height: 45px">
		<template slot-scope="scope">
			<div>{{timeFormate(parseInt(scope.row.create_time))}}</div>
		</template>
	 </el-table-column>
	 <el-table-column prop="is_verify" label="状态" align="center">
		 <template slot-scope="scope">
		 	<div v-if="scope.row.is_verify==0">待审核</div>
		 	<div v-if="scope.row.is_verify==1">已通过</div>
			<div v-if="scope.row.is_verify==2">已驳回</div>
		 </template>
	 </el-table-column>
     <el-table-column prop="operation" align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
			style="color:#5584FF;"
            @click="eidt(scope.row)"
          >编辑</el-button>
		  <el-button
		    type="text"
		    size="small"
			style="color: #5584FF;"
		    @click="review(scope.row,scope.$index)"
		  >审核</el-button>
		 <!-- <el-button
		    type="text"
		    size="small"
		  			style="color:#5584FF;"
		    @click="pass(scope.row)"
		  >查看原因</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-row class="page">
        <el-col>
          <div class="pagination">
            <el-pagination
              v-if="paginations.total > 0"
              @current-change="handleCurrentChange"
              :current-page.sync="paginations.page_index"
              :page-size="paginations.page_size"
              layout="prev, pager, next, jumper"
              :total="paginations.total">
            </el-pagination>
          </div>
        </el-col>
      </el-row>
  </div>
  <!-- 审核通过-->
  <div class="marks" v-if="dialog.show">
	   <HeadAccountOperate :dialog="dialog" :form="form" @update="getProfile"></HeadAccountOperate>
  </div>

</div>
</template>

<script>
import { getverifyChiefUserList,doWord } from '@/api/index'
import HeadAccountOperate from '@/components/HeadAccountOperate'
export default {
  name: 'ServerHome',
  data () {
    return {
      fullHeight: '', // fullHeight: document.documentElement.clientHeight  屏幕高度 默认值
      tableData: [],
      // 需要给分页组件传的信息
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10 // 1页显示多少条
      },
	  options: [{
	            value: '0',
	            label: '待审核'
	          }, {
	            value: '1',
	            label: '已通过'
	          }, {
	            value: '2',
	            label: '已驳回'
	          },
		],
	  dialog: {
	    show: false,
	    title: '',
	    option: 'edit'
	  },
	  form: {
	    
	  },
	  is_handle:false,//是否选中已处理
	  store_name:'',//搜索框内容
	  nick_name:'',//搜索框内容
	  phone:'',//搜索框内容
	  status:'',//搜索框内容
	  search:'',//搜索框内容
    }
  },
  components: {
    HeadAccountOperate
  },
  created () {
    // 加载数据表格
    this.getProfile(1,0)
    // 获取屏幕高度
    this.fullHeight = `${document.documentElement.clientHeight}`// 默认值
    const that = this
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        that.fullHeight = window.fullHeight
      })()
    }
  },
  methods: {
	getProfile (pages,type) {
		console.log("切换至")
		console.log(pages,this.pagesNumber)
	  // 获取表格数据
	  const data = {
	    search:this.search,
		status:this.status,
		page: pages? pages:this.pagesNumber,
	    limit: '10',
	  }
	  // 请求数据
	  getverifyChiefUserList(data)
	    .then((res) => {
	      console.log(res)
		  console.log(res.data)
		  this.paginations.total=res.count_page
		  this.tableData=res.data
		  console.log("总共有"+ this.paginations.total+"条数据")
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
    // 根据屏幕的高度实时动态修改分页条数
    changePageSize (fullHeight) {
      if (fullHeight > 801) {
        this.paginations.page_size = 15
      } else {
        this.paginations.page_size = 10
      }
    },
	// 团长审核新增
	add(){
		this.$router.push({  //核心语句
		        path:'/headReviewOperate',   //跳转的路径
		        query:{           //路由传参时push和query搭配使用 ，作用时传递参数
		          type:'add',
				  id:1 , 
		        }
		})
	},
	// 编辑操作
    eidt (row) {
	  console.log('点击编辑')
      console.log(row)
	  this.$router.push({  //核心语句
	          path:'/headReviewOperate',   //跳转的路径
	          query:{           //路由传参时push和query搭配使用 ，作用时传递参数
	            type:'edit',
	  		    id:row.id , 
				row:row
	          }
	  })
    },
	// 审核操作
	review (row, index) {
	  console.log('点击审核')
	  console.log(row)
	  this.$router.push({  //核心语句
	          path:'/headReviewOperate',   //跳转的路径
	          query:{           //路由传参时push和query搭配使用 ，作用时传递参数
	            type:'review',
	  		    id:row.id , 
	  			row:row
	          }
	  })
	},
    // 搜索
    goSearch(){
      console.log(this.search)
      console.log(this.status)
      this.getProfile(1)
    },
    handleCurrentChange (page) {
      const number = String(page)
	  this.pagesNumber=number
	  console.log("切换至"+this.pagesNumber+"页")
      // 当点击切换分页数据时，发送请求给服务器，让其把相应的页码数据内容发送回来
	  const data = {
	    search:this.search,
	    status:this.status,
	    page: pages? pages:this.pagesNumber,
	    limit: '10',
	  }
      console.log(data)
      // 发送请求
      // 请求数据
      getverifyChiefUserList(data)
        .then((res) => {
          console.log(res)
      	  console.log(res.data)
		  this.tableData=res.data
        })
        .catch((err) => {
          console.log(err)
        })

      // // 当前页
      // const sortnum = this.paginations.page_size * (page - 1)
      // const table = this.allTableData.filter((item, index) => {
      //   return index >= sortnum
      // })
      // // 设置默认分页数据
      // this.tableData = table.filter((item, index) => {
      //   return index < this.paginations.page_size
      // })
    },
	// 格式化后台传来的时间戳（年月日时分秒）
	timeFormate (timeStamp) {
	      var date = new Date(timeStamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
	      const Y = date.getFullYear() + '-'
	      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
	      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	      // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
		  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) 
	      const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	      return Y + M + D 
	},

  },
  // 监听屏幕变化
  // watch: {
  //   // 如果 `fullHeight ` 发生改变，这个函数就会运行
  //   fullHeight: function (val) {
  //     if (!this.timer) {
  //       this.fullHeight = val
  //       this.changePageSize(this.fullHeight)
  //       this.getProfile()
  //       console.log(val)
  //       console.log(this.paginations.page_size)
  //       this.timer = true
  //       const that = this
  //       setTimeout(function () { // 频繁触发 resize 函数，会导致页面很卡
  //         that.timer = false
  //       }, 100)
  //     }
  //   }

  // }
}
</script>
<style scoped>
  .head_review{
	width: 100%;
	height: 100%;
    color: #606266;
	/* position: relative; */
	top: 0;
	overflow: auto;
	background-color: #F7F8FA;
  }
  .top{
	  width: 130px;
	  height: 28px;
	  border-left: 4px solid #FB5C55;
	  font-size: 20px;
	  color: #333333;
	  font-weight: bold;
	  margin: 24px 0 10px 20px;
  }
  .tabber_box {
	width: 100%;
	height: 40px;
	font-size: 14px;
	color:#333333 ;
	line-height: 40px;
	margin-top: 23px;
	margin-bottom: 24px;
	display: flex;
	align-items: center;
	justify-content: start;
	margin-left: 20px;
	position: relative;
  }
  .search_item{
	display: flex;
	align-items: center;
	justify-content: start;  
	margin-right: 37px;
  }
  input{
	  width: 180px;
	  height: 32px;
	  margin-left: 13px;
	  border: 1px solid #C4C6CF;
	  padding-left: 12px;
	  border-radius: 3px;
  }
  /deep/.el-select .el-input__inner{
	width: 200px;
	height: 34px;
	margin-left: 10px;
	/* margin-left: 13px; */
	 border: 1px solid #C4C6CF;
	 padding-left: 12px;
	 border-radius: 3px;
  }
  .tabber_box .search{
	  width: 66px;
	  height: 36px;
	  border-radius: 3px;
	  background-color: #E91A1B;
	  color: #FFFFFF;
	  line-height: 36px;
	  text-align: center;
	  margin-left: 37px;
	  
  }
  .add{
	position: absolute;
	right: 30px;
	width: 66px;
	height: 36px;
	border-radius: 3px;
	background-color: #E91A1B;
	color: #FFFFFF;
	line-height: 36px;
	text-align: center;  
  }
  /* .tabber_box span{
	  display: inline-block;
	  text-align: center;
	  line-height: 40px;
	  width: 80px;
	  height: 40px;
	  border-radius: 3px 0 0 3px;
  } */
  /* .yes{
	  background-color: #FB5C55;
	   color: #FFFFFF;
	   border: 1px solid #FB5C55;
  }
  .no{
	  background-color: #F2F3F7;
	  border: 1px solid #C4C6CF;
	  font-size: 12px;
	  color:#333333 ;
  } */
  .head_review .table_container{
    /* padding-left:10px; */
	padding: 0 10px;
  }
  .head_review .page{
    width: 50%;
    margin: 20px auto;
    text-align: center;
  }
  /*为了设置每行的高度不会随着内容的增多而变大设置的样式，需要结合表格行内样式的row-style、cell-style一起来使用*/
  /deep/ .el-table .cell{
    height: 45px;
    line-height: 45px;
    overflow: hidden;
  }
  .marks{
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background: rgba(0,0,0,0.6);
  }
  /deep/.el-table--mini td, .el-table--mini th{
	  padding: 0;
  }
  /deep/.el-table td, .el-table th{
	  padding: 0;
  }
  /deep/ .el-table::before{
	  background-color: rgba(255,255,255,0);
  }
</style>

