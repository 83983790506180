<template>
    <div class="head-nav">
       <div class="left">
         <!-- <img src="../assets/logo_m.png" class='logo'> -->
        <span class='title'>爱家智惠生活后台管理系统</span>
       </div>
      <div class="right">
			<div @click="logout">退出登录</div>
        </div>

      </div>
    </div>
</template>

<script>
export default {
  name: 'HeadNav',
  computed: {
    user () {
      return this.$store.getters.user
    }
  },
  methods: {
    setDialogInfo (cmditem) {
      if (!cmditem) {
        console.log('test')
        this.$message('菜单选项缺少command属性')
        return
      }
      switch (cmditem) {
        case 'info':
          this.showInfoList()
          break
        case 'logout':
          this.logout()
          break
      }
    },
    showInfoList () {
      // 个人信息
      this.$router.push('/infoshow')
      console.log(666)
    },
    logout () {
      // // 清除token
      // localStorage.removeItem('eleToken')
      // // 设置vuex store
      // this.$store.dispatch('clearCurrentState')

      // 页面跳转
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
  .head-nav {
    width: 100%;
    height: 70px;
    min-width: 600px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
    color: #fff;
  }
  .left {
    float: left;
    line-height: 70px;
    /* width: 150px; */
  }
  .logo {
    height: 26px;
    width: 82px;
   /* margin-right: 5px; */
    vertical-align: middle;
    display: inline-block;
  }
 .title {
    vertical-align: middle;
    font-size: 24px;
    font-family: "Microsoft YaHei";
    letter-spacing: 3px;
	color: #E91A1B;
	font-weight: bold;
	margin-left: 30px;
  }
  .right {
    float: right;
    line-height: 70px;
    text-align: right;
    float: right;
    padding-right: 10px;
  }
  .right div{
	  width: 98px;
	  height: 40px;
	  border: 1px solid #E91A1B;
	  border-radius: 3px;
	  font-size: 16px;
	  color:#E91A1B ;
	  text-align: center;
	  line-height: 40px;
	  margin-top: 15px;
  }
</style>
