<template>
	<div class="head_review_operate">
		<div class="top">
		    <span v-if="type=='add'">团长审核新增</span>
			<span v-else>团长审核</span>
		</div>
		<div class="form_box">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
			  <el-form-item label="团长姓名" prop="chief_name">
			    <el-input v-model="ruleForm.chief_name" placeholder="请输入"></el-input>
			  </el-form-item>
			  <el-form-item label="团长手机" prop="chief_phone">
			    <el-input v-model="ruleForm.chief_phone" placeholder="请输入" type="number" onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode) ) )"></el-input>
			  </el-form-item>
			  <el-form-item label="密码" prop="password">
			    <el-input v-model="ruleForm.password"  placeholder="请输入"></el-input>
			  </el-form-item>
			  <el-form-item label="确认密码" prop="password2" v-if="type=='add'">
			    <el-input v-model="ruleForm.password2"  placeholder="请再次输入"></el-input>
			  </el-form-item>
			  <el-form-item label="管理小区" prop="chief_region">
			    <div class="tableDate">
			        <div class="button" style="width:3%;float:right;">
			          <P><el-button class="el-icon-plus" @click.prevent="addRow()"></el-button></P>
			          <p><el-button class="el-icon-minus" @click.prevent="delData()"></el-button></p>
			        </div>
			        <div class="table">
			          <el-table
			            :data="tableData"
			            ref="table"
			            tooltip-effect="dark"
			            border
			            stripe
			            style="width: 95%"
			            @selection-change='selectRow'>
			            <el-table-column type="selection" width="45" align="center"></el-table-column>
			            <el-table-column label="序号"  type="index" width="60" align="center"></el-table-column>
			            <el-table-column  label="小区地址" align="center">
			              <template slot-scope="scope">
			                  <el-input v-model="scope.row.address" @focus="map(scope.row,scope.$index)"></el-input>
			              </template>
			            </el-table-column>
						<el-table-column  prop="operation" label="操作" align="center"  width="100">
						  <template slot-scope="scope">
						    <el-button
						      type="text"
						      size="small"
						  	  style="color:#5584FF;"
						      @click="map(scope.row,scope.$index)"
						    >打开地图</el-button>
						  </template>
						</el-table-column>
			            <!-- <el-table-column label="男猪脚">
			              <template slot-scope="scope">
			                <el-input v-model="scope.row.name"></el-input>
			              </template>
			            </el-table-column>
			            <el-table-column label="女猪脚">
			              <template slot-scope="scope">
			                <el-input v-model="scope.row.loveer"></el-input>
			              </template>
			            </el-table-column>
			            <el-table-column prop="name" label="天气">
			              <template slot-scope="scope">
			                <el-input v-model="scope.row.weather"></el-input>
			              </template>
			            </el-table-column>
			            <el-table-column label="电话">
			              <template slot-scope="scope">
			                <el-input v-model="scope.row.phone"></el-input>
			              </template>
			            </el-table-column>
			            <el-table-column label="牵手日">
			              <template slot-scope="scope">
			                <el-input v-model="scope.row.date"></el-input>
			              </template>
			            </el-table-column>
			            <el-table-column label="纪念日">
			              <template slot-scope="scope">
			                <el-input v-model="scope.row.mdate"></el-input>
			              </template>
			            </el-table-column> -->
			          </el-table>
			        </div>
			      </div>
			   
			  </el-form-item>
			  <el-form-item label="驳回理由" prop="remark" v-if="type=='review'">
			    <el-input v-model="ruleForm.remark"  placeholder="请输入"></el-input>
			  </el-form-item>
			  <el-form-item>
				<el-button type="primary" @click="submitForm('ruleForm',1)" v-if="type=='review'">通过</el-button>
				<el-button type="primary" @click="submitForm('ruleForm',2)" v-if="type=='review'">驳回</el-button>
				<el-button type="primary" @click="submitForm('ruleForm')" v-else>确认</el-button>
				<el-button @click="cancel">取消</el-button>
				<!-- <el-button @click="map">地图</el-button> -->
			  </el-form-item>
			</el-form>
		</div>
		<!-- 地图选择对话框 -->
		<div class="marks" v-if="dialog.show">
			 <Map :dialog="dialog"  @update="getAddress"></Map>
		</div>
	</div>
</template>

<script>
import Map from '@/components/Map.vue'
// 引入中国省市数据
import { regionData,provinceAndCityData, CodeToText,TextToCode } from "element-china-area-data";
import { doChiefUser,verifyChiefUser,editChiefUser } from '@/api/index'
export default{
        data(){
			var validatePass2 = (rule, value, callback) => {
			  if (value !== this.ruleForm.password) {
			    callback(new Error('两次输入密码不一致!'))
			  } else {
			    callback()
			  }
			}
            return{
				dialog: {
				  show: false,//地图选择对话框
				  title: '',
				},
                id:'',//上一页传来的团长审核id
				type:'',//上一页传来的操作类型
				ruleForm: {
					chief_name:'',
					chief_phone:'',
					password:'',
					password2:'',
					chief_region:''
				},
				rules: {
						  chief_name: [
						    { required: true, message: '请输入团长名称', trigger: 'blur' },
							{ min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
						  ],
				          chief_phone: [
				            { required: true, message: '请输入团长手机', trigger: 'blur' },
							{ min:11, max: 11, message: '长度在 11 位', trigger: 'blur' }
				          ],
				          password: [
				            { required: true, message: '请输入密码', trigger: 'blur' },
							{ min: 6, max: 30, message: '长度在 6 到 30 个字符', trigger: 'blur' }
				          ],
				          password2: [
				            { required: true, message: '确认密码不能为空', trigger: 'blur' },
				            {
				              min: 6,
				              max: 30,
				              message: '长度在 6 到 30 个字符',
				              trigger: 'blur'
				            },
				            { validator: validatePass2, trigger: 'blur' }
				          ],
				},
			     tableData: [],
			     selectlistRow: [],
				 selectIndex:null,//点击地图时候的序号
				 arr:null,
            }
        },
		components: {
		  Map
		},
        created(){  //生命周期里接收上一页传来的参数
            this.id = this.$route.query.id //接受参数关键代码
			this.type = this.$route.query.type  //接受参数关键代码
			
			
			if(this.type!='add'){
				this.ruleForm = this.$route.query.row?this.$route.query.row:null  //接受参数关键代码
				this.arr=this.ruleForm.chief_region.split(',')
				let obj={}
				for(let i=0;i<this.arr.length;i++){
					obj={
						address:this.arr[i]
					}
					this.tableData.push(obj)
				}
			}
			console.log(this.id)
			console.log(this.type) 
			console.log(this.ruleForm) 
			console.log(this.tableData)
			// let arr=["天津市", "市辖区", "河东区"]
			// this.selected=this.areaChangeCode(arr)
			// console.log("初始化地址")
			// console.log(this.selected)
        },
		methods:{
			// 新增接口请求
			addReview(){
				let arr=[]
				for(let i=0;i<this.tableData.length;i++){
					arr.push(this.tableData[i].address)
				}
				console.log(arr.join(','))
				const data = {
				  chief_name:this.ruleForm.chief_name,
				  chief_phone:this.ruleForm.chief_phone,
				  chief_region:arr.join(','),
				  password:this.ruleForm.password
				}
				console.log(data)
				doChiefUser(data).then((res) => {
				  console.log(res)
				  if (res.msg === '成功') {
				    // 操作成功
				    this.$message({
				      message: '新增成功！',
				      type: 'success'
				    })
				    this.$router.go(-1);//返回上一层
				  }
				})
				  .catch((err) => {
				    console.log(err)
				  })
			},
			// 修改团长审核接口
			editReview(){
				let arr=[]
				for(let i=0;i<this.tableData.length;i++){
					arr.push(this.tableData[i].address)
				}
				console.log(arr.join(','))
				const data = {
				  id:this.ruleForm.id,
				  chief_name:this.ruleForm.chief_name,
				  chief_phone:this.ruleForm.chief_phone,
				  chief_region:arr.join(','),
				  password:this.ruleForm.password
				}
				console.log(data)
				editChiefUser(data).then((res) => {
				  console.log(res)
				  if (res.msg === '成功') {
				    // 操作成功
				    this.$message({
				      message: '编辑成功！',
				      type: 'success'
				    })
				    this.$router.go(-1);//返回上一层
				  }
				})
				  .catch((err) => {
				    console.log(err)
				  })
			},
			// 通过/拒绝审核请求
			Review(type){
				const data = {
				  id:this.ruleForm.id,
				  is_verify:type,//1 通过 2拒绝
				  remark:this.ruleForm.remark,
				}
				console.log(type)
				verifyChiefUser(data).then((res) => {
				  console.log(res)
				  if (res.msg === '成功') {
				    // 操作成功
				    this.$message({
				      message: '操作成功！',
				      type: 'success'
				    })
				    this.$router.go(-1);//返回上一层
				  }
				})
				  .catch((err) => {
				    console.log(err)
				  })
			},
			submitForm(formName,type) {
				console.log(type)
				console.log(this.tableData) 
			    this.$refs[formName].validate((valid) => {
			       if (valid) {
			           if(this.type=='add'){
						   this.addReview()//新增请求
					   }else if(this.type=='review'){
						   if(type==2 && !this.ruleForm.remark){
							   this.$message.error({
							     message: '请填写驳回理由！',
							   })
						   }else{
							  this.Review(type)//通过/拒绝审核请求  
						   }
						   
					   }else if(this.type=='edit'){
						   this.editReview()//编辑接口
					   }
			       }else {
			         console.log('error submit!!');
			         return false;
			       }
			        });
			      },
			resetForm(formName) {
			    console.log(this.selected)
				this.areaChange()
			    this.$refs[formName].resetFields();
			},
			cancel(){
				console.log("取消")
				 this.$router.go(-1);//返回上一层
			},
			map(data,index){
				console.log(data)
				console.log(index)
				this.selectIndex=index
				this.dialog = {
				  show: true,
				  title: '位置选择',
				}
			},
			// 获取表格选中时的数据
			selectRow (val) {
			   console.log(val)
			   this.selectlistRow = val
		    },
			// 增加行
			addRow () {
			 //  var list = {
			 //    rowNum: '',
			 //    address: this.address,
			 //    name: this.name,
			 //    weather: this.weather,
			 //    phone: this.phone,
			 //    date: this.date,
			 //    mdate: this.mdate,
			 //    loveer: this.loveer,
				// }
			let list={ address: this.address,}
			  // this.tableData.unshift(list)
			   this.tableData.push(list)
			},      
			// 删除方法
			  // 删除选中行
			  delData () {
			    for (let i = 0; i < this.selectlistRow.length; i++) {
			      let val = this.selectlistRow
			      // 获取选中行的索引的方法
			      // 遍历表格中tableData数据和选中的val数据，比较它们的rowNum,相等则输出选中行的索引
			      // rowNum的作用主要是为了让每一行有一个唯一的数据，方便比较，可以根据个人的开发需求从后台传入特定的数据
			      val.forEach((val, index) => {
			        this.tableData.forEach((v, i) => {
			          // if (val.rowNum === v.rowNum) {
					  if (val.address === v.address) {
			            // i 为选中的索引
			            this.tableData.splice(i, 1)
			          }
			        })
			      })
			    }
			    // 删除完数据之后清除勾选框
			    this.$refs.tableData.clearSelection()
			},     
			// 子组件的地址数据传递
			getAddress(data){
				console.log("子组件传来的数据")
				console.log(data)
				console.log(this.selectIndex)
				this.tableData[this.selectIndex].address=data
				console.log(this.tableData[this.selectIndex].address)
			}	
			
		}
    }
</script>

<style scoped="scoped">
.head_review_operate{
	width: 100%;
	height: 100%;
	color: #606266;
	top: 0;
	overflow: auto;
	background-color: #F7F8FA;
}
.top{
	  width: 130px;
	  height: 28px;
	  border-left: 4px solid #FB5C55;
	  font-size: 20px;
	  color: #333333;
	  font-weight: bold;
	  margin: 24px 0 22px 30px;
  }
  .form_box{
	  width: calc(100% - 60px);
	  /* height: 1404px; */
	  padding: 30px;
	  box-sizing: border-box;
	  background: #FFFFFF;
	  margin-left: 30px ;
	  box-shadow: 0px 3px 5px 4px rgba(153, 153, 153, 0.12);
  }
  /deep/.el-form-item{
	  width: 70%;
  }
  /deep/.el-form-item__label{
	 /* margin-right: 10px; */
  }
  /deep/.el-select{
	  left: 0;
	  position: absolute!important;
  }
  .bm-view {
     width: 600px;
  	 height: 350px;
  }
  .search_icon{
  	width: 44px;
  	height: 44px;
  	background: #19223F;
  	box-shadow: 0px 2px 6px 0px rgba(29, 33, 62, 0.4);
  	border-radius: 4px;
  	position: relative;
  	margin-left: 10px;
  	margin-top: 10px;
  }
  .search_icon img{
  	width: 20px;
  	height: 20px;
  	position: absolute;
  	left: 50%;
  	top: 50%;
  	transform: translate(-50%,-50%);
  }
  #sole-searchbox-content{
  	width: 300px;
  	height: 36px;
  	border-radius: 4px;
  	background-color: #EEEEEE;
  	position: relative;
  	display: flex;
  	align-items: center;
  	justify-content: start;
  	margin-top: 10px;
  	margin-left:10px;
  	padding-left: 10px;
  	color: #FFFFFF;
	border: 1px solid #C4C6CF;
  }
  #sole-input{
  	height: 36px;
  	width: 200px;
  	background-color: #EEEEEE;
  	border: none;
  	color: #1E1E1E;
  	outline:none;
	margin-left:10px ;
  }
  #sole-searchbox-content img{
  	width: 20px;
  	height: 20px;
  }
  #sole-searchbox-content .line{
  	width: 1px;
  	height: 14px;
  	background: #FFFFFF;
  	opacity: 0.2;
  	margin:0 23px ;
  }
  /* 去掉百度地图引入的左下角logo及水印 */
  /deep/.BMap_cpyCtrl {
       display: none !important;
  }
  /deep/.anchorBL {
       display: none!important;
  }
  .tableDate{
	  /* width: 800px; */
  }
  .marks{
  	  position: absolute;
  	  top: 0;
  	  left: 0;
  	  width: 100%;
  	  height: 100%;
  	  background: rgba(0,0,0,0.6);
	  z-index: 999;
  }
  /deep/.el-button--primary{
  	  background-color: #E91A1B;
	  border-color:#E91A1B ;
  }
  /*------------------------修改type=number的样式------------------------------------------------*/
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
  input[type="number"]{
      -moz-appearance: textfield;
  }
</style>
