import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Index from '../views/Index.vue'
// 超级管理员权限菜单
import HeadManagement from "../views/HeadManagement.vue"
import GoodsManagement from "../views/GoodsManagement.vue"
import ServiceOrder from "../views/ServiceOrder.vue"
// import GoodsOrder from "../views/GoodsOrder.vue"
import GoodsOrderStatistics from "../views/GoodsOrderStatistics.vue"
import Service from "../views/Service.vue"
import ShopMall from "../views/ShopMall.vue"
import MenuManagement from "../views/MenuManagement.vue"
import HomePage from "../views/HomePage.vue"
import MainAccountDistribution from "../views/MainAccountDistribution.vue"
import HeadAccountDistribution from "../views/HeadAccountDistribution.vue"
import HeadReview from "../views/HeadReview.vue"
import HeadGoodsReview from "../views/HeadGoodsReview.vue"
import CustomerInformation from "../views/CustomerInformation.vue"
import PotentialCustomers from "../views/PotentialCustomers.vue"
import BadQuery from "../views/BadQuery.vue"
import EvaluationFeedback from "../views/EvaluationFeedback.vue"
import HeadShareMoney from "../views/HeadShareMoney.vue"
import HeadReviewOperate from "../views/HeadReviewOperate.vue"
import GoodsFinance from "../views/GoodsFinance.vue"
// 团长权限菜单
import TiXian from "../views/TiXian.vue"
import ServiceOrderManagement from "../views/ServiceOrderManagement.vue"
import MonthlyFlowingWater from "../views/MonthlyFlowingWater.vue"

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/index',
    name: 'index',
    component: Index,
	children: [
	  // // 超级管理员权限菜单页面
	  // { path: '', component: HomePage },
	  { path: '', component: MainAccountDistribution },
	  { path: '/headManagement', name: 'headManagement', component: HeadManagement },
	  { path: '/goodsManagement', name: 'goodsManagement', component: GoodsManagement },
	  { path: '/serviceOrder', name: 'serviceOrder', component: ServiceOrder },
	  // { path: '/goodsOrder', name: 'goodsOrder', component: GoodsOrder },
	  { path: '/goodsOrderStatistics', name: 'goodsOrderStatistics', component: GoodsOrderStatistics },
	  { path: '/service', name: 'service', component: Service },
	  { path: '/shopMall', name: 'shopMall', component: ShopMall },
	  { path: '/menuManagement', name: 'menuManagement', component: MenuManagement },
	  { path: '/home', name: 'home', component: HomePage },
	  { path: '/mainAccountDistribution', name: 'mainAccountDistribution', component: MainAccountDistribution },
	  { path: '/headAccountDistribution', name: 'headAccountDistribution', component: HeadAccountDistribution },
	  { path: '/headReview', name: 'headReview', component: HeadReview },
	  { path: '/headGoodsReview', name: 'headGoodsReview', component: HeadGoodsReview },
	  { path: '/customerInformation', name: 'customerInformation', component: CustomerInformation },
	  { path: '/potentialCustomers', name: 'potentialCustomers', component: PotentialCustomers },
	  { path: '/badQuery', name: 'badQuery', component: BadQuery },
	  { path: '/evaluationFeedback', name: 'evaluationFeedback', component: EvaluationFeedback },
	  { path: '/headShareMoney', name: 'headShareMoney', component: HeadShareMoney },
	  { path: '/headReviewOperate', name: 'headReviewOperate', component: HeadReviewOperate },
	  { path: '/goodsFinance', name: 'goodsFinance', component: GoodsFinance },
	  // // 团长权限菜单页面
	  { path: '/tiXian', name: 'tiXian', component: TiXian },
	  { path: '/serviceOrderManagement', name: 'serviceOrderManagement', component: ServiceOrderManagement },
	  { path: '/monthlyFlowingWater', name: 'monthlyFlowingWater', component: MonthlyFlowingWater },
	]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
