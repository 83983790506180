<template>
  <div class="server">
	<div class="top">
		<span>服务管理</span>
	</div>
    <!-- 左侧树状图 -->
	<el-main class="left">
		<div class="all">
			<div @click="getRightData(0)">全部分类</div>
			<el-button
			  type="text"
			  size="mini"
			  @click="() => add(1)">
			  新建
			</el-button>
		</div>
		<div v-for="(item,index) in list" :key='index' >
			<div class="item">
				<div class="label" :class="[item.son_class_list.length==0?'no':'']" @click="getRightData(1,item.class_name,item)">
					<div class="el-icon-caret-bottom an"  @click="open(index)" v-if="item.isOpen&&item.son_class_list.length>0"></div>
					<div class="el-icon-caret-top an"  @click="open(index)" v-if="!item.isOpen&&item.son_class_list.length>0"></div>
				    {{item.class_name}}
				</div>
				<el-button
				  type="text"
				  size="mini"
				  @click="() => add(2,item)">
				  新建
				</el-button>
			</div>
			<div >
				<div v-for="(ite,inde) in item.son_class_list" :key='inde' class="second" v-show="item.isOpen">
					<div>
						<div class="ite">
							<div class="label" :class="[ite.goods_list.length==0?'no':'']" @click="getRightData(2,ite.class_name,ite,item)">
								<div class="el-icon-caret-bottom an" @click="open(index,inde)"  v-if="ite.isOpen&&ite.goods_list.length>0"></div>
								<div class="el-icon-caret-top an" @click="open(index,inde)"  v-if="!ite.isOpen&&ite.goods_list.length>0"></div>
							    {{ite.class_name}}
							</div>
							<el-button
							  type="text"
							  size="mini"
							  @click="() => add(3,ite)">
							  新建
							</el-button>
						</div>
					</div>
					<div class="three" v-for="(it,idx) in ite.goods_list" :key='idx' v-show="ite.isOpen">
						<div>{{it.goods_name}}</div>
					</div>
				</div>
			</div>
		</div>
	</el-main>
	<!-- 右侧图表 -->  
	<div class="table_container">
	   <div class="table_top">
		  <el-breadcrumb separator-class="el-icon-arrow-right">
		    <el-breadcrumb-item :to="{ path: '/' }">全部分类</el-breadcrumb-item>
		    <el-breadcrumb-item v-if="right_type>=1">{{head_one}}</el-breadcrumb-item>
		    <el-breadcrumb-item v-if="right_type>=2">{{head_second}}</el-breadcrumb-item>
		    <!-- <el-breadcrumb-item>活动详情</el-breadcrumb-item> -->
		  </el-breadcrumb>	  
	   </div>
	   <el-table
	     :data="tableData"
	     size="mini"
	     fit
		   border
		   stripe
	     :default-sort="{prop:'date', order: 'descending'}"
	     :row-style="{height: '45px'}"
		   :header-cell-style="{'text-align':'left',padding: '0'}"
	     :cell-style="{padding: '0','text-align':'left'}"
	     style="width: 100%"
		   @selection-change="handleSelectionChange"
	  >
		 <el-table-column prop="id" label="ID" align="center" ></el-table-column>
		 <el-table-column prop="class_name" label="一级分类名称" align="center"  v-if="right_type==0">
			 <template slot-scope="scope">
			 	<div>{{scope.row.class_name}}</div>
			 </template>
		 </el-table-column>
		 <el-table-column prop="class_name" label="二级分类名称" align="center"  v-if="right_type==1">
		 	<template slot-scope="scope">
		 		<div>{{scope.row.class_name}}</div>
		 	</template>		 
		 </el-table-column>
	     <el-table-column prop="goods_name" label="三级分类名称" align="center"  v-if="right_type==2">
			<template slot-scope="scope">
				<div>{{scope.row.goods_name}}</div>
			</template> 
		 </el-table-column>
		 <el-table-column prop="is_home" label="分类是否显示到首页" align="center"  v-if="right_type==0">
		 	<template slot-scope="scope">
		 		<el-switch
		 		  v-model="scope.row.is_home==1?true:false"
		 		  @change="changeOpen($event,scope.row)"
		 		  >
		 		</el-switch>
		 	</template>
		 </el-table-column>
		 <el-table-column prop="is_shelf" label="状态" align="center" v-if="right_type==2">
			 <template slot-scope="scope">
			 	<el-switch
			 	  v-model="scope.row.is_shelf==1?true:false"
				  @change="changeSwitch($event,scope.row)"
			 	  >
			 	</el-switch>
			 </template>	 
		 </el-table-column>
		 <el-table-column prop="is_hot" label="是否设置到首页精选" align="center" v-if="right_type==2">
		 	<template slot-scope="scope">
		 		<el-switch
		 		  v-model="scope.row.is_hot==1?true:false"
		 		  @change="changeHot($event,scope.row)"
		 		  >
		 		</el-switch>
		 	</template>			  
		 </el-table-column>
		 <el-table-column prop="sort" label="排序(数值越大排名越前)" align="center"  v-if="right_type==2"></el-table-column>
		 <!-- <el-table-column prop="organizers" label="商品数量" align="center"  v-if="right_type==2"></el-table-column> -->
		 <!-- <el-table-column prop="organizers" label="绑定属性" align="center" ></el-table-column> -->
		 <el-table-column prop="create_time" label="创建时间" align="center" style="height: 45px"  v-if="right_type==2">
		 	<template slot-scope="scope">
		 		<div>{{timeFormate(parseInt(scope.row.create_time))}}</div>
		 	</template>	
		 </el-table-column>
	   <el-table-column prop="operation" align="center" label="操作">
	      <template slot-scope="scope">
			<el-button
			  type="text"
			  size="small"
				style="color:#5584FF;"
			  @click="check(scope.row)"
			>查看</el-button>
	        <el-button
	          type="text"
	          size="small"
				style="color:#5584FF;"
	          @click="edit(scope.row)"
	        >编辑</el-button>
			  <el-button
			    type="text"
			    size="small"
				style="color: #5584FF;"
			    @click="onDelete(scope.row,scope.$index)"
			  >删除</el-button>
	      </template>
	    </el-table-column>
	  </el-table>
	  <!-- 分页 -->
	  <el-row class="page">
	      <el-col>
	        <div class="pagination">
	          <el-pagination
	            v-if="paginations.total > 0"
	            @current-change="handleCurrentChange"
	            :current-page.sync="paginations.page_index"
	            :page-size="paginations.page_size"
	            layout="prev, pager, next, jumper"
	            :total="paginations.total">
	          </el-pagination>
	        </div>
	      </el-col>
	    </el-row>
	</div>
	<!-- 对话框-->
	<div class="marks" v-if="dialog.show">
		<ServiceOperate :dialog="dialog" :form="form" @update="serviceList" v-if="dialog.level==3"></ServiceOperate>
		<ClassOperate :dialog="dialog" :form="form" @update="getTree" v-else></ClassOperate>
	</div>
  </div>
</template>

<script>
let id = 1000;
import { getClassList,getServiceInfo,getServiceList,ServiceIsShelf,doClass,doService,SetIshome,SetHot} from '@/api/index'
import ClassOperate from '@/components/ClassOperate.vue'
import ServiceOperate from '@/components/ServiceOperate.vue'
export default {
  name: 'Category',
  data() {
    return {
	  fullHeight: '', // fullHeight: document.documentElement.clientHeight  屏幕高度 默认值
      list: [],
	  tableData: [],
	  // 需要给分页组件传的信息
	  paginations: {
	    page_index: 1, // 当前位于哪页
	    total: 0, // 总数
	    page_size: 10 // 1页显示多少条
	  },
	  dialog: {
	    show: false,
	    title: '',
	    option: ''
	  },
	  form: {},
	  right_type:'',//右侧菜单选中的类型
	  head_one:'',//右侧头部一级分类名称
	  head_second:'',//右侧头部二级分类名称
	  second_class_id:'',//点击的二级分类id
    }
  },
  components: {
  	ClassOperate,
	ServiceOperate
  },
  created () {
	// 加载左侧树形图数据
	 this.getTree()
    // 加载数据表格
    // 获取屏幕高度
    this.fullHeight = `${document.documentElement.clientHeight}`// 默认值
    const that = this
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        that.fullHeight = window.fullHeight
      })()
    }
  },
  methods: {
	 // 加载树形图数据
	 getTree() {
	   // 获取数据
	   const data = {}
	   // 请求数据
	   getClassList(data)
	     .then((res) => {
		   console.log("获取树状图数据")
	       console.log(res)
	 	   console.log(res.data)
		   this.list=res.data
		   this.tableData=res.data//初始话右侧得表格，默认为选择全部分类
		   for(let i=0;i<this.list.length;i++){
			   this.list[i].isOpen=false
			   for(let j=0;j<this.list[i].son_class_list.length;j++){
				   this.list[i].son_class_list[j].isOpen=false
			   }
		   }
		   console.log("list")
		   console.log(this.list)
	     })
	     .catch((err) => {
	       console.log(err)
	     })
	 },
	 // 获取单个服务详情
	 getSerInfo(id) {
	   // 获取数据
	   const data = {
		   g_id:id
	   }
	   // 请求数据
	   getServiceInfo(data)
	     .then((res) => {
	 	   console.log("获取服务详情数据")
	       console.log(res)
	 	   console.log(res.data)
		   this.form=res.data
		   
	     })
	     .catch((err) => {
	       console.log(err)
	     })
	 },
	 // 获取二级分类下的所有服务列表
	 serviceList(pages) {
		 console.log("切换至")
		 console.log(pages,this.pagesNumber)
	   // 获取数据
	   const data = {
	 		class_id:this.second_class_id,
			page: pages? pages:this.pagesNumber,
			limit: '10',
	   }
	   // 请求数据
	   getServiceList(data)
	     .then((res) => {
	 	   console.log("获取二级分类下的服务列表")
	       console.log(res)
	 	   console.log(res.data)
	 	   this.tableData=res.data
		   this.paginations.total=res.count_page
		   this.tableData=res.data
		   console.log("总共有"+ this.paginations.total+"条数据")
	 		   
	     })
	     .catch((err) => {
	       console.log(err)
	     })
	 },
	 // 服务上下架请求
	 serviceIsShelf(row) {
	   // 获取数据
	   const data = {
	 		g_id:row.id,
			is_shelf:row.is_shelf==0?1:0
	   }
	   // 请求数据
	   ServiceIsShelf(data)
	     .then((res) => {
	 	   console.log("服务上下架")
	       console.log(res)
	 	   console.log(res.data)
		   if(res.code==200){
			 this.serviceList()   
		   }
	 	     
	     })
	     .catch((err) => {
	       console.log(err)
	     })
	 },
	 // 分类设置是否显示到首页
	 Sethome(row) {
	   // 获取数据
	   const data = {
	 		id:row.id,
			is_home:row.is_home==true?0:1, //1 显示 0不显示
	 		type:1 //1 服务 2 产品
	   }
	   // 请求数据
	   SetIshome(data)
	     .then((res) => {
	 	   console.log("分类设置是否显示到首页")
	       console.log(res)
	 	   console.log(res.data)
	 	   if(res.code==200){
	 		this.getTree()  
			console.log('需要刷新')
	 	   }else if(res.code==404)
	 	     this.$message.error(res.msg);
			 row.is_home=false
			 
	     })
	     .catch((err) => {
	       console.log(err)
	     })
	 },
	 // 设置服务到首页精选
	 Sethot(row) {
	   // 获取数据
	   const data = {
	 		id:row.id,
	 		is_hot:row.is_hot==true?0:1, //1 显示 0不显示
	   }
	   // 请求数据
	   SetHot(data)
	     .then((res) => {
	 	   console.log("设置服务到首页精选")
	       console.log(res)
	 	   console.log(res.data)
	 	   if(res.code==200){
	 		   this.serviceList()    
	 	      console.log('需要刷新')
	 	   }else if(res.code==404)
	 	     this.$message.error(res.msg);
	 			 row.is_hot=false
	 			 
	     })
	     .catch((err) => {
	       console.log(err)
	     })
	 },
	// 分类添加
	add(type,data){
		console.log("分类添加")
		console.log(data)
		this.dialog = {
			  show: true,
			  title: '服务新增',
			  option: 'add',
			  level:type,
			}
		if(type!=3){
			this.form = {
				id:'',
				icon_img:'',
				parent_id:data?data.id:0,
				class_name:'',
			}
		}else{
			this.form={
				id:'',
				goods_name:'',
				class_id:data.id,
				goods_img:'',
				goods_img_str:'',
				sort:'',
				is_shelf:1,
				model_list:'',
			}
		}
		
	},
	// 菜单展开/隐藏
	open(index,inde){
		console.log("菜单展开/隐藏")
		console.log(index,inde)
		if(inde!=undefined){
			this.list[index].son_class_list[inde].isOpen=!this.list[index].son_class_list[inde].isOpen
			this.$forceUpdate()
		}else{
			this.list[index].isOpen=!this.list[index].isOpen
			this.$forceUpdate()
		}
	},
	// 点击左侧菜单标签名称获得右侧数据
	getRightData(type,name,data,fatherData){
		console.log("点击菜单")
		this.right_type=type
		if(type==1){
			this.head_one=name
			this.tableData=data.son_class_list
		}else if(type==2){
			console.log(2)
			this.head_one=fatherData.class_name
			this.head_second=name
			this.second_class_id=data.id//二级分类id
			// this.tableData=data.goods_list
			this.serviceList(1)
			this.$forceUpdate()
		}else if(type==0){
			this.tableData=this.list
		}
		
		console.log(type)
		console.log(name)
		console.log(data)
		console.log(fatherData)
		console.log('数据')
		console.log(this.tableData)
	},
	// 根据屏幕的高度实时动态修改分页条数
	changePageSize (fullHeight) {
	  if (fullHeight > 801) {
	    this.paginations.page_size = 15
	  } else {
	    this.paginations.page_size = 10
	  }
	},
	// 表格数据选中方法
	handleSelectionChange(val) {
		console.log(val)
	   this.multipleSelection = val;
	   console.log( this.multipleSelection)
	},
	// 表格中分类是否显示在首页操作
	changeOpen(e,row){
		console.log("是否显示在首页操作")
		console.log(e)
		console.log(row)
		this.Sethome(row)
	},
	// 表格中服务上下架操作
	changeSwitch(e,row){
		console.log("开关变化")
		console.log(e)
		console.log(row)
		this.serviceIsShelf(row)
	},
	// 表格中是否设置为首页精选
	changeHot(e,row){
		console.log("首页精选操作")
		console.log(e)
		console.log(row)
		this.Sethot(row)
	},
	// 查看操作
	check(row){
		console.log('点击查看')
		console.log(row)
		console.log(this.right_type)
		this.dialog = {
		  show: true,
		  title:this.right_type+1<3? '分类查看':'服务查看',
		  option: 'check',
		  level:this.right_type+1
		}
		if(this.right_type+1==3){
			this.getSerInfo(row.id)
		}else{
			this.form = row
		}
		// this.form = row
		console.log("传递的from")
		console.log(this.form)
		
	},
	// 编辑操作
	edit (row) {
	  console.log('点击编辑')
	  console.log(row)
	  // console.log(row.t_ids)
	  this.dialog = {
	    show: true,
	    title: this.right_type+1<3?'分类编辑':'服务编辑',
	    option: 'edit',
		level:this.right_type+1
	  }
	  if(this.right_type+1==3){
	  	this.getSerInfo(row.id)
	  }else{
	  	this.form = row
	  }
	    console.log("传递的from")
		console.log(this.form)
	},
	//删除操作
	onDelete (row, index) {
	  // 删除提示
	  console.log(row, index)
	  this.$confirm('确认要删除吗，删除后不可恢复', {
	    confirmButtonText: '删除',
	    cancelButtonText: '取消',
	    confirmButtonClass: 'delete',
	  }).then(() => {
		  console.log(this.right_type)
		  if(this.right_type<2){ //分类删除
		    const data = {
		      act:'del',
		      id:row.id,
		      icon_img:row.icon_img,
		      parent_id:row.parent_id,
		      class_name:row.class_name,
		      level:''
		    }
		    console.log(data)
		    doClass(data).then((res) => {
		      console.log(res)
		      if (res.msg === '成功') {
		        // 操作成功
		        this.$message({
		          message: '删除成功！',
		          type: 'success'
		        })
		        this.getTree()//刷新数据
		      }
		    })
		      .catch((err) => {
		        console.log(err)
		      })
			  
		  }else{//服务删除
			// 删除请求
			  const data = {
			    act: 'del',
			    id:row.id,
			    class_id:row.class_id,
			    goods_name:row.goods_name,
			    goods_img:row.goods_img,
			    goods_img_str:row.goods_img_str,
			    sort:row.sort,
			    is_shelf:row.is_shelf,
			    model_json:'',
			  }
			  console.log(data)
			  doService(data).then((res) => {
			    console.log(res)
			    if (res.msg === '成功') {
			      // 操作成功
			      this.$message({
			        message: '删除成功！',
			        type: 'success'
			      })
				  this.getTree()//刷新数据
			     this.serviceList()
			    }
			  })
			    .catch((err) => {
			      console.log(err)
			    })
			}
	 
	  })
	  // }).catch(() => {
			//   this.$message({
			//     type: 'info',
			//     message: '已取消删除'
			//   })  
		 //  }
	},
	handleCurrentChange (page) {
	  const number = String(page)
	  this.pagesNumber=number
	  console.log("切换至"+this.pagesNumber+"页")
	  // 当点击切换分页数据时，发送请求给服务器，让其把相应的页码数据内容发送回来
	  const data = {
	    page: number,
	    limit: '10',
		class_id:this.second_class_id//二级分类id
	  }
	  console.log(data)
	  // 发送请求
	  // 请求数据
	  getServiceList(data)
	    .then((res) => {
	      console.log(res)
	  	  console.log(res.data)
		  this.tableData=res.data
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	// 格式化后台传来的时间戳（年月日时分秒）
	timeFormate (timeStamp) {
	      var date = new Date(timeStamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
	      const Y = date.getFullYear() + '-'
	      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
	      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	      // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
		  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) 
	      const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	      return Y + M + D + h + m + s
	},
  }
}
</script>

<style scoped>
 .server{
	width: 100%;
	height: 100%;
    color: #606266;
	top: 0;
	overflow: auto;
	background-color: #F3F3F3;
	/* padding-top: 24px; */
	box-sizing: border-box;
  }
  .top{
	  /* margin-top: 24px; */
	  width: 100px;
	  height: 28px;
	  border-left: 4px solid #FB5C55;
	  font-size: 20px;
	  color: #333333;
	  font-weight: bold;
	  margin: 24px 0 10px 20px;
  }	
  
 .left{
	 position: absolute;
	 left: 20px;
	 width: 230px;
	/* height: 90%; */
	 height: 85%;
	 padding-top: 20px;
	 box-shadow: 0 3px 5px 4px #999999;
	 background-color: #FFFFFF;
	 font-size: 14px!important;
	 color: #333333;
	 margin-top: 20px;
 }
 .all{
	margin-left: 10%;
 	width: 80%;
 	display: flex;
 	justify-content: space-between;
 	align-items: center;  
 }
 .item{
	 margin-left: 10%;
	 width: 80%;
	 display: flex;
	 justify-content: space-between;
	 align-items: center;
 }
 .second{
	 width: 75%;
	 margin-left: 15%;
	 /* background-color: #EB7E10; */
 }
 .ite{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center; 
 }
 .three{
	 width: 80%;
	 margin-left: 20%;
	 text-align: left;
 }
 .label{
	display: flex;
	justify-content: start;
	align-items: center;
 }
 .no{
	 margin-left: 26px;
 }
 .an{
	 margin-right: 10px;
 }
 .custom-tree-node {
     flex: 1;
     display: flex;
     align-items: center;
     justify-content: space-between;
     font-size: 14px;
     padding-right: 8px;
   }
  /deep/.el-tree-node__content{
	  height: 30px;
	  margin-top: 5px;
	  font-size: 12px!important;
  }
 
.table_container{
    /* padding-left:10px; */
	padding: 0 10px;
	position: absolute;
	right: 0;
	width: calc(100% - 280px);
	margin-top: 20px;
  }
  .table_top{
	  width: 100%;
	  display: flex;
	  align-items: center;
	  justify-content: start;
	  font-size: 14px;
	  margin-bottom: 25px;
  }
  .t_r{
	  /* width: 130px; */
	  height: 36px;
	  padding: 0 17px;
	  border: 1px solid #C4C6CF;
	  color: #333333;
	  line-height: 36px;
	  text-align: center;
	  border-radius: 3px;
	  margin-left: 36px;
  }
  /deep/.cell{
	display: flex;
	align-items: center;
	justify-content: start;  
  }
  /deep/.cell img{
	  width: 34px;
	  height: 34px;
	  margin-right: 10px;
  }
  .s_r{
	  height: 40px;
	  display: flex;
	  flex-direction: column;
	  line-height: 40px;
  }
  .name{
	  height: 20px;
	  line-height: 20px;
  }
  .price{
	  height: 20px;
	  line-height: 20px;
	  color: #E91A1B;
  }
   .page{
    width: 50%;
    margin: 20px auto;
    text-align: center;
  }
  /*为了设置每行的高度不会随着内容的增多而变大设置的样式，需要结合表格行内样式的row-style、cell-style一起来使用*/
  /deep/ .el-table .cell{
    height: 45px;
    line-height: 45px;
    overflow: hidden;
  }
  /deep/.el-switch.is-checked .el-switch__core{
	border-color: #E91A1B;
	background-color: #E91A1B;
  }
  .marks{
  	  position: absolute;
  	  top: 0;
  	  left: 0;
  	  width: 100%;
  	  height: 100%;
  	  background: rgba(0,0,0,0.6);
  	  z-index: 999;
  }
  /deep/.el-table--mini td, .el-table--mini th{
  	  padding: 0;
  }
  /deep/.el-table td, .el-table th{
  	  padding: 0;
  }
  /deep/ .el-table::before{
  	  background-color: rgba(255,255,255,0);
  }
  /deep/.el-table .descending .sort-caret.descending{
  	 border-top-color: #E91A1B !important; 
  }
  /deep/.el-table .ascending .sort-caret.ascending{
  	 border-bottom-color: #E91A1B !important; 
  }
</style>
<style>
	.delete{
		  background-color: #E91A1B!important;
		  color: #FFFFFF;
		  border: 1px solid #E91A1B !important;
	}
	
</style>

