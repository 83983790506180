import axios from 'axios'
// import { Message,Loading } from 'element-ui'
import { Loading } from 'element-ui'
// import router from '@/router'

// 进行一些全局配置
// axios.defaults.baseURL = 'https://lovehome.dysense.cn' // 请求的服务器域名
axios.defaults.baseURL = 'https://testlove.dysense.cn' // 请求的服务器域名
axios.defaults.timeout = 5000

let loading // 定义loading变量

function startLoading () { // 使用Element loading-start 方法
  loading = Loading.service({
    lock: true,
    text: '加载中...',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}
function endLoading () { // 使用Element loading-close 方法
  loading.close()
}

// let count = 0
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // count++
  startLoading()
  // if (localStorage.eleToken) { config.headers.Authorization = localStorage.eleToken }
  return config
}, function (error) {
  // 对请求错误做些什么
  endLoading()
  return Promise.reject(error)
})

// 添加响应拦截器 401 token过期处理
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  // count--
  // if (count === 0) {
  //   endLoading()
  // }
  endLoading()
  return response
}, function (error) {
  // 对响应错误做点什么
  endLoading()
  // Message.error(error.response.data)
  // Message.error(error.response)
  console.log(error)
  console.log('响应错误')

  // const { status } = error.response
  // if (status === 401) {
  //   Message.error('token值无效，请重新登录')
  //   // 清除token
  //   localStorage.removeItem('eleToken')
  //
  //   // 页面跳转
  //   router.push('/login')
  // }
  return Promise.reject(error)
})

// 封装自己的get/post方法
export default {
  get: function (path = '', data = {}) {
    return new Promise(function (resolve, reject) {
      axios.get(path, {
        params: data
      })
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  post: function (path = '', data = {}, isImport) {
    if (isImport) {
      console.log(axios.defaults.baseURL + path)
      return axios.defaults.baseURL + path
    } else {
      return new Promise(function (resolve, reject) {
        axios.post(path, data)
          .then(function (response) {
            resolve(response.data)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    }
  },
  all: function (list) {
    return new Promise(function (resolve, reject) {
      axios.all(list)
        .then(axios.spread(function (...result) {
          // 两个请求现在都执行完成
          resolve(result)
        }))
        .catch(function (err) {
          reject(err)
        })
    })
  }
}
