<template>
<div class="service_op">
  <div class="manage_tip">
    <p class="title">{{ dialog.title }}</p>
	<el-button  icon="el-icon-close" @click="dialog.show = false" class="close"></el-button>
  </div>
  <el-main class='main'>
	  <el-form
	    ref="form"
	    :model="form"
		:rules="rules"
	    label-width="60px"
	    style="width:100%"
	  >
	   <!-- <el-form-item prop="id" label="ID">
		  <el-input type="text" v-model="form.id" placeholder="请输入" v-if="dialog.option=='check'" :disabled="true"></el-input>
	      <el-input type="text" v-model="form.id" placeholder="请输入" v-else></el-input>
	    </el-form-item> -->
	  	
	  	<el-form-item prop="class_name" label="名称">
		   <el-input type="text" v-model="form.class_name" placeholder="请输入" v-if="dialog.option=='check'" :disabled="true"></el-input>
	  	  <el-input type="text" v-model="form.class_name" placeholder="请输入名称" v-else></el-input>
	  	</el-form-item>
	  	
	  	<!-- <el-form-item prop="icon_img" label="图片" v-if="dialog.level=='2'||dialog.level=='3'"> -->
		<el-form-item prop="icon_img" label="图片" v-if="dialog.level=='1'||dialog.level=='3'">
	  	  <div class="img" v-if="form.icon_img">
	  	    <img :src="SetImg(form.icon_img)"></img>
	  	  		<i @click="deleteVoice()" v-if="dialog.option!='check'"></i>
	  	  </div>
	  	  <el-upload
	  	    class="upload-demo"
	  	    :action="updateUrl"
	  	    :on-progress="handleProgress"
	  	    :on-success="handleSuccessBattery"
	  	    :on-remove="handleRemove"
	  	    :before-remove="beforeRemove"
	  	    style="border: 1px solid #DCDFE6;width: 285px;height: 194px;line-height: 30px;margin-left:5% ;padding-top: 80px;box-sizing: border-box;color: #666666!important;border-radius: 4px;margin-bottom: 10px;"
	  	  	v-if="!form.icon_img"
	  	  >
	  	    <!-- <svg-icon data_iconName="Clipped" class="Clipped"/> -->
	  	  		 <i class="el-icon-upload"></i>
	  	    <span class="el-upload__text">点击此处上传图片</span>
	  	    <!--        <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>-->
	  	  </el-upload>
	  	</el-form-item>
	  
		<!-- <el-form-item prop="organizers" label="型号管理" v-if="(dialog.option=='add'&&dialog.level=='3')||(dialog.option=='edit'&&dialog.level=='3')" >
		  <div class="tableDate" style="margin-left: 20px;">
		      <div class="button" style="width:36px;float:right;">
		        <P><el-button class="el-icon-plus" @click.prevent="addRow()"></el-button></P>
		        <p><el-button class="el-icon-minus" @click.prevent="delData()"></el-button></p>
		      </div>
		      <div class="table">
		        <el-table
		          :data="tableData"
		          ref="table"
		          tooltip-effect="dark"
		          border
		          stripe
				  :row-style="{height: '45px'}"
				  :header-cell-style="{'text-align':'left',padding: '0'}"
				  :cell-style="{'text-align':'left',padding: '0'}"
		          style="width: 90%"
		          @selection-change='selectRow'>
		          <el-table-column type="selection" width="45" align="center"></el-table-column>
		          <el-table-column label="序号"  type="index" width="60" align="center"></el-table-column>
		          <el-table-column  label="型号名称" align="center">
		            <template slot-scope="scope">
		                <el-input v-model="scope.row.name"></el-input>
		            </template>
		          </el-table-column>
		  		<el-table-column label="价格" align="center"  width="100">
		  		  <template slot-scope="scope">
		  		      <el-input v-model="scope.row.price"></el-input>
		  		  </template>
		  		</el-table-column>
			  </el-table>
			 </div>
		  </div>
		</el-form-item> -->
		
		<!-- <el-form-item prop="organizers" label="驳回理由" v-if="dialog.option=='pass'||dialog.option=='refuse'">
		  <el-input type="text" v-model="form.reason" placeholder="请输入"></el-input>
		</el-form-item> -->
	  
	  
	  
	  	<div class="text_right" v-if="dialog.option!='check'">
	      <el-button type="primary" @click="onSubmit('form')" class="primary">
			  <span v-if="dialog.option=='add'||dialog.option=='goods_add'">发 布</span>
			  <span v-if="dialog.option=='edit'">确 定</span>
			  <!-- <span v-if="dialog.option=='check'">通 过</span> -->
			  <!-- <span v-if="dialog.option=='pass'||dialog.option=='refuse'">通 过</span> -->
		  </el-button>
		  <!-- <el-button type="primary" class="no" v-if="dialog.option=='pass'||dialog.option=='refuse'">
		  	  <span>驳 回</span>
		  </el-button> -->
		  
	    </div>
	  </el-form>
  </el-main>
  
</div>
</template>

<script>
import {doProClass} from '@/api/index'
export default {
  name: 'WordOperate',
  props: {
    dialog: Object,
    form: Object
  },
  data () {
    return {
	  password:'',//删除时输入的密码
      loginUser: {
		 person_name:'',
		 person_pass:''
	  },
      // updateUrl: "https://lovehome.dysense.cn/admin/User/uploads_img",
	  updateUrl: "https://testlove.dysense.cn/admin/User/uploads_img",
      batterty_img: '',
	  // tableData: [
	  // 					 {
	  // 					           price: 100,
	  // 					           name: '型号1',
	  // 					 }, {
	  // 					           price: 80,
	  // 					           name: '型号2',
	  // 					         }, 				
	  // ],
	  rules: {
	  		  class_name: [
	  		    { required: true, message: '请输入名称', trigger: 'blur' },
	  		  ],
	            icon_img: [
	              { required: true, message: '请选择图片', trigger: 'blur' },
	            ],
	  },
	  // selectlistRow: [],
    }
  },
  mounted () {},
  methods: {
	// 分类增删改（一级/二级）
	classApi(str){
		const data = {
		  act:str,
		  id:this.form.id,
		  icon_img:this.form.icon_img,
		  parent_id:this.form.parent_id,
		  class_name:this.form.class_name,
		  level:this.dialog.level
		}
		console.log(data)
		doProClass(data).then((res) => {
		  console.log(res)
		  if (res.msg === '成功') {
		    // 操作成功
		    this.$message({
		      message: '操作成功！',
		      type: 'success'
		    })
			this.dialog.show=false
		    this.$emit('update');//刷新数据
		  }
		})
		  .catch((err) => {
		    console.log(err)
		  })
	},
    // 图片删除方法
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    // 上传图片钩子方法
    handleProgress (event, file, fileList) {
      console.log(file)
      // setTimeout((file) => {
      //   console.log(file.response)
      // }, 5000)
    },
    // 上传图片成功
    handleSuccessBattery  (res, file, fileList) {
	 // this.$set(this.form, 'pronunciation_file',res.data)
      this.form.icon_img = res.data
	  console.log("成功！")
      console.log(this.form.icon_img)
    },
    handleSuccessType (res, file, fileList) {
      this.vehicle_model_img = res.data
      console.log(this.vehicle_model_img)
    },
    // 上传图片删除
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 提交按钮
    onSubmit (form) {
		console.log(111)
		console.log(this.form)
		console.log(this.tableData)
      this.$refs[form].validate(valid => {
        if (valid) {
          // 表单数据验证完成之后，提交数据;
		  if(this.dialog.option=='add'){
			 this.classApi('add')    
		  }else{
			 this.classApi('edit')   
		  }
	     
        }
      })
    },
    // // 获取图片上传的地址
    // getUrl (data) {
    //   this.updateUrl = uploads_file()
    //   console.log(this.updateUrl)
    // },
	// 给请求回来的图片地址加上后台服务器地址，可以正常显示
	SetImg(value){
		// var str ="https://lovehome.dysense.cn" + value
		var str ="https://testlove.dysense.cn" + value
		if(value != ""){
			return str
		}
	},
    // 删除编辑界面中原有图片的方法
    deleteVoice () {
      console.log('delete')
	  this.form.icon_img = ''
	  this.$forceUpdate()
    },
	// 格式化后台传来的时间戳（年月日时分秒）
	timeFormate (timeStamp) {
	      var date = new Date(timeStamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
	      const Y = date.getFullYear() + '-'
	      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
	      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	      // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
		  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) 
	      const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	      return Y + M + D + h + m + s
	},
  }
}
</script>

<style scoped>
.service_op{
  /* width: 418px; */
  width: 520px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background-color: #FFFFFF;
  opacity: 1;
  /*height: 100%;*/
}
/* .manage_tip{
	position: relative;
} */
.manage_tip .title {
  /*height: 20px;*/
  font-family: "Microsoft YaHei";
  margin: 25px auto;
  width: 100px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #303133;
}
.close{
	position: absolute;
	right: 0;
	top: 0;
	border: none;
}
.pic{
  display: flex;
}
.main{
	/* height: 500px; */
	padding: 0 20px;
}
.img{
	width: 280px;
	height: 194px;
  margin-left: 5%;
  position: relative;
}
.img img{
  width: 280px;
  height: 194px;
}
.img i{
  width: 17px;
  height: 17px;
  display: inline-block;
  background: url("../assets/del.png") center no-repeat;
  background-size: 100%;
  position: absolute;
  right: -10px;
  top: -5px;
}
/deep/ .el-input__inner{
  height:40px;
  width: 90%;
  margin-bottom: 10px;
}
/deep/ .el-form{
  margin: 0;
}
/deep/ .el-form-item{
  width: 100%;
  /* display: flex !important; */
  /* justify-content: start; */
  margin-bottom: 2%;
}
/deep/ .el-form-item__content{
 /*  width: 85%;
  display: flex;
  position: relative;
  margin-left: 0!important; */
  /* margin-left: 80px !important; */
}
/deep/ .el-form-item__label{
  text-align: right;
  /* margin-left: 25px !important; */
  padding: 0;
  /*margin-right: 5%;*/
}
/deep/ .el-select{
	/* margin-left: 3%; */
	width: 100%;
	text-align: center;
	position: relative;
}
/deep/ .el-select{
	width:100%;
	margin: 0 auto;
	left: 0;
	text-align: center;
}
/deep/ .el-tag.el-tag--info{
	margin-left: 35px;
	margin-bottom: 10px;
}
/deep/ .el-input__suffix{
	margin-right: 30px;
	height: 40px;
}
/deep/ .el-upload--text{
	color: #C4C6CF !important;
}
/deep/.el-select .el-input__inner{
	width: 370px!important;
	height: 40px !important;
	border: 1px solid #DCDFE6 !important;
} 
.text_right{
	/* background-color: #0486FE; */
	width:200px;
	/* text-align: center; */
	display: flex;
	justify-content: center;
	margin: 0 auto;
	margin-bottom: 20px;
	margin-top: 10px;
	
}
.text_right1{
	/* background-color: #0486FE; */
	width:200px;
	text-align: center;
	margin: 0 auto;
	margin-bottom: 20px;
	margin-top: 10px;
	
}
.primary{
	background-color: #E91A1B !important;
	border: none;
	color: #FFFFFF;
}
.no{
	display: inline-block;
	background-color: none !important;
	color: #333333 !important;
	width: 66px;
	height: 40px;
	background: #FFFFFF;
	border-radius: 3px;
	border: 1px solid #C4C6CF;
	margin-left: 30px;
}
.Clipped{
  margin-left: 5px;
  margin-right: 5px;
}
/deep/.el-form-item__error{
	top: 85%;
	left: 22px;
}
</style>



