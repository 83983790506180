<template>
  <div class="server">
	<div class="top">
		<span>菜单管理</span>
	</div>
	<!--轮播图图表 -->  
	<div class="table_container">
	   <div class="table_top">
		  轮播图管理 
	   </div>
	   <el-table
	     :data="tableData"
	     size="mini"
	     fit
		   border
		   stripe
	     :default-sort="{prop:'date', order: 'descending'}"
	     :row-style="{height: '80px'}"
		 :header-cell-style="{'text-align':'left',padding: '0'}"
	     :cell-style="{padding: '0','text-align':'left'}"
	     style="width: 100%"
		 @selection-change="handleSelectionChange"
	  >
		 <el-table-column prop="id" label="序号" align="center" ></el-table-column>
		 <!-- <el-table-column prop="class_name" label="名称" align="center">
			 <template slot-scope="scope">
			 	<div>{{scope.row.class_name}}</div>
			 </template>
		 </el-table-column> -->
		 <el-table-column prop="banner_img" label="图片" align="center">
		 	<template slot-scope="scope">
		 		<img :src="SetImg(scope.row.banner_img)" />
		 	</template>		 
		 </el-table-column>
		<!-- <el-table-column prop="is_shelf" label="状态" align="center">
			 <template slot-scope="scope">
			 	<el-switch
			 	  v-model="scope.row.is_shelf==1?true:false"
				  @change="changeSwitch($event,scope.row)"
			 	  >
			 	</el-switch>
			 </template>	 
		 </el-table-column> -->
	   <el-table-column prop="operation" align="center" label="操作">
	      <template slot-scope="scope">
			<el-button
			  type="text"
			  size="small"
				style="color:#5584FF;"
			  @click="check(scope.row)"
			>查看</el-button>
	        <el-button
	          type="text"
	          size="small"
				style="color:#5584FF;"
	          @click="edit(scope.row)"
	        >编辑</el-button>
			<!-- <el-button
			    type="text"
			    size="small"
				style="color: #5584FF;"
			    @click="onDelete(scope.row,scope.$index)"
			  >删除</el-button> -->
	      </template>
	    </el-table-column>
	  </el-table>
	</div>
	<!-- 对话框-->
	<div class="marks" v-if="dialog.show">
		<BannerOperate :dialog="dialog" :form="form" @update="getBanner"  v-if="dialog.title=='轮播图编辑'||dialog.title=='轮播图查看'"></BannerOperate>
	</div>
  </div>
</template>

<script>
let id = 1000;
import { getbannerList,dobanner} from '@/api/index'
import BannerOperate from '@/components/BannerOperate.vue'
export default {
  name: 'Category',
  data() {
    return {
	  fullHeight: '', // fullHeight: document.documentElement.clientHeight  屏幕高度 默认值
      list: [],
	  tableData: [],//轮播图数据
	  // 需要给分页组件传的信息
	  paginations: {
	    page_index: 1, // 当前位于哪页
	    total: 0, // 总数
	    page_size: 10 // 1页显示多少条
	  },
	  dialog: {
	    show: false,
	    title: '',
	    option: ''
	  },
	  form: {},
    }
  },
  components: {
  	BannerOperate
  },
  created () {
	// 加载轮播图图数据
	 this.getBanner()
    // 加载数据表格
   
  },
  methods: {
	  // 表格数据选中方法
	  handleSelectionChange(val) {
	  	console.log(val)
	     this.multipleSelection = val;
	     console.log( this.multipleSelection)
	  },
	 // 加载轮播图数据
	 getBanner() {
	   // 获取数据
	   const data = {}
	   // 请求数据
	   getbannerList(data)
	     .then((res) => {
		   console.log("获取轮播图数据")
	       console.log(res)
	 	   console.log(res.data)
		   this.list=res.data
		   this.tableData=res.data//初始话右侧得表格，默认为选择全部分类
		   for(let i=0;i<this.list.length;i++){
			   this.list[i].isOpen=false
			   for(let j=0;j<this.list[i].son_class_list.length;j++){
				   this.list[i].son_class_list[j].isOpen=false
			   }
		   }
		   console.log("list")
		   console.log(this.list)
	     })
	     .catch((err) => {
	       console.log(err)
	     })
	 },
	// 分类添加
	add(type,data){
		console.log("分类添加")
		console.log(data)
		this.dialog = {
			  show: true,
			  title: '服务新增',
			  option: 'add',
			  level:type,
			}
		if(type!=3){
			this.form = {
				id:'',
				icon_img:'',
				parent_id:data?data.id:0,
				class_name:'',
			}
		}else{
			this.form={
				id:'',
				goods_name:'',
				class_id:data.id,
				goods_img:'',
				goods_img_str:'',
				sort:'',
				is_shelf:1,
				model_list:'',
			}
		}
		
	},
	// 查看操作
	check(row){
		console.log('点击查看')
		console.log(row)
		this.dialog = {
		  show: true,
		  title:'轮播图查看',
		  option: 'check',
		}
		this.form = row
		// this.form = row
		console.log("传递的from")
		console.log(this.form)
		
	},
	// 编辑操作
	edit (row,type) {
	  console.log('点击编辑')
	  console.log(row)
	  console.log(type)
	  this.dialog = {
	    show: true,
	    title:'轮播图编辑',
	    option: 'edit',
	  }
	  	this.form = row
	    console.log("传递的from")
		console.log(this.form)
	},
	//删除操作
	onDelete (row, index) {
	  // 删除提示
	  console.log(row, index)
	  this.$confirm('确认要删除吗，删除后不可恢复', {
	    confirmButtonText: '删除',
	    cancelButtonText: '取消',
	    confirmButtonClass: 'delete',
	  }).then(() => {
		  console.log(this.right_type)
		  if(this.right_type<2){ //分类删除
		    const data = {
		      act:'del',
		      id:row.id,
		      icon_img:row.icon_img,
		      parent_id:row.parent_id,
		      class_name:row.class_name,
		      level:''
		    }
		    console.log(data)
		    doClass(data).then((res) => {
		      console.log(res)
		      if (res.msg === '成功') {
		        // 操作成功
		        this.$message({
		          message: '删除成功！',
		          type: 'success'
		        })
		        this.getBanner()//刷新数据
		      }
		    })
		      .catch((err) => {
		        console.log(err)
		      })
			  
		  }else{//服务删除
			// 删除请求
			  const data = {
			    act: 'del',
			    id:row.id,
			    class_id:row.class_id,
			    goods_name:row.goods_name,
			    goods_img:row.goods_img,
			    goods_img_str:row.goods_img_str,
			    sort:row.sort,
			    is_shelf:row.is_shelf,
			    model_json:'',
			  }
			  console.log(data)
			  doService(data).then((res) => {
			    console.log(res)
			    if (res.msg === '成功') {
			      // 操作成功
			      this.$message({
			        message: '删除成功！',
			        type: 'success'
			      })
				  this.getBanner()//刷新数据
			     this.serviceList()
			    }
			  })
			    .catch((err) => {
			      console.log(err)
			    })
			}
	 
	  })
	},
	// 给请求回来的图片地址加上后台服务器地址，可以正常显示
	SetImg(value){
		// var str =getApp().globalData.https + value
		// var str ="https://lovehome.dysense.cn" + value
		var str ="https://testlove.dysense.cn" + value
		if(value != ""){
			return str
		}
	},
	// 格式化后台传来的时间戳（年月日时分秒）
	timeFormate (timeStamp) {
	      var date = new Date(timeStamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
	      const Y = date.getFullYear() + '-'
	      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
	      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	      // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
		  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) 
	      const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	      return Y + M + D + h + m + s
	},
  }
}
</script>

<style scoped>
 .server{
	width: 100%;
	height: 100%;
    color: #606266;
	top: 0;
	overflow: auto;
	background-color: #F3F3F3;
	padding-top: 24px;
	box-sizing: border-box;
  }
  .top{
	  width: 100px;
	  height: 28px;
	  border-left: 4px solid #FB5C55;
	  font-size: 20px;
	  color: #333333;
	  font-weight: bold;
	  margin: 0px 0 10px 20px;
  }	
.table_container{
	margin-top: 20px;
	padding: 0 10px;
	width: 100%;
	box-sizing: border-box;
  }
  .table_container2{
	width: 100%;
	padding: 0 10px;
	margin-top: 30px;
	box-sizing: border-box;  
  }
  .table_top{
	  width: 100%;
	  display: flex;
	  align-items: center;
	  justify-content: start;
	  font-size: 14px;
	  margin-bottom: 25px;
	  margin-left: 8px;
	  font-weight: bold;
  }
  .t_r{
	  /* width: 130px; */
	  height: 36px;
	  padding: 0 17px;
	  border: 1px solid #C4C6CF;
	  color: #333333;
	  line-height: 36px;
	  text-align: center;
	  border-radius: 3px;
	  margin-left: 36px;
  }
  /deep/.cell{
	display: flex;
	align-items: center;
	justify-content: start; 
	 /* height: 120px!important; */
  }
  /deep/.cell img{
	  width: 120px;
	  height: 60px !important;
	  margin-right: 10px;
  }
  .s_r{
	  height: 40px;
	  display: flex;
	  flex-direction: column;
	  line-height: 40px;
  }
  .name{
	  height: 20px;
	  line-height: 20px;
  }
  .price{
	  height: 20px;
	  line-height: 20px;
	  color: #E91A1B;
  }

  /*为了设置每行的高度不会随着内容的增多而变大设置的样式，需要结合表格行内样式的row-style、cell-style一起来使用*/
  /deep/ .el-table .cell{
    height: 45px;
    line-height: 45px;
    overflow: hidden;
  }
  /deep/.el-switch.is-checked .el-switch__core{
	border-color: #E91A1B;
	background-color: #E91A1B;
  }
  .marks{
  	  position: absolute;
  	  top: 0;
  	  left: 0;
  	  width: 100%;
  	  height: 100%;
  	  background: rgba(0,0,0,0.6);
  	  z-index: 999;
  }
  /deep/.el-table--mini td, .el-table--mini th{
  	  padding: 0;
  }
  /deep/.el-table td, .el-table th{
  	  padding: 0;
  }
  /deep/ .el-table::before{
  	  background-color: rgba(255,255,255,0);
  }
  /deep/.el-table .descending .sort-caret.descending{
  	 border-top-color: #E91A1B !important; 
  }
  /deep/.el-table .ascending .sort-caret.ascending{
  	 border-bottom-color: #E91A1B !important; 
  }
</style>
<style>
	.delete{
		  background-color: #E91A1B!important;
		  color: #FFFFFF;
		  border: 1px solid #E91A1B !important;
	}
	
</style>


