// import Vue from 'vue'
// import Vuex from 'vuex'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const types = {
  SET_USER_ACCOUNT: 'SET_USER_ACCOUNT' // 用户账号信息
}

const state = { // 需要维护的状态
  user_account: {}// 存储用户账号信息
}

const getters = {
  user_account: state => state.user_account
}

const mutations = {
  [types.SET_USER_ACCOUNT] (state, user_account) {
    if (user_account) { state.user_account = user_account } else { state.user_account = {} }
  }
}

const actions = {
  setUserAccount: ({ commit }, user_account) => {
    commit(types.SET_USER_ACCOUNT, user_account)
  },

}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
})