import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入百度地图
import BaiduMap from 'vue-baidu-map'
// 引入vue-area-linkage实现省市区街道四级联动
import {pcaa} from 'area-data';
import 'vue-area-linkage/dist/index.css';
import VueAreaLinkage from 'vue-area-linkage';
Vue.prototype.$pcaa = pcaa;
Vue.use(VueAreaLinkage)

// 百度地图
Vue.use(BaiduMap, {

  ak:'TTGTfg79te6ztSkuTPtkde0XsNo3iebp' //百度地图开发者平台申请的密钥

})


Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
