<template>
<div class="head_review">
  <div class="top">
	  <span>商品订单</span>
  </div>
  <div class="tabber_box">
	  <div class="search_item">
		<div>订单号</div>
		<input type="text" placeholder="请输入" v-model="order_number"/>
	  </div>
	  <div class="search_item">
	  	<div style="margin-right: 13px">订单状态</div>
	  	<el-select v-model="status" placeholder="请选择">
	  	    <el-option
	  	      v-for="item in options"
	  	      :key="item.value"
	  	      :label="item.label"
	  	      :value="item.value">
	  	    </el-option>
	  	  </el-select>
	  </div>
	  <div class="search" @click="goSearch">搜索</div>
  </div>
  <div class="table_container">
   <el-table
     :data="tableData"
      size="mini"
      fit
	  border
	  stripe
      :default-sort="{prop: 'date', order: 'descending'}"
     :row-style="{height: '45px'}"
	  :header-cell-style="{'text-align':'left',padding: '0'}"
     :cell-style="{padding: '0','text-align':'left'}"
     style="width: 100%"
    >
	 <!-- 展开详情信息 -->
	  <el-table-column type="expand">
	       <template slot-scope="props">
	         <el-form label-position="left" inline class="demo-table-expand">
	           <el-form-item label="订单号">
	              <div>{{props.row.order_number}}</div>
	           </el-form-item>
	           <el-form-item label="商品名称">
	             <span>{{ props.row.product_name }}</span>
	           </el-form-item>
	           <el-form-item label="订单状态">
	             <span v-if="props.row.status==0">未完成</span>
	             <span v-if="props.row.status==1">已完成</span>
	           </el-form-item>
			   <el-form-item label="收获地址">
			     <span>{{props.row.select_address}}{{props.row.address_info}}</span>
			   </el-form-item>
	 		  <el-form-item label="评价内容">
	 		    <span>{{ props.row.evaluate }}</span>
	 		  </el-form-item>
	         </el-form>
	       </template>
	 </el-table-column>
	 <!-- 表格的列 -->
     <el-table-column prop="order_number" label="订单号" align="center" >
		 <template slot-scope="scope">
		 	<div>{{scope.row.order_number}}</div>
		 </template>
	 </el-table-column>
	 <el-table-column prop="order_time" label="下单时间" align="center" style="height: 45px">
	 	<template slot-scope="scope">
	 		<div>{{timeFormate(parseInt(scope.row.order_time))}}</div>
	 	</template>	
	 </el-table-column>
	 <el-table-column prop="product_name" label="商品名称" align="center"></el-table-column>
	 <el-table-column prop="price" label="订单总额" align="center">
		 <template slot-scope="scope">
		 	<div>{{scope.row.spec_price*scope.row.num}}</div>
		 </template> 
	 </el-table-column>
	 <el-table-column prop="consignee_name" label="收货人" align="center"></el-table-column>
	 <el-table-column prop="select_address" label="收货地址" align="center">
		 <template slot-scope="scope">
		 	<div>{{scope.row.select_address}}{{scope.row.address_info}}</div>
		 </template>
	 </el-table-column>
	 <el-table-column prop="status" label="订单状态" align="center">
		 <template slot-scope="scope">
		 	<div v-if="scope.row.status==0">未完成</div>
		 	<div v-if="scope.row.status==1">已完成</div>
		 </template> 
	 </el-table-column>
	 <el-table-column prop="level" label="评价等级" align="center"></el-table-column>
	 <el-table-column prop="evaluate" label="评价内容" align="center">
		 <template slot-scope="scope">
		 	<div v-if="scope.row.evaluate">{{scope.row.evaluate}}</div>
		 	<div v-if="!scope.row.evaluate">暂无评价</div>
		 </template> 
	 </el-table-column>
     <!-- <el-table-column prop="operation" align="center" label="操作">
        <template slot-scope="scope">
		  <el-button
		    type="text"
		    size="small"
			style="color: #5584FF;"
		    @click="refuse(scope.row,scope.$index)"
		  >冻结账号</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <!-- 分页 -->
    <el-row class="page">
        <el-col>
          <div class="pagination">
            <el-pagination
              v-if="paginations.total > 0"
              @current-change="handleCurrentChange"
              :current-page.sync="paginations.page_index"
              :page-size="paginations.page_size"
              layout="prev, pager, next, jumper"
              :total="paginations.total">
            </el-pagination>
          </div>
        </el-col>
      </el-row>
  </div>
 
</div>
</template>

<script>
import { getProOrderList } from '@/api/index'
import AccountOperate from '@/components/AccountOperate'
export default {
  name: 'ServerHome',
  data () {
    return {
      fullHeight: '', // fullHeight: document.documentElement.clientHeight  屏幕高度 默认值
      tableData: [],
      // 需要给分页组件传的信息
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10 // 1页显示多少条
      },
	  dialog: {
	    show: false,
	    title: '',
	    option: 'edit'
	  },
	  form: {},
	  is_handle:false,//是否选中已处理
	  // nick_name:'',//搜索框内容
	  order_number:'',//搜索框内容
	  options: [{
	            value: '1',
	            label: '已完成'
	          }, {
	            value: '0',
	            label: '未完成'
	          }],
	  status:'',//输入的状态
    }
  },
  components: {
    AccountOperate
  },
  created () {
    // 加载数据表格
    this.getProfile(1,0)
    // 获取屏幕高度
    this.fullHeight = `${document.documentElement.clientHeight}`// 默认值
    const that = this
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        that.fullHeight = window.fullHeight
      })()
    }
  },
  methods: {
	getProfile (pages,type) {
		console.log("切换至")
		console.log(pages,this.pagesNumber)
	  // 获取表格数据
	  const data = {
	    order_number:this.order_number,
	    status:this.status,
	    page: pages? pages:this.pagesNumber,
	    limit: '10',
	  }
	  // 请求数据
	  getProOrderList(data)
	    .then((res) => {
	      console.log(res)
		  console.log(res.data)
		  this.paginations.total=res.count_page
		  this.tableData=res.data
		  console.log("总共有"+ this.paginations.total+"条数据")
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
    // 根据屏幕的高度实时动态修改分页条数
    changePageSize (fullHeight) {
      if (fullHeight > 801) {
        this.paginations.page_size = 15
      } else {
        this.paginations.page_size = 10
      }
    },
	// 搜索
	goSearch(){
		this.getProfile()
	},
    handleCurrentChange (page) {
      const number = String(page)
	  this.pagesNumber=number
	  console.log("切换至"+this.pagesNumber+"页")
      // 当点击切换分页数据时，发送请求给服务器，让其把相应的页码数据内容发送回来
	  const data = {
	   order_number:this.order_number,
	   status:this.status,
	   page: number,
	   limit: '10',
	  }
      console.log(data)
      // 发送请求
      // 请求数据
      getProOrderList(data)
        .then((res) => {
          console.log(res)
      	  console.log(res.data)
		  this.tableData=res.data
        })
        .catch((err) => {
          console.log(err)
        })

      // // 当前页
      // const sortnum = this.paginations.page_size * (page - 1)
      // const table = this.allTableData.filter((item, index) => {
      //   return index >= sortnum
      // })
      // // 设置默认分页数据
      // this.tableData = table.filter((item, index) => {
      //   return index < this.paginations.page_size
      // })
    },
	// 格式化后台传来的时间戳（年月日时分秒）
	timeFormate (timeStamp) {
	      var date = new Date(timeStamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
	      const Y = date.getFullYear() + '-'
	      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
	      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	      // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
		  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) 
	      const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	      return Y + M + D 
	},

  },
  // 监听屏幕变化
  // watch: {
  //   // 如果 `fullHeight ` 发生改变，这个函数就会运行
  //   fullHeight: function (val) {
  //     if (!this.timer) {
  //       this.fullHeight = val
  //       this.changePageSize(this.fullHeight)
  //       this.getProfile()
  //       console.log(val)
  //       console.log(this.paginations.page_size)
  //       this.timer = true
  //       const that = this
  //       setTimeout(function () { // 频繁触发 resize 函数，会导致页面很卡
  //         that.timer = false
  //       }, 100)
  //     }
  //   }

  // }
}
</script>
<style scoped>
  .head_review{
	width: 100%;
	height: 100%;
    color: #606266;
	/* position: relative; */
	top: 0;
	overflow: auto;
	background-color: #F7F8FA;
  }
  .top{
	  width: 130px;
	  height: 28px;
	  border-left: 4px solid #FB5C55;
	  font-size: 20px;
	  color: #333333;
	  font-weight: bold;
	  margin: 24px 0 10px 20px;
  }
  .tabber_box {
	width: 100%;
	height: 40px;
	font-size: 14px;
	color:#333333 ;
	line-height: 40px;
	margin-top: 23px;
	margin-bottom: 24px;
	display: flex;
	align-items: center;
	justify-content: start;
	margin-left: 20px;
	position: relative;
  }
  .search_item{
	display: flex;
	align-items: center;
	justify-content: start;  
	margin-right: 37px;
  }
  input{
	  width: 180px;
	  height: 32px;
	  margin-left: 13px;
	  border: 1px solid #C4C6CF;
	  padding-left: 12px;
	  border-radius: 3px;
  }
  /deep/.el-select .el-input__inner{
	width: 250px;
	height: 34px;
	/* margin-left: 13px; */
	 border: 1px solid #C4C6CF;
	 padding-left: 12px;
	 border-radius: 3px;
  }
  .tabber_box .search{
	  width: 66px;
	  height: 36px;
	  border-radius: 3px;
	  background-color: #E91A1B;
	  color: #FFFFFF;
	  line-height: 36px;
	  text-align: center;
	  margin-left: 37px;
	  
  }
  .add{
	position: absolute;
	right: 30px;
	width: 66px;
	height: 36px;
	border-radius: 3px;
	background-color: #E91A1B;
	color: #FFFFFF;
	line-height: 36px;
	text-align: center;  
  }
  .head_review .table_container{
    /* padding-left:10px; */
	padding: 0 10px;
  }
  .head_review .page{
    width: 50%;
    margin: 20px auto;
    text-align: center;
  }
  /*为了设置每行的高度不会随着内容的增多而变大设置的样式，需要结合表格行内样式的row-style、cell-style一起来使用*/
  /deep/ .el-table .cell{
    height: 45px;
    line-height: 45px;
    overflow: hidden;
  }
 
  /deep/.el-table--mini td, .el-table--mini th{
	  padding: 0;
  }
  /deep/.el-table td, .el-table th{
	  padding: 0;
  }
  /deep/ .el-table::before{
	  background-color: rgba(255,255,255,0);
  }
  /* 展开表格详情列表 */
  .demo-table-expand {
      font-size: 0;
  	  padding: 20px;
  	  box-sizing: border-box;
    }
    .demo-table-expand label {
      width: 90px;
      color: #99A9BF!important;
    }
  	/deep/.el-form--inline .el-form-item__label{
  		width: 90px;
  		color: #99A9BF!important;
  		font-weight: bold;
  	}
    .demo-table-expand .el-form-item {
      margin-right: 0;
      margin-bottom: 0;
      width: 100%;
  	  color: #666666;
  	  word-wrap: break-word;
  	  word-break: break-all;
  	 /* display: flex;
  	  justify-content: start; */
    }
  	/deep/.el-form--inline .el-form-item__content{
  		width: calc(100% - 90px);
  	}
  	/deep/.el-table__expand-icon{
  		height: 45px;
  	}
</style>




