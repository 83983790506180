<template>
  <div class="login">
	<div class="left">
		<img src="../assets/ai.jpg" />
		<!-- <div class="left_title">爱家智慧生活</div> -->
	</div>
	<div class="right">
		<div class="form_container">
		  <div class="manage_tip">
		    <span class="title">爱家智惠生活后台管理系统</span>
		  </div>
		  <el-form :model="loginUser" status-icon :rules="rules" ref="loginForm" label-width="0px" class="loginForm">
		    <el-form-item label="" prop="uname">
		      <el-input type="text" v-model="loginUser.uname" placeholder="请输入用户名"></el-input>
		    </el-form-item>
		    <el-form-item label="" prop="password">
		      <el-input type="password" v-model="loginUser.password" placeholder="请输入密码"></el-input>
		    </el-form-item>
			<el-form-item label="" prop="type">
			  <el-select v-model="loginUser.type" placeholder="请选择登录身份">
			      <el-option
			        v-for="item in options"
			        :key="item.value"
			        :label="item.label"
			        :value="item.value">
			      </el-option>
			    </el-select>
			</el-form-item>
			<el-form-item>
			   <el-checkbox v-model="checked" class="checkbox">记住密码</el-checkbox>
			</el-form-item>
		    <el-form-item>
			  <div class="submit_btn" @click="submitForm('loginForm')">登 录</div>
		    </el-form-item>
		   <!-- <div class="tiparea">
		      <p>还没有账号？现在<router-link to="/register">注册</router-link></p>
		    </div> -->
		  </el-form>
		</div>
	</div>
    
  </div>
</template>

<script>
// eslint-disable-next-line camelcase
// import jwt_decode from 'jwt-decode'
import { getLogin } from '../api/index'
export default {
  name: 'Login',
  data () {
    return {
      loginUser: {
        uname: '',
        password: '',
		type:''	//1 主账户 2 团长
      },
      rules: {
        uname: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { min:1, max: 30, message: '长度在 6 到 30 个字符', trigger: 'blur' }
        ],
		type: [
          { required: true, message: '身份不能为空', trigger: 'blur' },
        ],
      },
	  checked: false,
	  options: [{
	            value: '1',
	            label: '管理员'
	          }, {
	            value: '2',
	            label: '团长'
	          }],
	  // identity:'',//输入的身份
    }
  },
  //页面加载调用获取cookie值
  mounted(){
      this.getCookie()
  },
  methods: {
   submitForm (formName) {
     console.log(this.loginUser)
     this.$refs[formName].validate(valid => {
       if (valid) {
         getLogin(this.loginUser).then((res) => {
           // 登录成功
           console.log(res)
           // 页面跳转
           if (res.msg === '成功') {
			   //判断复选框是否被勾选 勾选则调用配置cookie方法
			      if(this.checked=true){
			        //传入账号名，密码，和保存天数3个参数
			       this.setCookie(this.loginUser.uname,this.loginUser.password,365);
			      }
			   
                // 存储数据到store
               this.$store.dispatch('setUserAccount', res.data)
               console.log(this.$store.state.user_account) //获取到store中的账户信息
			   console.log(this.$store.getters.user_account)//获取到store中的账户信息
			   this.$router.push('/index')
  
           } else {
             // 登录失败提示
             this.$message({
               showClose: true,
               message: '用户名或者密码、身份不匹配，请重新输入',
               type: 'error'
             })
           }
         })
           .catch(function (err) {
             // eslint-disable-next-line no-console
             console.log(err)
           })
       } else {
         console.log('error submit!!')
         return false
       }
     })
   },
   //设置cookie
    setCookie(c_name,c_pwd,exdays) {
     var exdate=new Date();//获取时间
     exdate.setTime(exdate.getTime() + 24*60*60*1000*exdays);//保存的天数
     //字符串拼接cookie
     window.document.cookie="aiUserName"+ "=" +c_name+";path=/;expires="+exdate.toGMTString();
     window.document.cookie="aiUserPwd"+"="+c_pwd+";path=/;expires="+exdate.toGMTString();
	 console.log("cookie保存成功")
    },
    //读取cookie
    getCookie:function () {
     if (document.cookie.length>0) {
	  this.checked=true //证明内存中有保存密码
      var arr=document.cookie.split('; ');//这里显示的格式需要切割一下自己可输出看下
      for(var i=0;i<arr.length;i++){
       var arr2=arr[i].split('=');//再次切割
       //判断查找相对应的值
       if(arr2[0]=='aiUserName'){
        this.loginUser.uname=arr2[1];//保存到保存数据的地方
       }else if(arr2[0]=='aiUserPwd'){
        this.loginUser.password=arr2[1];
       }
      }
     }
    },
    //清除cookie
    clearCookie:function () {
     this.setCookie("","",-1);//修改2值都为空，天数为负1天就好了
    }
    
  }
}
</script>

<style scoped>
  .login {
   /* position: relative; */
    width: 100%;
    height: 100%;
	position: relative;
  }
  .left{
	  width: 35%;
	  height: 100%;
	  /* background: #AE2621; */
	  background-color: #B2221F;
	  position: relative;
	  float: left;
  }
  .left_title{
	  width: 480px;
	  height: 120px;
	  font-size: 120px;
	  font-family: Helvetica;
	  color: #FFFFFF;
	  line-height: 144px;
	  /* font-weight: bold; */
	  position: absolute;
	  left: 50%;
	  top: 50%;
	  transform: translate(-50%,-50%);
  }
 .left img{
	  width:400px ;
	  height: 358px;
	  position: absolute;
	  left: 50%;
	  top: 50%;
	  transform: translate(-50%,-50%);
  }
  .right{
	  width: 65%;
	  height: 100%;
	  position: relative;
	  float: right;
  }
  .form_container {
    /* width: 370px; */
   /* height: 210px; */
    height: 250px;
    position: absolute;
    top: 40%;
	left: 40%;
	transform: translate(-50%,-50%);
    padding: 25px;
    border-radius: 5px;
    text-align: center;
  }
  .form_container .manage_tip .title {
    font-family: "Microsoft YaHei";
	/* font-family: "苹方"; */
    font-weight: bold;
    font-size:36px;
    color:#E91A1B;
	width: 358px;
	text-align: left;
	/* margin-left: 20px; */
	margin-bottom: 61px;
  }
  .loginForm {
	width: 358px;
    margin-top: 20px;
    background-color: #fff;
    padding: 20px 40px 20px 20px;
    border-radius: 5px;
  /*  box-shadow: 0px 5px 10px #cccc; */
  }
  .checkbox{
	  width: 100%;
	  text-align: left;
	  color: #333333;
	  font-size: 12px;
  }

  .submit_btn {
    width: 240px;
	height: 40px;
	color: #FFFFFF;
	font-size: 12px;
	margin-top: 30px;
	background-color: #E91A1B;
  }
  .tiparea {
    text-align: right;
    font-size: 12px;
    color: #333;
  }
  .tiparea p a {
    color: #409eff;
  }
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner{
	  background-color: #E91A1B;
	  color: #E91A1B;
	  border-color: #E91A1B;
  }
  /deep/ .el-checkbox__inner:hover{
	   border-color: #E91A1B;
  }
  
  /deep/ .el-checkbox__input.is-checked+.el-checkbox__label{
	  color: #E91A1B;
  }
  /deep/.el-select{
	  width: 100%;
  }
</style>
