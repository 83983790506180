<template>
	<div class="ti_xian">
	  <div class="top">
		  <span>服务订单管理</span>
	  </div>
	  <div class="tabber_box">
	  	  <div class="search_item">
	  	  	<div style="margin-right: 13px">订单状态</div>
	  	  	<el-select v-model="level_value" placeholder="请选择">
	  	  	    <el-option
	  	  	      v-for="item in options"
	  	  	      :key="item.value"
	  	  	      :label="item.label"
	  	  	      :value="item.value">
	  	  	    </el-option>
	  	  	  </el-select>
	  	  </div>
	  </div>
	  <div class="table_container">
	   <el-table
	     :data="tableData"
	      size="mini"
	      fit
	  	  border
	  	  stripe
	      :default-sort="{prop: 'date', order: 'descending'}"
	     :row-style="{height: '45px'}"
	  	  :header-cell-style="{'text-align':'left'}"
	     :cell-style="{padding: '0','text-align':'left'}"
	     style="width: 100%"
	    >
	  	<!-- 展开详情信息 -->
	  	 <el-table-column type="expand">
	  	      <template slot-scope="props">
	  	        <el-form label-position="left" inline class="demo-table-expand">
	  	          <el-form-item label="订单号">
	  	            <div v-if="props.row.customName">{{props.row.customName}}</div>
	  	            <div v-if="!props.row.customName">{{props.row.nickName}}</div>
	  	          </el-form-item>
	  	          <el-form-item label="商品名称">
	  	            <span>{{ props.row.organizers }}</span>
	  	          </el-form-item>
				  <el-form-item label="收货人">
				    <span>{{ props.row.organizers }}</span>
				  </el-form-item>
				  <el-form-item label="收获地址">
				    <span>{{ props.row.organizers }}</span>
				  </el-form-item>
	  	          <el-form-item label="订单状态">
	  	            <span>{{ props.row.organizers }}</span>
	  	          </el-form-item>
				  <el-form-item label="评价等级">
				    <span>{{ props.row.organizers }}</span>
				  </el-form-item>
	  			  <el-form-item label="评价内容">
	  			    <span>{{ props.row.organizers }}</span>
	  			  </el-form-item>
	  	        </el-form>
	  	      </template>
	    </el-table-column>
	  	<!-- 表格的列 -->
	     <el-table-column prop="phonetic_symbols" label="订单号" align="center" >
	  		 <template slot-scope="scope">
	  		 	<div v-if="scope.row.customName">{{scope.row.customName}}</div>
	  		 	<div v-if="!scope.row.customName">{{scope.row.nickName}}</div>
	  		 </template>
	  	 </el-table-column>
	  	 <el-table-column prop="create_time" label="下单时间" align="center" style="height: 45px">
	  	 	<template slot-scope="scope">
	  	 		<div>{{timeFormate(parseInt(scope.row.create_time))}}</div>
	  	 	</template>	
	  	 </el-table-column>
	  	 <el-table-column prop="organizers" label="商品名称" align="center"></el-table-column>
	  	 <el-table-column prop="organizers" label="订单总额" align="center"></el-table-column>
	  	 <el-table-column prop="organizers" label="收货人" align="center"></el-table-column>
	  	 <el-table-column prop="organizers" label="收货地址" align="center"></el-table-column>
	  	 <el-table-column prop="organizers" label="订单状态" align="center"></el-table-column>
	  	 <el-table-column prop="organizers" label="评价等级" align="center"></el-table-column>
	  	 <el-table-column prop="organizers" label="评价内容" align="center"></el-table-column>
	    </el-table>
	    <!-- 分页 -->
	    <el-row class="page">
	        <el-col>
	          <div class="pagination">
	            <el-pagination
	              v-if="paginations.total > 0"
	              @current-change="handleCurrentChange"
	              :current-page.sync="paginations.page_index"
	              :page-size="paginations.page_size"
	              layout="prev, pager, next, jumper"
	              :total="paginations.total">
	            </el-pagination>
	          </div>
	        </el-col>
	      </el-row>
	  </div>
	</div>
</template>

<script>
	import { getMatchList,doWord } from '@/api/index'
	export default {
	  name: 'TiXian',
	  data () {
	    return {
			fullHeight: '', // fullHeight: document.documentElement.clientHeight  屏幕高度 默认值
			tableData: [],
			// 需要给分页组件传的信息
			paginations: {
			  page_index: 1, // 当前位于哪页
			  total: 0, // 总数
			  page_size: 10 // 1页显示多少条
			},
			options: [{
			          value: 'level_1',
			          label: '已完成'
			        }, {
			          value: 'level_2',
			          label: '未完成'
			        }],
			level_value:'',//输入的等级值
		}
	  },
	  created () {
	    // 加载数据表格
	    this.getProfile(1,0)
	    // 获取屏幕高度
	    this.fullHeight = `${document.documentElement.clientHeight}`// 默认值
	    const that = this
	    window.onresize = () => {
	      return (() => {
	        window.fullHeight = document.documentElement.clientHeight
	        that.fullHeight = window.fullHeight
	      })()
	    }
	  },
	  methods:{
		getProfile (pages,type) {
			console.log("切换至")
			console.log(pages,this.pagesNumber)
		  // 获取表格数据
		  const data = {
		    // page: '1',
			page: pages? pages:this.pagesNumber,
		    limit: '10',
			type:this.is_handle?1:0//0 未审核 1已审核
		  }
		  // 请求数据
		  getMatchList(data)
		    .then((res) => {
		      console.log(res)
			  console.log(res.data)
			  this.paginations.total=res.count_page
			  this.tableData=res.data
			  console.log("总共有"+ this.paginations.total+"条数据")
		    })
		    .catch((err) => {
		      console.log(err)
		    })
		},
		// 根据屏幕的高度实时动态修改分页条数
		changePageSize (fullHeight) {
		  if (fullHeight > 801) {
		    this.paginations.page_size = 15
		  } else {
		    this.paginations.page_size = 10
		  }
		},
		handleCurrentChange (page) {
		    const number = String(page)
		    this.pagesNumber=number
		    console.log("切换至"+this.pagesNumber+"页")
		    // 当点击切换分页数据时，发送请求给服务器，让其把相应的页码数据内容发送回来
		    const data = {
		      page: number,
		      limit: '10',
		  	type:this.is_handle?1:0
		    }
		    console.log(data)
		    // 发送请求
		    // 请求数据
		    getMatchList(data)
		      .then((res) => {
		        console.log(res)
		    	  console.log(res.data)
		  	  this.tableData=res.data
		      })
		      .catch((err) => {
		        console.log(err)
		      })
	  },
	  // 格式化后台传来的时间戳（年月日时分秒）
	  timeFormate (timeStamp) {
	        var date = new Date(timeStamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
	        const Y = date.getFullYear() + '-'
	        const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	        const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
	        const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	        // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
	  	  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) 
	        const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	        return Y + M + D 
	  },
	}
}
</script>

<style scoped="scoped">
.ti_xian{
	width: 100%;
	height: 100%;
	color: #606266;
	/* position: relative; */
	top: 0;
	overflow: hidden;
	background-color: #F7F8FA;
}
.top{
	  width: 130px;
	  height: 28px;
	  border-left: 4px solid #FB5C55;
	  font-size: 20px;
	  color: #333333;
	  font-weight: bold;
	  margin: 24px 0 10px 20px;
  }
.tabber_box {
	width: 100%;
	height: 40px;
	font-size: 14px;
	color:#333333 ;
	line-height: 40px;
	margin-top: 23px;
	margin-bottom: 24px;
	display: flex;
	align-items: center;
	justify-content: start;
	margin-left: 20px;
	position: relative;
  }
  .search_item{
	display: flex;
	align-items: center;
	justify-content: start;  
	margin-right: 37px;
  }
  /deep/.el-select .el-input__inner{
	width: 250px;
	height: 34px;
	/* margin-left: 13px; */
	 border: 1px solid #C4C6CF;
	 padding-left: 12px;
	 border-radius: 3px;
  }
  .table_container{
    /* padding-left:10px; */
  	padding: 0 10px;
  }
  .page{
    width: 50%;
    margin: 20px auto;
    text-align: center;
  }
  /*为了设置每行的高度不会随着内容的增多而变大设置的样式，需要结合表格行内样式的row-style、cell-style一起来使用*/
  /deep/ .el-table .cell{
    height: 45px !important;
    line-height: 45px;
    overflow: hidden;
  }
   
  /deep/.el-table--mini td, .el-table--mini th{
  	  padding: 0;
  }
  /deep/.el-table td, .el-table th{
  	  padding: 0;
  }
  /deep/ .el-table::before{
  	  background-color: rgba(255,255,255,0);
  }
  /deep/.el-table thead{
	  height: 45px;
	  padding: 12px !important;
  }
  /* 展开表格详情列表 */
  .demo-table-expand {
      font-size: 0;
  	  padding: 20px;
  	  box-sizing: border-box;
    }
    .demo-table-expand label {
      width: 90px;
      color: #99A9BF!important;
    }
  	/deep/.el-form--inline .el-form-item__label{
  		width: 90px;
  		color: #99A9BF!important;
  		font-weight: bold;
  	}
    .demo-table-expand .el-form-item {
      margin-right: 0;
      margin-bottom: 0;
      width: 100%;
  	  color: #666666;
  	  word-wrap: break-word;
  	  word-break: break-all;
  	 /* display: flex;
  	  justify-content: start; */
    }
  	/deep/.el-form--inline .el-form-item__content{
  		width: calc(100% - 90px);
  	}
  	/deep/.el-table__expand-icon{
  		height: 45px;
  	}

</style>

