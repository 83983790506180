<template>
<div class="service_op">
  <div class="manage_tip">
    <p class="title">{{ dialog.title }}</p>
	<el-button  icon="el-icon-close" @click="dialog.show = false" class="close"></el-button>
  </div>
  <el-main class='main'>
	  <el-form
	    ref="form"
	    :model="form"
		:rules="rules"
	    label-width="70px"
	    style="width:100%"
	  >
	   <!-- <el-form-item prop="id" label="ID">
		  <el-input type="text" v-model="form.id" placeholder="请输入" v-if="dialog.option=='check'" :disabled="true"></el-input>
	      <el-input type="text" v-model="form.id" placeholder="请输入" v-else></el-input>
	    </el-form-item> -->
	  	
	  	<el-form-item prop="goods_name" label="服务名称">
		   <el-input type="text" v-model="form.goods_name" placeholder="请输入" v-if="dialog.option=='check'" :disabled="true"></el-input>
	  	  <el-input type="text" v-model="form.goods_name" placeholder="请输入名称" v-else></el-input>
	  	</el-form-item>
	  	
	  	<!-- <el-form-item prop="title" label="创建时间" v-if="dialog.option=='check'">
	  	   <el-input type="text" v-model="form.create_time" placeholder="请输入" :disabled="true"></el-input>
	  	</el-form-item> -->
	  	
	  	<el-form-item prop="goods_img" label="小图" v-if="dialog.level=='2'||dialog.level=='3'">
	  	  <div class="img_m" v-if="form.goods_img">
	  	    <img :src="SetImg(form.goods_img)"></img>
	  	  	<i @click="deleteVoice('mini')" v-if="dialog.option!='check'"></i>
	  	  </div>
	  	  <el-upload
	  	    class="upload-demo"
	  	    :action="updateUrl"
	  	    :on-progress="handleProgress"
	  	    :on-success="handleSuccessMini"
	  	    :on-remove="handleRemove"
	  	    :before-remove="beforeRemove"
	  	    style="border: 1px solid #DCDFE6;width: 150px;height: 150px;line-height: 30px;margin-left:5% ;padding-top: 60px;box-sizing: border-box;color: #666666!important;border-radius: 4px;margin-bottom: 10px;"
	  	  	v-if="!form.goods_img"
	  	  >
	  	    <!-- <svg-icon data_iconName="Clipped" class="Clipped"/> -->
	  	  	<i class="el-icon-upload"></i>
	  	    <span class="el-upload__text">点击此处上传图片</span>
	  	    <!--        <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>-->
	  	  </el-upload>
	  	</el-form-item>
		
		<el-form-item prop="goods_img_str" label="大图" v-if="dialog.level=='2'||dialog.level=='3'">
		  <div class="img" v-if="form.goods_img_str">
		    <img :src="SetImg(form.goods_img_str)"></img>
		  		<i @click="deleteVoice('big')" v-if="dialog.option!='check'"></i>
		  </div>
		  <el-upload
		    class="upload-demo1"
		    :action="updateUrl"
		    :on-progress="handleProgress"
		    :on-success="handleSuccessBig"
		    :on-remove="handleRemove"
		    :before-remove="beforeRemove"
		    style="border: 1px solid #DCDFE6;width: 285px;height: 194px;line-height: 30px;margin-left:5% ;padding-top: 80px;box-sizing: border-box;color: #666666!important;border-radius: 4px;margin-bottom: 10px;"
		  	v-if="!form.goods_img_str"
		  >
		    <!-- <svg-icon data_iconName="Clipped" class="Clipped"/> -->
		  		 <i class="el-icon-upload"></i>
		    <span class="el-upload__text">点击此处上传图片</span>
		    <!--        <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>-->
		  </el-upload>
		</el-form-item>
		
		<el-form-item prop="sort" label="排序号">
		   <el-input type="text" v-model="form.sort" placeholder="请输入" v-if="dialog.option=='check'" :disabled="true"></el-input>
		  <el-input type="text" v-model="form.sort" placeholder="请输入" v-else></el-input>
		</el-form-item>
	  
		<el-form-item prop="organizers" label="型号管理" v-if="(dialog.option=='add'&&dialog.level=='3')||(dialog.option=='edit'&&dialog.level=='3')||(dialog.option=='check'&&dialog.level=='3')" >
		  <div class="tableDate" style="margin-left: 25px;margin-bottom: 10px;">
		      <div class="button" style="width:36px;float:right;">
		        <P><el-button class="el-icon-plus" @click.prevent="addRow()"></el-button></P>
		        <p><el-button class="el-icon-minus" @click.prevent="delData()"></el-button></p>
		      </div>
		      <div class="table">
		        <el-table
		          :data="tableData"
		          ref="table"
		          tooltip-effect="dark"
		          border
		          stripe
				  :row-style="{height: '45px'}"
				  :header-cell-style="{'text-align':'left',padding: '0'}"
				  :cell-style="{'text-align':'left',padding: '0'}"
		          style="width: 90%"
		          @selection-change='selectRow'>
		          <el-table-column type="selection" width="45" align="center"></el-table-column>
		          <el-table-column label="序号"  type="index" width="60" align="center"></el-table-column>
		          <el-table-column  label="型号名称" align="center">
		            <template slot-scope="scope">
		                <el-input v-model="scope.row.model_name"></el-input>
		            </template>
		          </el-table-column>
		  		  <el-table-column label="价格" align="center"  width="80">
		  		    <template slot-scope="scope">
		  		      <el-input v-model="scope.row.price"></el-input>
		  		    </template>
		  		  </el-table-column>
				  <el-table-column label="单位" align="center"  width="80">
				    <template slot-scope="scope">
				      <el-input v-model="scope.row.unit"></el-input>
				    </template>
				  </el-table-column>
			  </el-table>
			 </div>
		  </div>
		</el-form-item>
		
		<!-- <el-form-item prop="organizers" label="驳回理由" v-if="dialog.option=='pass'||dialog.option=='refuse'">
		  <el-input type="text" v-model="form.reason" placeholder="请输入"></el-input>
		</el-form-item> -->
	  
	  	<div class="text_right" v-if="dialog.option!='check'">
	      <el-button type="primary" @click="onSubmit('form')" class="primary">
			  <span v-if="dialog.option=='add'||dialog.option=='goods_add'">发 布</span>
			  <span v-if="dialog.option=='edit'">确 定</span>
			  <!-- <span v-if="dialog.option=='check'">通 过</span>
			  <span v-if="dialog.option=='pass'||dialog.option=='refuse'">通 过</span> -->
		  </el-button>
		 <!-- <el-button type="primary" class="no" v-if="dialog.option=='pass'||dialog.option=='refuse'">
		  	  <span>驳 回</span>
		  </el-button> -->
		  
		  
	    </div>
	  </el-form>
  </el-main>
  
</div>
</template>

<script>
import {doService} from '@/api/index'
export default {
  name: 'WordOperate',
  props: {
    dialog: Object,
    form: Object
  },
  data () {
    return {
	  password:'',//删除时输入的密码
      loginUser: {
		 person_name:'',
		 person_pass:''
	  },
      // updateUrl: "https://lovehome.dysense.cn/admin/User/uploads_img",
	  updateUrl: "https://testlove.dysense.cn/admin/User/uploads_img",
      batterty_img: '',
	  // tableData:[],
	  rules: {
	  		  goods_name: [
	  		    { required: true, message: '请输入名称', trigger: 'blur' },
	  		  ],
	          goods_img: [
	              { required: true, message: '请选择图片', trigger: 'blur' },
	            ],
			  goods_img_str: [
			      { required: true, message: '请选择图片', trigger: 'blur' },
			    ],
			  
	  },
	  selectlistRow: [],
    }
  },
  computed:{
	tableData:function(){
		let arr=[]
		if(this.form.model_list.length>0){
			arr=this.form.model_list
		}
		return arr 
	}  
  },
  mounted () {},
  methods: {
	// 服务商品增删改（三级）
	serviceApi(){
		const data = {
		  act:this.dialog.option=='add'?'add':'edit',
		  id:this.form.id,
		  class_id:this.form.class_id,
		  goods_name:this.form.goods_name,
		  goods_img:this.form.goods_img,
		  goods_img_str:this.form.goods_img_str,
		  sort:this.form.sort,
		  is_shelf:this.form.is_shelf,
		  model_json:JSON.stringify(this.tableData),
		}
		console.log(data)
		doService(data).then((res) => {
		  console.log(res)
		  if (res.msg === '成功') {
		    // 操作成功
		    this.$message({
		      message: '操作成功！',
		      type: 'success'
		    })
			this.dialog.show=false
		    this.$emit('update');//刷新数据
		  }
		})
		  .catch((err) => {
		    console.log(err)
		  })
	},
    // 图片删除方法
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    // 上传图片钩子方法
    handleProgress (event, file, fileList) {
      console.log(file)
      // setTimeout((file) => {
      //   console.log(file.response)
      // }, 5000)
    },
	// 小图上传图片成功
	handleSuccessMini(res, file, fileList) {
      this.form.goods_img = res.data
	  console.log("小图上传成功！")
      console.log(this.form.goods_img)
    },
    // 大图上传图片成功
    handleSuccessBig(res, file, fileList) {
	 // this.$set(this.form, 'pronunciation_file',res.data)
      this.form.goods_img_str = res.data
	  console.log("大图成功！")
      console.log(this.form.goods_img_str)
    },
    // 上传图片删除
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
	// 获取表格选中时的数据
	selectRow (val) {
	   console.log(val)
	   this.selectlistRow = val
	},
	// 型号管理增加行
	addRow () {
	  var list = {
		id:'',
	    price: this.price,
	    model_name: this.name,
		unit:this.unit,
		is_del:0,
		}
	  // this.tableData.unshift(list) //插入至第一个
	  this.tableData.push(list) //插入至最后一个
	},      
	//型号管理行删除方法
	  // 删除选中行
	  delData () {
	    for (let i = 0; i < this.selectlistRow.length; i++) {
	      let val = this.selectlistRow
	      // 获取选中行的索引的方法
	      // 遍历表格中tableData数据和选中的val数据，比较它们的rowNum,相等则输出选中行的索引
	      // rowNum的作用主要是为了让每一行有一个唯一的数据，方便比较，可以根据个人的开发需求从后台传入特定的数据
	      val.forEach((val, index) => {
	        this.tableData.forEach((v, i) => {
	          // if (val.rowNum === v.rowNum) {
			if (val.model_name === v.model_name) {
	            // i 为选中的索引
	            this.tableData.splice(i, 1)
	          }
	        })
	      })
	    }
	    // 删除完数据之后清除勾选框
	    // this.$refs.tableData.clearSelection()
	},    
    // 提交按钮
    onSubmit (form) {
		console.log(111)
		console.log(this.form)
		console.log(this.tableData)
		let str=JSON.stringify(this.tableData)
		console.log(str)
      this.$refs[form].validate(valid => {
        if (valid) {
          // 表单数据验证完成之后，提交数据;
		  this.serviceApi()
          
        }
      })
    },
    // 获取图片上传的地址
    // getUrl (data) {
    //   this.updateUrl = uploads_file()
    //   console.log(this.updateUrl)
    // },
	// 给请求回来的图片地址加上后台服务器地址，可以正常显示
	SetImg(value){
		// var str =getApp().globalData.https + value
		// var str ="https://lovehome.dysense.cn" + value
		var str ="https://testlove.dysense.cn" + value
		if(value != ""){
			return str
		}
	},
    // 删除编辑界面中原有图片的方法
    deleteVoice (type) {
      console.log('delete')
	  if(type=='mini'){
		 this.form.goods_img = ''  
	  }else{
		 this.form.goods_img_str = ''  
	  }
	   this.$forceUpdate()
    },
	// 格式化后台传来的时间戳（年月日时分秒）
	timeFormate (timeStamp) {
	      var date = new Date(timeStamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
	      const Y = date.getFullYear() + '-'
	      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
	      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	      // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
		  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) 
	      const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	      return Y + M + D + h + m + s
	},
  }
}
</script>

<style scoped>
.service_op{
  /* width: 418px; */
 /* width: 520px; */
  width: 600px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background-color: #FFFFFF;
  opacity: 1;
  /*height: 100%;*/
}
/* .manage_tip{
	position: relative;
} */
.manage_tip .title {
  /*height: 20px;*/
  font-family: "Microsoft YaHei";
  margin: 25px auto;
  width: 100px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #303133;
}
.close{
	position: absolute;
	right: 0;
	top: 0;
	border: none;
}
.pic{
  display: flex;
}
.main{
	height: 500px;
	padding: 0 20px;
}
.img_m{
	width: 150px;
	height: 154px;
  margin-left: 5%;
  position: relative;
}
.img_m img{
  width: 150px;
  height: 154px;
}
.img{
	width: 280px!important;
	height: 194px!important;
    margin-left: 5%;
     position: relative;
}
.img img{
  width: 280px;
  height: 194px;
}
.img i,.img_m i{
  width: 17px;
  height: 17px;
  display: inline-block;
  background: url("../assets/del.png") center no-repeat;
  background-size: 100%;
  position: absolute;
  right: -10px;
  top: -5px;
}
/deep/ .el-input__inner{
  height:40px;
  width: 90%;
  margin-bottom: 10px;
}
/deep/ .el-form{
  margin: 0;
}
/deep/ .el-form-item{
  width: 100%;
  /* display: flex !important; */
  /* justify-content: start; */
  margin-bottom: 4%;
}
/deep/ .el-form-item__content{
 /*  width: 85%;
  display: flex;
  position: relative;
  margin-left: 0!important; */
  /* margin-left: 80px !important; */
}
/deep/ .el-form-item__label{
  text-align: right;
  /* margin-left: 25px !important; */
  padding: 0;
  /*margin-right: 5%;*/
}
/deep/ .el-select{
	/* margin-left: 3%; */
	width: 100%;
	text-align: center;
	position: relative;
}
/deep/ .el-select{
	width:100%;
	margin: 0 auto;
	left: 0;
	text-align: center;
}
/deep/ .el-tag.el-tag--info{
	margin-left: 35px;
	margin-bottom: 10px;
}
/deep/ .el-input__suffix{
	margin-right: 30px;
	height: 40px;
}
/deep/ .el-upload--text{
	color: #C4C6CF !important;
}
/deep/.el-select .el-input__inner{
	width: 370px!important;
	height: 40px !important;
	border: 1px solid #DCDFE6 !important;
} 
.text_right{
	/* background-color: #0486FE; */
	width:200px;
	/* text-align: center; */
	display: flex;
	justify-content: center;
	margin: 0 auto;
	margin-bottom: 20px;
	margin-top: 10px;
	
}
.text_right1{
	/* background-color: #0486FE; */
	width:200px;
	text-align: center;
	margin: 0 auto;
	margin-bottom: 20px;
	margin-top: 10px;
	
}
.primary{
	background-color: #E91A1B !important;
	border: none;
	color: #FFFFFF;
}
.no{
	display: inline-block;
	background-color: none !important;
	color: #333333 !important;
	width: 66px;
	height: 40px;
	background: #FFFFFF;
	border-radius: 3px;
	border: 1px solid #C4C6CF;
	margin-left: 30px;
}
.Clipped{
  margin-left: 5px;
  margin-right: 5px;
}
/deep/.el-form-item__error{
	top: 95%;
	left: 25px;
}
/deep/.el-input__inner{
	padding-left: 10px;
	margin-bottom: 0;
}
</style>


