<template>
<div class="goods_op">
  <div class="manage_tip">
    <p class="title">{{ dialog.title }}</p>
	<el-button  icon="el-icon-close" @click="dialog.show = false" class="close"></el-button>
  </div>
  <el-main class='main'>
	  <el-form
	    ref="form"
	    :model="form"
		:rules="rules"
	    label-width="80px"
	    style="width:100%"
	  >
	    <el-form-item prop="id" label="id" v-if="dialog.option!='add'">
		  <el-input type="text" v-model="form.id" placeholder="请输入" :disabled="true"></el-input>
	      <!-- <el-input type="text" v-model="form.nickName" placeholder="请输入" v-else></el-input> -->
	    </el-form-item>
	  	
	  	<el-form-item prop="product_name" label="商品">
		   <el-input type="text" v-model="form.product_name" placeholder="请输入" v-if="dialog.option=='check'" :disabled="true"></el-input>
	  	  <el-input type="text" v-model="form.product_name" placeholder="请输入商品名称" v-else></el-input>
	  	</el-form-item>
	  	
	  	<!-- <el-form-item prop="title" label="">
		  <el-input type="text" v-model="form.u_id" placeholder="请输入" v-if="dialog.option=='check'" :disabled="true"></el-input>
	  	  <el-input type="text" v-model="form.u_id" placeholder="请输入商品价格" v-else></el-input>
	  	</el-form-item> -->
	  	
	  	<el-form-item prop="product_img" label="">
	  	  <div class="img" v-if="form.product_img">
	  	    <img :src="SetImg(form.product_img)"></img>
	  	  		<i @click="deleteVoice()" v-if="dialog.option!='check'"></i>
	  	  </div>
	  	  <el-upload
	  	    class="upload-demo"
	  	    :action="updateUrl"
	  	    :on-progress="handleProgress"
	  	    :on-success="handleSuccessBattery"
	  	    :on-remove="handleRemove"
	  	    :before-remove="beforeRemove"
	  	    style="border: 1px solid #DCDFE6;width: 285px;height: 194px;line-height: 30px;margin-left:5% ;padding-top: 80px;box-sizing: border-box;color: #666666!important;border-radius: 4px;margin-bottom: 10px;"
	  	  		v-if="!form.product_img"
	  	  >
	  	    <!-- <svg-icon data_iconName="Clipped" class="Clipped"/> -->
	  	  		 <i class="el-icon-upload"></i>
	  	    <span class="el-upload__text">点击此处上传图片</span>
	  	    <!--        <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>-->
	  	  </el-upload>
	  	</el-form-item>
	  
	    <el-form-item prop="code" label="商品编码">
		  <el-input type="text" v-model="form.code" placeholder="请输入" v-if="dialog.option=='check'" :disabled="true"></el-input>
	      <el-input type="text" v-model="form.code" placeholder="请输入" v-else></el-input>
	    </el-form-item>
	  	
	  	<el-form-item prop="class_name" label="分类" >
		  <el-input type="text" v-model="form.class_name" placeholder="请输入" v-if="dialog.option=='check'" :disabled="true"></el-input>
		  <el-select v-model="form.class_name" placeholder="请输入" @change="selectType" v-else>
		      <el-option
		        v-for="item in classOptions"
		        :key="item.value"
		        :label="item.label"
		        :value="item.value">
		      </el-option>
		    </el-select>
	  	</el-form-item>
	  	
	  	<el-form-item prop="display_price" label="显示价格" >
		  <el-input type="text" v-model="form.display_price" placeholder="请输入" v-if="dialog.option=='check'" :disabled="true"></el-input>
	  	  <el-input type="text" v-model="form.display_price" placeholder="请输入" v-else></el-input>
	  	</el-form-item>
	  	
	  	<el-form-item prop="desc" label="商品描述" >
		  <el-input type="text" v-model="form.desc" placeholder="请输入" v-if="dialog.option=='check'" :disabled="true"></el-input>
	  	  <el-input type="text" v-model="form.desc" placeholder="请输入" v-else></el-input>
	  	</el-form-item>
	  	
	  	<el-form-item prop="actual_sales_num" label="实际销量" >
		  <el-input type="text" v-model="form.actual_sales_num" placeholder="请输入" v-if="dialog.option=='check'" :disabled="true"></el-input>
	  	  <el-input type="text" v-model="form.actual_sales_num" placeholder="请输入" v-else></el-input>
	  	</el-form-item>
	  	
	  	<el-form-item prop="virtual_sales_num" label="虚拟销量" >
		  <el-input type="text" v-model="form.virtual_sales_num" placeholder="请输入" v-if="dialog.option=='check'" :disabled="true"></el-input>
	  	  <el-input type="text" v-model="form.virtual_sales_num" placeholder="请输入" v-else></el-input>
	  	</el-form-item>
		
		<el-form-item prop="organizers" label="型号管理">
		  <div class="tableDate" style="margin-left: 25px;margin-bottom: 10px;">
		      <div class="button" style="width:36px;float:right;">
		        <P><el-button class="el-icon-plus" @click.prevent="addRow()"></el-button></P>
		        <p><el-button class="el-icon-minus" @click.prevent="delData()"></el-button></p>
		      </div>
		      <div class="table">
		        <el-table
		          :data="tableData"
		          ref="table"
		          tooltip-effect="dark"
		          border
		          stripe
				  :row-style="{height: '45px'}"
				  :header-cell-style="{'text-align':'left',padding: '0'}"
				  :cell-style="{'text-align':'left',padding: '0'}"
		          style="width: 90%"
		          @selection-change='selectRow'>
		          <el-table-column type="selection" width="45" align="center"></el-table-column>
		          <el-table-column label="序号"  type="index" width="60" align="center"></el-table-column>
		          <el-table-column  label="型号名称" align="center">
		            <template slot-scope="scope">
		                <el-input v-model="scope.row.spec_name"></el-input>
		            </template>
		          </el-table-column>
		  		  <el-table-column label="价格" align="center"  width="80">
		  		    <template slot-scope="scope">
		  		      <el-input v-model="scope.row.spec_price"></el-input>
		  		    </template>
		  		  </el-table-column>
				  <!-- <el-table-column label="单位" align="center"  width="80">
				    <template slot-scope="scope">
				      <el-input v-model="scope.row.unit"></el-input>
				    </template>
				  </el-table-column> -->
			  </el-table>
			 </div>
		  </div>
		</el-form-item>
		
		<!-- 商品详情 -->
		<!-- <el-upload
		  class="detail_btn"
		  :action="updateUrl"
		  :on-progress="handleProgress"
		  :on-success="handleSuccessDetail"
		  :on-remove="handleRemove"
		  :before-remove="beforeRemove"
		  :show-file-list='false'
		  style="border: 1px solid #DCDFE6;width: 285px;height: 194px;line-height: 30px;margin-left:5% ;padding-top: 80px;box-sizing: border-box;color: #666666!important;border-radius: 4px;margin-bottom: 10px;"
		>
			<el-button size="small" type="primary">点击详情图片上传</el-button>
		</el-upload>
		<div class="detail_box" v-for="(item,index) in detail_list" :key='index'>
			  <div class="img" v-if="item">
			    <img :src="SetImg(item)"></img>
			  		<i @click="deleteVoice()" v-if="dialog.option!='check'"></i>
			  </div>
		</div> -->
		<div class="detail_box">
			<div class="label">商品详情</div>
			<el-upload
			  ref="pictureUpload"
			  :action="updateUrl"
			  list-type="picture-card"
			  :on-success="handleSuccessDetail"
			  :auto-upload="true"
			  :file-list="file_list"
			  style="text-align: left;"
			  >
			    <i slot="default" class="el-icon-plus plus"></i>
			    <div slot="file" slot-scope="{file}" style="width: 100%;height: 100%;">
			      <img
			        class="el-upload-list__item-thumbnail"
			        :src="file.url" alt=""
			      >
			      <span class="el-upload-list__item-actions">
			        <span
			          v-if="!disabled"
			          class="el-upload-list__item-delete"
			          @click="handleRemoveDetail(file)"
			        >
			          <i class="el-icon-delete"></i>
			        </span>
			      </span>
			    </div>
			</el-upload>
		</div>
		
		<el-form-item prop="remark" label="驳回理由" v-if="dialog.option=='pass'||dialog.option=='refuse'">
		  <el-input type="text" v-model="form.remark" placeholder="请输入"></el-input>
		</el-form-item>
	  
	  	<div class="text_right" v-if="!(dialog.option=='check'&&dialog.title=='商品详情查看')">
	      <el-button type="primary" @click="onSubmit('form')" class="primary">
			  <span v-if="dialog.option=='add'">发 布</span>
			  <span v-if="dialog.option=='edit'">确 定</span>
			  <span v-if="dialog.option=='check'&&dialog.title=='团长产品详情'">通 过</span>
			  <span v-if="dialog.option=='pass'||dialog.option=='refuse'">通 过</span>
		  </el-button>
		  <el-button type="primary" class="no" v-if="dialog.option=='pass'||dialog.option=='refuse'"  @click="refuse()">
		  	  <span>驳 回</span>
		  </el-button>
		  
		  
	    </div>
	  </el-form>
  </el-main>
  
</div>
</template>

<script>
import {getChiefVerifyGoodsList,doGoods} from '@/api/index'
export default {
  name: 'WordOperate',
  props: {
    dialog: Object,
    form: Object,
	classOptions:Array
  },
  data () {
    return {
	  password:'',//删除时输入的密码
      loginUser: {
		 person_name:'',
		 person_pass:''
	  },
      // updateUrl: "https://lovehome.dysense.cn/admin/User/uploads_img",
	  updateUrl: "https://testlove.dysense.cn/admin/User/uploads_img",
      rules: {
      		  product_name: [
      		    { required: true, message: '请输入名称', trigger: 'blur' },
      		  ],
              product_img: [
                  { required: true, message: '请选择图片', trigger: 'blur' },
                ],
			  code:[
				   { required: true, message: '请输入', trigger: 'blur' },
			  ],
			  class_name:[
				  { required: true, message: '请输入', trigger: 'blur' },
			  ],
			  display_price:[
				  { required: true, message: '请输入', trigger: 'blur' },
			  ],
			  desc:[
			  	{ required: true, message: '请输入', trigger: 'blur' },
			  ],
			  actual_sales_num:[
				  { required: true, message: '请输入', trigger: 'blur' },
			  ],
			  virtual_sales_num:[
				  { required: true, message: '请输入', trigger: 'blur' },  
			  ]
      			  
      },
	  selectlistRow: [],
	  // detail_list:[],
	   
	   
	   dialogImageUrl: '',
	          dialogVisible: false,
	          disabled: false
    }
  },
  computed:{
  	tableData:function(){
  		let arr=[]
  		if(this.form.id){
  			arr=this.form.model_list
  		}
  		return arr 
  	} ,
	file_list:function(){
		if(this.form.content){
			let urlStr = this.form.content.split(",");
			 let arr=[]
			 urlStr.forEach(item => {
			    let obj = new Object();
			    obj.url = this.SetImg(item);
				obj.response = {};
				obj.response.data = item;
			    arr.push(obj);
			});
			return arr
		}
	},
	detail_list:function(){
		let arr=[]
		if(this.form.content){
			let urlStr = this.form.content.split(",");
			 urlStr.forEach(item => {
			    arr.push(item);
			});
		}
		return arr
	}
  },
  mounted () {
	
  },
  methods: {
     // 审核结果提交接口请求
	 chiefVerifyGoodsList(type) {
	   // 获取数据
	   const data = {
		   p_id:this.form.id,
		   is_verify:type,//1 通过 2拒绝,
		   remark:this.form.remark//拒绝原因
	   }
	   // 请求数据
	   getChiefVerifyGoodsList(data)
	     .then((res) => {
	 		console.log("提交审核结果")
	        console.log(res)
			if(res.code==200){
				this.$message({
				  message: '提交成功！',
				  type: 'success'
				})
				this.dialog.show = false
				this.$emit('update')
			}
	     })
	     .catch((err) => {
	       console.log(err)
	     })
	 },
	 // 产品增删改接口请求
	 goodsOperate() {
	   // 获取数据
	   const data = {
	 		act:this.dialog.option=='add'?'add':'edit',
	 		id:this.form.id,
	 		product_img:this.form.product_img,
			product_name:this.form.product_name,
			code:this.form.code,
			class_id:this.form.class_id,
			actual_sales_num:this.form.actual_sales_num,
			virtual_sales_num:this.form.virtual_sales_num,
			info_json:JSON.stringify(this.tableData),
			// chief_id:''//团长id,当前登录该权限的用户id
			chief_id:this.$store.getters.user_account.id,//团长id,当前登录该权限的用户id
			desc:this.form.desc,//描述
			content: this.detail_list.join(','),//详情
			display_price:this.form.display_price,//显示价格
	   }
	   // 请求数据
	   doGoods(data)
	     .then((res) => {
	 		console.log("产品增删改")
	        console.log(res)
			if(res.code==200){
				this.$message({
				  message: '操作成功！',
				  type: 'success'
				})
				this.dialog.show = false
				this.$emit('update')
				// if(this.dialog.title=='商品编辑'){
				// 	this.$emit('uptree')
				// }
				
			}
	     })
	     .catch((err) => {
	       console.log(err)
	     })
	 },
    // 图片删除方法
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    // 上传图片钩子方法
    handleProgress (event, file, fileList) {
      console.log(file)
      // setTimeout((file) => {
      //   console.log(file.response)
      // }, 5000)
    },
    // 上传图片成功
    handleSuccessBattery  (res, file, fileList) {
	 this.$set(this.form, 'product_img',res.data)
      this.form.product_img = res.data
	  console.log("成功！")
      console.log(this.form.product_img)
    },
	// 上传详情图片成功
	handleSuccessDetail  (res, file, fileList) {
	  // console.log(index)
	  console.log(res.data)
	  console.log("成功！")
	  // // this.$set(this.form, 'pronunciation_file',res.data)
	  // // this.form.product_img = res.data
	  this.detail_list.push(res.data)
	  // console.log("成功！")
	  console.log(this.detail_list)
	},
    handleSuccessType (res, file, fileList) {
      this.vehicle_model_img = res.data
      console.log(this.vehicle_model_img)
    },
    // 上传图片删除
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
	
	handleRemoveDetail(file) {
	    console.log(file);
		console.log(file.response.data)
		console.log(this.$refs.pictureUpload.uploadFiles)
		for(let i=0;i<this.$refs.pictureUpload.uploadFiles.length;i++){
			console.log(this.$refs.pictureUpload.uploadFiles[i].response.data)
			if(this.$refs.pictureUpload.uploadFiles[i].response.data==file.response.data){
				 this.$refs.pictureUpload.uploadFiles.splice(i,1)
				 this.detail_list.splice(i,1)
				 console.log(i)
			}
		}
		console.log('删除后')
		console.log(this.$refs.pictureUpload.uploadFiles)
		console.log(this.detail_list)
	},

	
	// 分类选中方法
	selectType(e){
		console.log("选择的分类id")
		console.log(e)
		this.form.class_id=e
		let obj= this.classOptions.filter(function(item){
             return item.value == e; 
        })
		console.log(obj)
		this.form.class_name=obj[0].label
		console.log(this.form.class_id)
		console.log(this.form.class_name)
	},
	// 获取表格选中时的数据
	selectRow (val) {
	   console.log(val)
	   this.selectlistRow = val
	},
	// 型号管理增加行
	addRow () {
	  var list = {
		id:'',
	    spec_price: this.price,
	    spec_name: this.name,
	    is_del:0
		}
	  // this.tableData.unshift(list) //插入至第一个
	  this.tableData.push(list) //插入至最后一个
	},      
	//型号管理行删除方法
	  // 删除选中行
	  delData () {
	    for (let i = 0; i < this.selectlistRow.length; i++) {
	      let val = this.selectlistRow
	      // 获取选中行的索引的方法
	      // 遍历表格中tableData数据和选中的val数据，比较它们的rowNum,相等则输出选中行的索引
	      // rowNum的作用主要是为了让每一行有一个唯一的数据，方便比较，可以根据个人的开发需求从后台传入特定的数据
	      val.forEach((val, index) => {
	        this.tableData.forEach((v, i) => {
	          // if (val.rowNum === v.rowNum) {
				if (val.spec_name === v.spec_name) {
				  console.log("选中的索引")
				  console.log(i)
	            // i 为选中的索引
	            this.tableData.splice(i, 1)
	          }
	        })
	      })
	    }
	    // 删除完数据之后清除勾选框
	    // this.$refs.tableData.clearSelection()
	},    
    // 提交按钮
    onSubmit (form) {
		console.log(this.form)
		console.log(this.tableData)
		console.log(JSON.stringify(this.tableData))
		if(this.dialog.option=='check'||this.dialog.option=='pass'||this.dialog.option=='refuse'){
			 this.chiefVerifyGoodsList(1) //通过
		}else{ //发布或编辑
			  this.$refs[form].validate(valid => {
			    if (valid) {
			      // 表单数据验证完成之后，提交数据;
			      this.goodsOperate()
			     }
			  })
		}
    },
	// 驳回按钮
	refuse(){
		// 驳回提示
		this.$confirm('确认要驳回吗，驳回后不可恢复', {
		  confirmButtonText: '确定',
		  cancelButtonText: '取消',
		  confirmButtonClass: 'delete',
		}).then(() => {
		  // 驳回请求
		  this.chiefVerifyGoodsList(2) //驳回
		}).catch(() => {
		  this.$message({
		    type: 'info',
		    message: '已取消驳回'
		  })
		})
	},
	// 删除按钮
	onDel () {
		console.log("删除")
		console.log(this.password)
		this.getCookie()
		console.log(this.loginUser.person_pass)
		if(this.password==this.loginUser.person_pass){
			console.log("密码输入正确")
			  // 删除数据;
			  const data = {
			   act:'del',
			   id: this.form.id,//	图片id
			   product_img: this.form.product_img,//图片地址
			   title:this.form.title,//	标题
			   web_url:this.form.web_url,//网页地址
			   img_type: this.form.img_type,//类型
			   img_sort:this.form.img_sort,//排序
			  }
			  console.log(data)
			  getChiefVerifyGoodsList(data).then((res) => {
			    console.log(res)
			    if (res.msg === '成功') {
			      // 操作成功
			      this.$message({
			        message: '删除成功！',
			        type: 'success'
			      })
				  }
			      this.dialog.show = false
			      this.$emit('update')
			  })
			    .catch((err) => {
			      console.log(err)
			    })
		}
	   
	},
    // 获取图片上传的地址
    // getUrl (data) {
    //   this.updateUrl = uploads_file()
    //   console.log(this.updateUrl)
    // },
	// 给请求回来的图片地址加上后台服务器地址，可以正常显示
	SetImg(value){
		// var str =getApp().globalData.https + value
		// var str ="https://lovehome.dysense.cn" + value
		var str ="https://testlove.dysense.cn" + value
		if(value != ""){
			return str
		}
	},
    // 删除编辑界面中原有图片的方法
    deleteVoice () {
      console.log('delete')
	  this.form.product_img =''
	  this.$forceUpdate()
    },
  },
  watch:{
	  from:function(val,old){
		  console.log('变化了')
		  console.log(val)
		  console.log(old)
	  }
  }
}
</script>

<style scoped>
.goods_op{
  /* width: 418px; */
  width: 540px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background-color: #FFFFFF;
  opacity: 1;
  /*height: 100%;*/
}
/* .manage_tip{
	position: relative;
} */
.manage_tip .title {
  /*height: 20px;*/
  font-family: "Microsoft YaHei";
  margin: 25px auto;
  width: 100px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #303133;
}
.close{
	position: absolute;
	right: 0;
	top: 0;
	border: none;
}
.pic{
  display: flex;
}
.main{
	height: 500px;
	padding: 0 20px;
}
.img{
	width: 280px;
	height: 194px;
  margin-left: 5%;
  position: relative;
}
.img img{
  width: 280px;
  height: 194px;
}
.img i{
  width: 17px;
  height: 17px;
  display: inline-block;
  background: url("../assets/del.png") center no-repeat;
  background-size: 100%;
  position: absolute;
  right: -10px;
  top: -5px;
}
/deep/ .el-input__inner{
  height:40px;
  width: 90%;
  margin-bottom: 10px;
}
/deep/ .el-form{
  margin: 0;
}
/deep/ .el-form-item{
  width: 100%;
  /* display: flex !important; */
  /* justify-content: start; */
  margin-bottom: 2%;
}
/deep/ .el-form-item__content{
 /*  width: 85%;
  display: flex;
  position: relative;
  margin-left: 0!important; */
  /* margin-left: 80px !important; */
}
/deep/ .el-form-item__label{
  text-align: right;
  /* margin-left: 25px !important; */
  padding: 0;
  /*margin-right: 5%;*/
}
/deep/ .el-select{
	/* margin-left: 3%; */
	width: 100%;
	text-align: center;
	position: relative;
}
/deep/ .el-select{
	width:100%;
	margin: 0 auto;
	left: 0;
	text-align: center;
}
/deep/ .el-tag.el-tag--info{
	margin-left: 35px;
	margin-bottom: 10px;
}
/deep/ .el-input__suffix{
	margin-right: 30px;
	height: 40px;
}
/deep/ .el-upload--text{
	color: #C4C6CF !important;
}
/deep/.el-select .el-input__inner{
	width: 370px!important;
	height: 40px !important;
	border: 1px solid #DCDFE6 !important;
} 
.text_right{
	/* background-color: #0486FE; */
	width:200px;
	/* text-align: center; */
	display: flex;
	justify-content: center;
	margin: 0 auto;
	margin-bottom: 20px;
	margin-top: 10px;
	
}
.text_right1{
	/* background-color: #0486FE; */
	width:200px;
	text-align: center;
	margin: 0 auto;
	margin-bottom: 20px;
	margin-top: 10px;
	
}
.primary{
	background-color: #E91A1B !important;
	border: none;
	color: #FFFFFF;
}
.no{
	display: inline-block;
	background-color: none !important;
	color: #333333 !important;
	width: 66px;
	height: 40px;
	background: #FFFFFF;
	border-radius: 3px;
	border: 1px solid #C4C6CF;
	margin-left: 30px;
}
.Clipped{
  margin-left: 5px;
  margin-right: 5px;
}
/deep/.el-form-item__error{
	left:25px;
	top:80%;
}
.detail_box{
	display: flex;
	align-items:flex-start;
	justify-content: start;
	padding-left: 20px;
	box-sizing: border-box;
}
.label{
	width: 85px;
	font-size: 15px;
	/* margin-right: 20px; */
	text-align: left;
}
.plus{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}
.detail_btn{
	border: none!important;
	width: auto!important;
	height: auto!important;
	padding-top: 0!important;
}
/deep/.el-upload--picture-card{
	width: 180px;
	height: 180px;
	position: relative;
}
/deep/.el-upload-list--picture-card .el-upload-list__item{
	width: 180px;
	height: 180px;
}
</style>

