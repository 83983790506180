<template>
<div class="share_money">
  <div class="top">
	  <span>团长分红</span>
  </div>
  <div class="tabber_box">
	  <div class="search_item">
	    <div>搜索内容</div>
	    <input type="text" placeholder="请输入团长姓名或者小区名称" v-model="search"/>
	  </div>
  	  <!-- <div class="search_item">
  		<div>团长姓名</div>
  		<input type="text" placeholder="请输入" v-model="head_name"/>
  	  </div> -->
	  <!-- <div class="search_item">
	    <div>店铺名称</div>
	    <input type="text" placeholder="请输入" v-model="store_name"/>
	  </div> -->
  	  <div class="search">搜索</div>
  </div>
  <div class="table_container">
     <div class="table_top">
		 <!-- <div class='t_r'>导出查询内容</div>
		 <div class='t_r'>导出近7天待发货商品</div>
		 <div class='t_r'>导出商品配货表</div>
		 <div class='t_r'>电子面单</div> -->
		 <div>
			 <span>已结算收入:</span>
			 <span class="money">2000</span>
		 </div>
		 <div>
		 	<span>未结算收入:</span>
		 	<span class="money">1000</span>
		 </div>
	 </div>
     <el-table
       :data="tableData"
       size="mini"
       fit
	   border
	   stripe
       :default-sort="{prop:'date', order: 'descending'}"
       :row-style="{height: '45px'}"
	   :header-cell-style="{'text-align':'left',padding: '0'}"
       :cell-style="{padding: '0','text-align':'left'}"
       style="width: 100%"
    >
	<!-- 展开详情信息 -->
	 <el-table-column type="expand">
	      <template slot-scope="props">
	        <el-form label-position="left" inline class="demo-table-expand">
	          <el-form-item label="区域地址">
	            <div>{{props.row.chief_region}}</div>
	          </el-form-item>
	        </el-form>
	      </template>
	</el-table-column>
	<!-- 表格的列 -->
	 <el-table-column prop="chief_name" label="团长姓名" align="center" width="200"></el-table-column>
     <el-table-column prop="chief_phone" label="联系方式" align="center" width="200"></el-table-column>
	 <!-- <el-table-column prop="organizers" label="店铺名称" align="center"></el-table-column> -->
	 <el-table-column prop="chief_region" label="区域地址" align="center" show-overflow-tooltip ></el-table-column>
	 <el-table-column prop="settled" label="已结算收入" align="center" sortable width="200"></el-table-column>
	 <el-table-column prop="be_settled" label="待结算收入" align="center" sortable width="200"></el-table-column>
     <el-table-column prop="operation" align="center" label="操作" width="120">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
			style="color:#5584FF;"
            @click="settlement(scope.row)"
          >结算订单</el-button>
		 <!-- <el-button
		    type="text"
		    size="small"
			style="color: #5584FF;"
		    @click="onDelete(scope.row,scope.$index)"
		  >下架</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-row class="page">
        <el-col>
          <div class="pagination">
            <el-pagination
              v-if="paginations.total > 0"
              @current-change="handleCurrentChange"
              :current-page.sync="paginations.page_index"
              :page-size="paginations.page_size"
              layout="prev, pager, next, jumper"
              :total="paginations.total">
            </el-pagination>
          </div>
        </el-col>
      </el-row>
  </div>
  <!-- 审核通过-->
  <div class="marks" v-if="dialog.show">
	   <!-- <CompetitionOperate :dialog="dialog" :form="form" @update="getProfile"></CompetitionOperate> -->
	   <!-- <GoodsOperate :dialog="dialog" :form="form" @update="getProfile"></GoodsOperate> -->
  </div>

</div>
</template>

<script>
import { getChiedBonusList,SettlementOrder } from '@/api/index'
import GoodsOperate from '@/components/GoodsOperate.vue'
export default {
  name: 'ServerHome',
  data () {
    return {
      fullHeight: '', // fullHeight: document.documentElement.clientHeight  屏幕高度 默认值
      tableData: [],
      // 需要给分页组件传的信息
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10 // 1页显示多少条
      },
	  dialog: {
	    show: false,
	    title: '',
	    option: 'edit'
	  },
	  form: {},
	  search:'',//搜索框内容
	  head_name:'',//搜索框内容
	  store_name:'',//搜索框内容
    }
  },
  components: {
	GoodsOperate
  },
  created () {
    // 加载数据表格
    this.getProfile(1)
    // 获取屏幕高度
    this.fullHeight = `${document.documentElement.clientHeight}`// 默认值
    const that = this
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        that.fullHeight = window.fullHeight
      })()
    }
  },
  methods: {
	getProfile (pages,type) {
		console.log("切换至")
		console.log(pages,this.pagesNumber)
	  // 获取表格数据
	  const data = {
	    search:this.search,
		page: pages? pages:this.pagesNumber,
	    limit: '10',
	  }
	  // 请求数据
	  getChiedBonusList(data)
	    .then((res) => {
	      console.log(res)
		  console.log(res.data)
		  this.paginations.total=res.count_page
		  this.tableData=res.data
		  console.log("总共有"+ this.paginations.total+"条数据")
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
    // 根据屏幕的高度实时动态修改分页条数
    changePageSize (fullHeight) {
      if (fullHeight > 801) {
        this.paginations.page_size = 15
      } else {
        this.paginations.page_size = 10
      }
    },
	// 表格数据选中方法
	handleSelectionChange(val) {
		console.log(val)
	   this.multipleSelection = val;
	   console.log( this.multipleSelection)
	},
	
	//结算操作
	settlement (row, index) {
	  // 结算提示
	  console.log(row, index)
	  this.$confirm('确认要结算吗？', {
	    confirmButtonText: '确定',
	    cancelButtonText: '取消',
	    confirmButtonClass: 'delete',
	  }).then(() => {
	    // 结算请求
	    const data = {
	      id: row.id,
	    }
	    console.log(data)
	    SettlementOrder(data).then((res) => {
	      console.log(res)
	      if (res.msg === '成功') {
	        // 操作成功
	        this.$message({
	          message: '结算成功！',
	          type: 'success'
	        })
	        this.getProfile(this.pagesNumber)
	      }else{
			this.$message({
			  message: '没有需要结算的订单！',
			  type: 'warning',
			  offset:300
			})  
		  }
	    })
	      .catch((err) => {
	        console.log(err)
	      })
	  }).catch(() => {
	    this.$message({
	      type: 'info',
	      message: '已取消结算'
	    })
	  })
	},
    handleCurrentChange (page) {
      const number = String(page)
	  this.pagesNumber=number
	  console.log("切换至"+this.pagesNumber+"页")
      // 当点击切换分页数据时，发送请求给服务器，让其把相应的页码数据内容发送回来
	  const data = {
		search:this.search,
	    page: number,
	    limit: '10',
	  }
      console.log(data)
      // 发送请求
      // 请求数据
      getChiedBonusList(data)
        .then((res) => {
          console.log(res)
      	  console.log(res.data)
		  this.tableData=res.data
        })
        .catch((err) => {
          console.log(err)
        })

      // // 当前页
      // const sortnum = this.paginations.page_size * (page - 1)
      // const table = this.allTableData.filter((item, index) => {
      //   return index >= sortnum
      // })
      // // 设置默认分页数据
      // this.tableData = table.filter((item, index) => {
      //   return index < this.paginations.page_size
      // })
    },
	// 格式化后台传来的时间戳（年月日时分秒）
	timeFormate (timeStamp) {
	      var date = new Date(timeStamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
	      const Y = date.getFullYear() + '-'
	      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
	      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	      // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
		  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) 
	      const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	      return Y + M + D 
	},

  },
  // 监听屏幕变化
  // watch: {
  //   // 如果 `fullHeight ` 发生改变，这个函数就会运行
  //   fullHeight: function (val) {
  //     if (!this.timer) {
  //       this.fullHeight = val
  //       this.changePageSize(this.fullHeight)
  //       this.getProfile()
  //       console.log(val)
  //       console.log(this.paginations.page_size)
  //       this.timer = true
  //       const that = this
  //       setTimeout(function () { // 频繁触发 resize 函数，会导致页面很卡
  //         that.timer = false
  //       }, 100)
  //     }
  //   }

  // }
}
</script>
<style scoped>
  .share_money{
	width: 100%;
	height: 100%;
    color: #606266;
	/* position: relative; */
	top: 0;
	overflow: auto;
	background-color: #F7F8FA;
  }
  .top{
	  width: 100px;
	  height: 28px;
	  border-left: 4px solid #FB5C55;
	  font-size: 20px;
	  color: #333333;
	  font-weight: bold;
	  margin: 24px 0 10px 20px;
  }
  .tabber_box {
  	width: calc(100% - 40px);
  	height: 40px;
  	font-size: 14px;
  	color:#333333 ;
  	line-height: 40px;
  	margin-top: 23px;
  	margin-bottom: 24px;
  	display: flex;
  	align-items: center;
  	justify-content: start;
  	margin-left: 20px;
	padding-bottom: 25px;
	border-bottom: 1px solid #C4C6CF;
  }
  .search_item{
  	display: flex;
  	align-items: center;
  	justify-content: start;  
  	margin-right: 37px;
  }
  input{
  	  width: 250px;
  	  height: 32px;
  	  margin-left: 13px;
  	  border: 1px solid #C4C6CF;
  	  padding-left: 12px;
  	  border-radius: 3px;
  }
  /deep/.el-select .el-input__inner{
  	width: 250px;
  	height: 34px;
  	/* margin-left: 13px; */
  	 border: 1px solid #C4C6CF;
  	 padding-left: 12px;
  	 border-radius: 3px;
  }
  .tabber_box .search{
  	  width: 66px;
  	  height: 36px;
  	  border-radius: 3px;
  	  background-color: #E91A1B;
  	  color: #FFFFFF;
  	  line-height: 36px;
  	  text-align: center;
  	  margin-left: 37px;
  	  
  }
  /* .tabber_box span{
	  display: inline-block;
	  text-align: center;
	  line-height: 40px;
	  width: 80px;
	  height: 40px;
	  border-radius: 3px 0 0 3px;
  } */
  /* .yes{
	  background-color: #FB5C55;
	   color: #FFFFFF;
	   border: 1px solid #FB5C55;
  }
  .no{
	  background-color: #F2F3F7;
	  border: 1px solid #C4C6CF;
	  font-size: 12px;
	  color:#333333 ;
  } */
  
  .share_money .table_container{
    /* padding-left:10px; */
	padding: 0 10px;
  }
  .table_top{
	  width: 100%;
	  display: flex;
	  align-items: center;
	  justify-content: start;
	  /* flex-direction: row-reverse; */
	  font-size: 14px;
	  margin-bottom: 25px;
  }
  .table_top div{
	  margin-right: 60px;
  }
  .table_top span{
	 font-size: 16px;
	 font-family: PingFangSC-Regular, PingFang SC;
	 font-weight: 400;
	 color: #333333;
	 line-height: 22px; 
  }
  .money{
	  margin-left: 10px;
	  color: #E91A1B !important;
	  font-weight: bold;
  }
 /* .t_r{
	  height: 36px;
	  padding: 0 17px;
	  border: 1px solid #C4C6CF;
	  color: #333333;
	  line-height: 36px;
	  text-align: center;
	  border-radius: 3px;
	  margin-left: 36px;
  } */
  /deep/.cell{
	display: flex;
	align-items: center;
	justify-content: start;  
  }
  /deep/.cell img{
	  width: 34px;
	  height: 34px;
	  margin-right: 10px;
  }
  .s_r{
	  height: 40px;
	  display: flex;
	  flex-direction: column;
	  line-height: 40px;
  }
  .name{
	  height: 20px;
	  line-height: 20px;
  }
  .price{
	  height: 20px;
	  line-height: 20px;
	  color: #E91A1B;
  }
  .share_money .page{
    width: 50%;
    margin: 20px auto;
    text-align: center;
  }
  /*为了设置每行的高度不会随着内容的增多而变大设置的样式，需要结合表格行内样式的row-style、cell-style一起来使用*/
  /deep/ .el-table .cell{
    height: 45px;
    line-height: 45px;
    overflow: hidden;
  }
  .marks{
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background: rgba(0,0,0,0.6);
	  z-index: 999;
  }
  /deep/.el-table--mini td, .el-table--mini th{
	  padding: 0;
  }
  /deep/.el-table td, .el-table th{
	  padding: 0;
  }
  /deep/ .el-table::before{
	  background-color: rgba(255,255,255,0);
  }
  /deep/.el-table .descending .sort-caret.descending{
	 border-top-color: #E91A1B !important; 
  }
  /deep/.el-table .ascending .sort-caret.ascending{
  	 border-bottom-color: #E91A1B !important; 
  }
  /* 展开表格详情列表 */
  .demo-table-expand {
      font-size: 0;
  	  padding: 20px;
  	  box-sizing: border-box;
    }
    .demo-table-expand label {
      width: 90px;
      color: #99A9BF!important;
    }
  	/deep/.el-form--inline .el-form-item__label{
  		width: 90px;
  		color: #99A9BF!important;
  		font-weight: bold;
  	}
    .demo-table-expand .el-form-item {
      margin-right: 0;
      margin-bottom: 0;
      width: 100%;
  	  color: #666666;
  	  word-wrap: break-word;
  	  word-break: break-all;
  	 /* display: flex;
  	  justify-content: start; */
    }
  	/deep/.el-form--inline .el-form-item__content{
  		width: calc(100% - 90px);
  	}
  	/deep/.el-table__expand-icon{
		width: 100%;
  		height: 45px;
  	}
</style>
<style>
	.delete{
		  background-color: #E91A1B!important;
		  color: #FFFFFF;
		  border: 1px solid #E91A1B !important;
	}
</style>
