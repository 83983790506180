<template>
	<div class="map">
		<div class="manage_tip">
		  <p class="title">位置选择</p>
			<el-button  icon="el-icon-close" @click="dialog.show = false" class="close"></el-button>
		</div>
		<baidu-map class="bm-view"
				:center="center"
		        :zoom="zoom"
		        :scroll-wheel-zoom='true'
		        @ready="handler" 
				@click="getClickInfo"
				@moving="syncCenterAndZoom"
				@moveend="syncCenterAndZoom"
				@zoomend="syncCenterAndZoom"
				>
						<!-- 缩放控制件 -->
						<bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
						       <!-- 定位控件 -->
						        <bm-geolocation
						               anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
						               :showAddressBar="true"
						               :autoLocation="true"
						               :locationIcon="{url: require('../assets/12.png'), size: {width: 33, height: 49},opts: {imageSize: {width: 33, height: 49}, }}" 
						               @locationSuccess='locationSuccess' 
													>
						        </bm-geolocation>
						       <!-- 自定义组件 -->
						       	 <bm-control>
						       	         <div id="searchbox" class="clearfix">
						       	           <div id="searchbox-container">
						       	             <div id="sole-searchbox-content" class="searchbox-content">
												 <img src="../assets/seach.png">
						       	                 <input id="sole-input" class="searchbox-content-common cc_cursor" type="text" name="word" autocomplete="off" maxlength="256"
						       	                      placeholder="请输入地址"
						       	                      v-model="keyword">
						       	             </div>
						       	           </div>
						       	         </div>
						        </bm-control>
						       <!-- 搜索地点-->
						        <bm-local-search :keyword="keyword" :auto-viewport="true" :location="location" :pageCapacity='2' :panel='false'></bm-local-search>
							   <!-- 地图标记点 -->
							    <bml-marker-clusterer :averageCenter="true">
							       <bm-marker :position="{ lng: center.lng, lat: center.lat }" animation="BMAP_ANIMATION_BOUNCE" :dragging="true">
									  <!-- <bm-label :content="marker.name" :position="{lng: marker.lng, lat: marker.lat}" :labelStyle="{color: '#1E1E1Ed', fontSize : '16px',border:'none',padding:'5px'}"  :offset="{width: -35, height: 30}"/> -->
								   </bm-marker>
							    </bml-marker-clusterer>	
		</baidu-map>
		<div class="show" v-if="selectAddress">
			<div style="margin-bottom: 10px;">
				<span style="color: #333333;">选择的地址为：</span>
				<span style="font-weight: bold;">{{selectAddress }}</span>
		    </div>
			<el-button type="primary" @click="sure">确定</el-button>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'Map',
		props: {
		  dialog: Object,
		},
	        data(){
	            return{
					city: '',
					province: '',
					district: '',
					center: {lng: 0, lat: 0},
					zoom: 16,//百度地图的放大级数
					mapStyle:{
						style : "midnight"                     
					},
					keyword:'',//搜地点
					location: '天津',
					selectAddress:null,//选择的地址
	            }
	        },
            methods:{
            	// 设置地图加载的初始位置
            	 handler ({BMap, map}) {
            		 console.log(666)
            	      console.log(BMap, map)
            	      // this.center.lng = 116.404
            	      // this.center.lat = 39.915  
            		  this.center.lng = 117.220656
            		  this.center.lat = 39.046226
            	      this.zoom = 16
            	    },
            	// 定位成功返回当前地址信息
            	locationSuccess(e){
            		console.log(e)
            	},
            	// 点击地图上的位置触发的方法
            	getClickInfo(e) {
            		console.log(1)
            		console.log(e)
            		 // 地图中心的变更
            	　　this.center.lng = e.point.lng;
            	　　this.center.lat = e.point.lat;
				   this.zoom = e.target.getZoom();
            	    this.getAddress(e.point)//逆向解析地址
            	},
            	// 移动地图触发的方法
            	syncCenterAndZoom(e) {
            		console.log(2)
            		console.log(e)
            	　　const { lng, lat } = e.target.getCenter();
            	    // 地图中心的变更
            	　　this.center.lng = lng;
            	　　this.center.lat = lat;
            	　　this.zoom = e.target.getZoom();
				    this.getAddress(e.target.getCenter())//逆向解析地址
				
            	},
				// 解析地址
				getAddress (data) {
					console.log("逆向解析地址")
					console.log(data)
				    let geocoder= new BMap.Geocoder();  //创建地址解析器的实例
				    const _this = this
				    // 根据坐标逆解析获取地址详细描述
				    geocoder.getLocation(data, function (result) {
				      if (result) {
				      //   _this.city = result.addressComponents.city
				      //   _this.province = result.addressComponents.province
				      //   _this.district = result.addressComponents.district
				    		// console.log(result)
				    		// console.log(_this.city)
				    		// console.log( _this.province)
				    		// console.log( _this.district)
				    		// console.log(result.surroundingPois[0].address + result.surroundingPois[0].title)
				    		// console.log(result.address)
				    		_this.selectAddress=result.surroundingPois.length > 0? result.surroundingPois[0].address + result.surroundingPois[0].title: result.address
				    		console.log(_this.selectAddress)
				    	}
				    }); 
				},
			    // 确定
				sure(){
					console.log(this.selectAddress)
					 this.dialog.show = false
					 this.$emit('update',this.selectAddress)
				},
		}
	}
</script>

<style scoped="scoped">
.map{
	width:660px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
	background-color: #FFFFFF;
	opacity: 1;
	padding:0 30px;
	box-sizing: border-box;
}
.manage_tip .title {
  /*height: 20px;*/
  font-family: "Microsoft YaHei";
  margin: 25px auto;
  width: 100px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #303133;
}
.close{
	position: absolute;
	right: 0;
	top: 0;
	border: none;
}
.bm-view {
     width: 600px;
  	 height: 350px;
	 margin-bottom: 30px;
  }
  .search_icon{
  	width: 44px;
  	height: 44px;
  	background: #19223F;
  	box-shadow: 0px 2px 6px 0px rgba(29, 33, 62, 0.4);
  	border-radius: 4px;
  	position: relative;
  	margin-left: 10px;
  	margin-top: 10px;
  }
  .search_icon img{
  	width: 20px;
  	height: 20px;
  	position: absolute;
  	left: 50%;
  	top: 50%;
  	transform: translate(-50%,-50%);
  }
  #sole-searchbox-content{
  	width: 300px;
  	height: 36px;
  	border-radius: 4px;
  	background-color: #EEEEEE;
  	position: relative;
  	display: flex;
  	align-items: center;
  	justify-content: start;
  	margin-top: 10px;
  	margin-left:10px;
  	padding-left: 10px;
  	color: #FFFFFF;
	border: 1px solid #C4C6CF;
  }
  #sole-input{
  	height: 36px;
  	width: 200px;
  	background-color: #EEEEEE;
  	border: none;
  	color: #1E1E1E;
  	outline:none;
	margin-left:10px ;
  }
  #sole-searchbox-content img{
  	width: 20px;
  	height: 20px;
  }
  #sole-searchbox-content .line{
  	width: 1px;
  	height: 14px;
  	background: #FFFFFF;
  	opacity: 0.2;
  	margin:0 23px ;
  }
/* 去掉百度地图引入的左下角logo及水印 */
  /deep/.BMap_cpyCtrl {
       display: none !important;
  }
  /deep/.anchorBL {
       display: none!important;
  }
  .show{
	 margin-bottom: 30px; 
  }
  /deep/.el-button--primary{
	  background-color: #E91A1B;
	  border-color:#E91A1B ;
  }
</style>
