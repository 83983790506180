<template>
<div class="banner_op">
  <div class="manage_tip">
    <p class="title">{{ dialog.title }}</p>
	<el-button  icon="el-icon-close" @click="dialog.show = false" class="close"></el-button>
  </div>
  <el-main class='main'>
	  <el-form
	    ref="form"
	    :model="form"
		:rules="rules"
	    label-width="80px"
	    style="width:100%"
	  >
	  	<el-form-item prop="banner_img" label="">
	  	  <div class="img" v-if="form.banner_img">
	  	    <img :src="SetImg(form.banner_img)"></img>
	  	  		<i @click="deleteVoice()" v-if="dialog.option!='check'"></i>
	  	  </div>
	  	  <el-upload
	  	    class="upload-demo"
	  	    :action="updateUrl"
	  	    :on-progress="handleProgress"
	  	    :on-success="handleSuccessBattery"
	  	    :on-remove="handleRemove"
	  	    :before-remove="beforeRemove"
	  	    style="border: 1px solid #DCDFE6;width: 285px;height: 194px;line-height: 30px;margin-left:5% ;padding-top: 80px;box-sizing: border-box;color: #666666!important;border-radius: 4px;margin-bottom: 10px;"
	  	  		v-if="!form.banner_img"
	  	  >
	  	  		 <i class="el-icon-upload"></i>
	  	    <span class="el-upload__text">点击此处上传图片</span>
	  	  </el-upload>
	  	</el-form-item>
		
	  	<div class="text_right">
	      <el-button type="primary" @click="onSubmit('form')" class="primary">
			  <span>确 定</span>
		  </el-button>
	    </div>
	  </el-form>
  </el-main>
  
</div>
</template>

<script>
import {dobanner} from '@/api/index'
export default {
  name: 'BannerOperate',
  props: {
    dialog: Object,
    form: Object,
  },
  data () {
    return {
	  password:'',//删除时输入的密码
      loginUser: {
		 person_name:'',
		 person_pass:''
	  },
      // updateUrl: "https://lovehome.dysense.cn/admin/User/uploads_img",
	  updateUrl: "https://testlove.dysense.cn/admin/User/uploads_img",
      rules: {
              banner_img: [
                  { required: true, message: '请选择图片', trigger: 'blur' },
                ],
      },
	  selectlistRow: [],
    }
  },
  mounted () {
	
  },
  methods: {
	 // 轮播图增删改接口请求
	 bannerOperate() {
	   // 获取数据
	   const data = {
	 		act:this.dialog.option='edit',
	 		id:this.form.id,
	 		banner_img:this.form.banner_img,
	   }
	   // 请求数据
	   dobanner(data)
	     .then((res) => {
	 		console.log("轮播图修改")
	        console.log(res)
			if(res.code==200){
				this.$message({
				  message: '操作成功！',
				  type: 'success'
				})
				this.dialog.show = false
				this.$emit('update')
			}
	     })
	     .catch((err) => {
	       console.log(err)
	     })
	 },
    // 图片删除方法
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    // 上传图片钩子方法
    handleProgress (event, file, fileList) {
      console.log(file)
      // setTimeout((file) => {
      //   console.log(file.response)
      // }, 5000)
    },
    // 上传图片成功
    handleSuccessBattery  (res, file, fileList) {
	 this.$set(this.form, 'pronunciation_file',res.data)
      this.form.banner_img = res.data
	  console.log("成功！")
      console.log(this.form.banner_img)
    },
    handleSuccessType (res, file, fileList) {
      this.vehicle_model_img = res.data
      console.log(this.vehicle_model_img)
    },
    // 上传图片删除
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 提交按钮
    onSubmit (form) {
		console.log(this.form)
	    this.$refs[form].validate(valid => {
			    if (valid) {
			      // 表单数据验证完成之后，提交数据;
			      this.bannerOperate()
			     }
			  })
    },
    // 获取图片上传的地址
    // getUrl (data) {
    //   this.updateUrl = uploads_file()
    //   console.log(this.updateUrl)
    // },
	// 给请求回来的图片地址加上后台服务器地址，可以正常显示
	SetImg(value){
		// var str =getApp().globalData.https + value
		// var str ="https://lovehome.dysense.cn" + value
		var str ="https://testlove.dysense.cn" + value
		if(value != ""){
			return str
		}
	},
    // 删除编辑界面中原有图片的方法
    deleteVoice () {
      console.log('delete')
	  this.form.banner_img =''
	  this.$forceUpdate()
    },
  },
  watch:{
	  from:function(val,old){
		  console.log('变化了')
		  console.log(val)
		  console.log(old)
	  }
  }
}
</script>

<style scoped>
.banner_op{
  /* width: 418px; */
  width: 540px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background-color: #FFFFFF;
  opacity: 1;
  /*height: 100%;*/
}
.manage_tip .title {
  /*height: 20px;*/
  font-family: "Microsoft YaHei";
  margin: 25px auto;
  margin-bottom: 15px;
  width: 100px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #303133;
}
.close{
	position: absolute;
	right: 0;
	top: 0;
	border: none;
}
.pic{
  display: flex;
}
.main{
	/* height: 500px; */
	padding: 0 20px;
	/* position: relative; */
}
.img{
  width: 280px;
   height: 194px;
  margin-left: 5%;
  position: relative;
  margin-top: 10px;
}
.img img{
  width: 280px;
  height: 194px;
}
.img i{
  width: 17px;
  height: 17px;
  display: inline-block;
  background: url("../assets/del.png") center no-repeat;
  background-size: 100%;
  position: absolute;
  right: -10px;
  top: -5px;
}
/deep/ .el-input__inner{
  height:40px;
  width: 90%;
  margin-bottom: 10px;
}
/deep/ .el-form{
  margin: 0;
}
/deep/ .el-form-item{
  width: 100%;
  /* display: flex !important; */
  /* justify-content: start; */
  margin-bottom: 2%;
}
/deep/ .el-form-item__content{
 /*  width: 85%;
  display: flex;
  position: relative;
  margin-left: 0!important; */
  /* margin-left: 80px !important; */
}
/deep/ .el-form-item__label{
  text-align: right;
  /* margin-left: 25px !important; */
  padding: 0;
  /*margin-right: 5%;*/
}
/deep/ .el-select{
	/* margin-left: 3%; */
	width: 100%;
	text-align: center;
	position: relative;
}
/deep/ .el-select{
	width:100%;
	margin: 0 auto;
	left: 0;
	text-align: center;
}
/deep/ .el-tag.el-tag--info{
	margin-left: 35px;
	margin-bottom: 10px;
}
/deep/ .el-input__suffix{
	margin-right: 30px;
	height: 40px;
}
/deep/ .el-upload--text{
	color: #C4C6CF !important;
}
/deep/.el-select .el-input__inner{
	width: 370px!important;
	height: 40px !important;
	border: 1px solid #DCDFE6 !important;
} 
.text_right{
	/* background-color: #0486FE; */
	width:200px;
	/* text-align: center; */
	display: flex;
	justify-content: center;
	margin: 0 auto;
	margin-bottom: 20px;
	margin-top: 20px;
	
}
.text_right1{
	/* background-color: #0486FE; */
	width:200px;
	text-align: center;
	margin: 0 auto;
	margin-bottom: 20px;
	margin-top: 10px;
	
}
.primary{
	background-color: #E91A1B !important;
	border: none;
	color: #FFFFFF;
}
.no{
	display: inline-block;
	background-color: none !important;
	color: #333333 !important;
	width: 66px;
	height: 40px;
	background: #FFFFFF;
	border-radius: 3px;
	border: 1px solid #C4C6CF;
	margin-left: 30px;
}
.Clipped{
  margin-left: 5px;
  margin-right: 5px;
}
/deep/.el-form-item__error{
	left:25px;
	top:80%;
}
</style>



