// 这个JS文件就是专门用于管理请求各种接口地址的
import Network from './network'

// 封装各种接口请求
// 登录
export const getLogin = (data) => Network.get('/admin/Login/login', data)
// 主账户分配列表
export const getPrimaryList = (data) => Network.get('/admin/User/getPrimaryList', data)
// 主账户增删改
export const doPrimary = (data) => Network.get('/admin/User/doPrimary', data)
// 获取团长用户分配列表
export const getChiefUserList = (data) => Network.get('/admin/User/getChiefUserList', data)
// 冻结 解冻用户
export const ChiefFrozenUser = (data) => Network.get('/admin/User/ChiefFrozenUser', data)
// 获取审核列表
export const getverifyChiefUserList = (data) => Network.get('/admin/User/getverifyChiefUserList', data)
// 新增团长审核
export const doChiefUser = (data) => Network.get('/admin/User/doChiefUser', data)
// 修改团长审核
export const editChiefUser = (data) => Network.get('/admin/User/editChiefUser', data)
// 团长审核
export const verifyChiefUser = (data) => Network.get('/admin/User/verifyChiefUser', data)
// 获取服务分类/商品列表
export const getClassList = (data) => Network.get('/admin/Goods/getClassList', data)
//分类增删改(一级/二级)
export const doClass = (data) => Network.get('/admin/Goods/doClass', data)
//服务增删改(三级)
export const doService = (data) => Network.get('/admin/Goods/doService', data)
// 获取商城分类
export const getProClassList = (data) => Network.get('/admin/Goods/getProClassList ', data)
//商城分类增删改(一级/二级)
export const doProClass = (data) => Network.get('/admin/Goods/doProClass', data)
//服务详情
export const getServiceInfo = (data) => Network.get('/admin/Goods/getServiceInfo', data)
//获取二级分类下的所有服务列表
export const getServiceList = (data) => Network.get('/admin/Goods/getServiceList', data)
//服务是否上架
export const ServiceIsShelf = (data) => Network.get('/admin/Goods/ServiceIsShelf', data)
//获取轮播图页面
export const getbannerList = (data) => Network.get('/admin/User/getbannerList', data)
//轮播图增删改
export const dobanner = (data) => Network.get('/admin/User/dobanner', data)
//分类设置是否显示到首页
export const SetIshome = (data) => Network.get('/admin/Goods/SetIshome', data)
//设置服务到首页精选
export const SetHot = (data) => Network.get('/admin/Goods/SetHot', data)
//产品列表
export const getGoodsList = (data) => Network.get('/admin/Goods/getGoodsList', data)
//产品详情接口
export const getGoodsInfo = (data) => Network.get('/admin/Goods/getGoodsInfo', data)
//产品审核
export const getChiefVerifyGoodsList = (data) => Network.get('/admin/Goods/getChiefVerifyGoodsList', data)
//产品增删改
export const doGoods = (data) => Network.get('/admin/Goods/doGoods', data)
//产品下架
export const GoodsIsShelf = (data) => Network.get('/admin/Goods/GoodsIsShelf', data)
//获取客户列表
export const getMemberList = (data) => Network.get('/admin/Member/getMemberList', data)
//客户意向列表
export const getRecords = (data) => Network.get('/admin/Member/getRecords', data)
//客户冻结解冻
export const MemberFrozenUser = (data) => Network.get('/admin/Member/MemberFrozenUser', data)
//获取服务订单列表(评价)
export const getOrderList = (data) => Network.get('/admin/Order/getOrderList', data)
//获取优品订单列表（评价）
export const getProOrderList = (data) => Network.get('/admin/Order/getProOrderList', data)
//团长分红
export const getChiedBonusList = (data) => Network.get('/admin/Order/getChiedBonusList', data)
//团长分红->结算订单
export const SettlementOrder = (data) => Network.get('/admin/Order/SettlementOrder', data)





// // 获取图片列表
// export const getInterfaceList = (data) => Network.get('/admin/Face/getInterfaceList', data)
// // 图片增删改
// export const doInterface = (data) => Network.get('/admin/Face/doInterface', data)
// // 图片排序
// export const SortImg = (data) => Network.get('/admin/Face/SortImg', data)
// // 获取赛事申请列表
// export const getMatchList = (data) => Network.get('/admin/Match/getMatchList', data)
// // 赛事审核通过/拒绝
// export const verifyMatch = (data) => Network.get('/admin/Match/verifyMatch', data)
// // 获取用户统计列表（数据统计-用户数据）
// export const getUserStatisticsList = (data) => Network.get('/admin/Statistics/getUserStatisticsList', data)
// // 获取用户统计列表中的字段列表（数据统计-用户数据）
// export const getValueList = (data) => Network.get('/admin/Statistics/getValueList', data)
// // 获取广场诗文数据（数据统计-广场诗文）
// export const getSquarePoetryList = (data) => Network.get('/admin/Statistics/getSquarePoetryList', data)
// // 获取诗歌详情（数据统计-广场诗文）
// export const getPoetryInfo = (data) => Network.get('/admin/Statistics/getPoetryInfo', data)
// // 诗歌下架（数据统计-广场诗文）
// export const off_shelf = (data) => Network.get('/admin/Statistics/off_shelf', data)
// // 获取诗文悬赏统计列表（数据统计-诗文悬赏）
// export const getRewardStatistics = (data) => Network.get('/admin/Statistics/getRewardStatistics', data)
// // 悬赏投稿作品查看（数据统计-诗文悬赏）
// export const getjoinPoetryList = (data) => Network.get('/admin/Statistics/getjoinPoetryList', data)
// // 获取赛事统计列表（数据统计-赛事数据）
// export const getMatchStatistics = (data) => Network.get('/admin/Statistics/getMatchStatistics', data)
// // 参赛作品查看（数据统计-赛事数据）
// export const getMatchPoetryLists = (data) => Network.get('/admin/Statistics/getMatchPoetryList', data)
// // 意见反馈
// export const getOpinion = (data) => Network.get('/admin/Match/getOpinion', data)




